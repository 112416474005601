import React, { useState, useEffect } from "react";
import "./index.scss";
import { Pagination } from "antd";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
import tm3 from "../../assest/new1.6.png";
import arrow3 from "../../assest/arrow2.png";
import arrow1 from "../images/arr1.png";
import arrow2 from "../images/arr2.png";
import { getOSSImgUrl } from "../../utils";
export default function APPNewsList() {
  const locale = localStorage.getItem("locale") || "en-us";
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const onChange = (page) => {
    getList(page);
  };
  useEffect(() => {
    getList();
  }, []);
  const getList = (page) => {
    const url = `${config.newsUrl}/bg/api/resource/list`;
    const param = {
      page: page || 1,
      limit: 20,
      status: 1,
      types: 3,
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        const data = res?.data?.data || {};
        console.log(data.totalCount);
        setTotal(data.totalCount || 0);
        setList(data.list || []);
      })
      .catch((err) => {
        console.log("onError", err);
      });
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a>
          <img style={{ width: "0.4rem" }} src={arrow1} alt="" />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <img style={{ width: "0.4rem" }} src={arrow2} alt="" />
        </a>
      );
    }
    return originalElement;
  };
  return (
    <div className="app-news-list">
      <div className="banner">
        <h3
          style={
            locale === "vn-vn"
              ? {
                  fontSize: "0.6rem",
                }
              : {}
          }
        >
          <FormattedMessage
            id="pages.home.平台新闻"
            defaultMessage="平台新闻"
          />
        </h3>
        <p>
          <FormattedMessage
            id="pages.home.不要错过与iBooming的每一刻"
            defaultMessage="不要错过与iBooming的每一刻"
          />
        </p>
      </div>
      <div className="list-warp">
        {list.map((item) => (
          <div
            key={item.id}
            className="list"
            onClick={() => navigate(`/news/detail/${item.id}`)}
          >
            <img
              className="img"
              src={getOSSImgUrl(item.showImg, 300, 100)}
              alt=""
            />
            <div className="text">
              <h3>{item.showTitle}</h3>
              <div className="more">
                <FormattedMessage
                  id="pages.home.继续阅读"
                  defaultMessage="继续阅读"
                />
                <img src={arrow3} alt="" />
              </div>
            </div>
          </div>
        ))}
        <Pagination
          hideOnSinglePage={true}
          defaultCurrent={1}
          total={total}
          itemRender={itemRender}
          pageSize={20}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
