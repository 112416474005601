export default {
  "pages.home.AI matching TikTok Affiliate Partner": "数字化匹配联盟团长",
  "pages.home.Product Analysis": "产品分析",
  "pages.home.iBooming analyse your product label and potential influencer demographics information through big data":
    "iBooming利用大数据分析您的产品标签和潜在网红的带货数据信息，精准匹配网红。",
  "pages.home.Celebrity Marketing": "名人营销",
  "pages.home.AI Matching": "头部效应",
  "pages.home.iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility":
    "iBooming专业地将您的产品与最合适的明星或头部网红匹配合作，快速的打开您的品牌知名度",
  "pages.home.Influencer Mega Campaign": "网红矩阵式推广",
  "pages.home.Matrix Affiliate": "矩阵联盟",
  "pages.home.iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement":
    "iBooming达人分发系统，运用成熟的网红组织和执行系统，可有效放大产品知名度并提高用户互动率。",
  "pages.home.TikTok Advertising": "TikTok广告",
  "pages.home.Keep Engaged": "提升效果",
  "pages.home.iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment":
    "iBooming广告投流服务，通过海量的优质内容资源来保障投流素材供应，从而提高效率以及广告投产比。",
  "pages.home.About iBooming": "关于iBooming",
  "pages.home.iBooming is a technology company specializing in developing artificial intelligence solutions for the digital content industry. Through AI, iBooming enables influencers and brands to optimize content strategies, expand reach, and increase monetization. With a commitment to innovation, iBooming aims to be a leader in AI-powered industry change, enabling influencers and brands to increase product visibility and achieve success in the global marketplace. For more information about iBooming, visit www.iboomingglobal.com":
    "iBooming是一家专注于为数字内容行业开发人工智能解决方案的科技公司。通过人工智能，iBooming使网红和品牌能够优化内容策略、扩大影响力，并增加盈利。秉持创新的承诺，iBooming旨在成为人工智能驱动的行业变革的领导者，帮助影响者和品牌提升产品可见性，实现全球市场上的成功。欲了解更多关于iBooming的信息，请访问 www.iboomingglobal.com。",
  "pages.home.TikTok username": "TikTok 账号",
  "pages.home.立即下载": "立即下载",
  "pages.home.MORE": "更多",
  "pages.home.CREATE": "创造",
  "pages.home.MORE2": "更多",
  "pages.home.BENEFIT": "收益",
  "pages.home.前往TikTok TAP报名": "前往TikTok TAP报名",
  "pages.home.pages.mcn.选择": " ",
  "pages.商家入驻.iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara":
    "iBooming利用人工智能（AI）将品牌与印度尼西亚乃至东南亚的大规模、精准的网红社群联系起来。",
  "pages.商家入驻.Influencer": "网红",
  "pages.商家入驻.Product": "品牌",
  "pages.商家入驻.Brand": "产品",
  "pages.商家入驻.Matchmaking Brand dan Influencer dengan AI Tools":
    "利用AI工具匹配品牌和网红",
  "pages.商家入驻.Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia":
    "印尼甚至东南亚的网红社群的社交网络",
  "pages.商家入驻.Minimalisir Biaya Operasional dan Sumber Daya Manusia":
    "降低运营成本和人力资源",
  "pages.商家入驻.Maksimalkan Trafik Persentase Penjualan Produk":
    "最大化产品销售流量百分比",
  "pages.商家入驻.One Stop Solution Services dalam Satu Platform":
    "一个平台上提供一站式解决方案服务",
  "pages.商家入驻.Mengapa MOUTH-TO-MOUTH MARKETING di iBooming":
    "为什么在iBooming进行口牌营销",
  "pages.商家入驻.Peran Nano-Influencer Marketing": "纳米影网红销的角色",
  "pages.商家入驻.Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan":
    "未来需要纳米网红来推广产品和增加销售",
  "pages.商家入驻.More Trusted": "更值得信赖",
  "pages.商家入驻.Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth":
    "纳米网红与粉丝建立更加亲密的关系，从而创造口牌营销的信任",
  "pages.商家入驻.More Profit": "更有利可图",
  "pages.商家入驻.Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat":
    "受纳米网红推荐的品牌经历了销售的指数增长",
  "pages.商家入驻.More Massive": "更大规模",
  "pages.商家入驻.Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer":
    "无限数量的纳米网红通过网红社群实现广泛覆盖",
  "pages.商家入驻.Hasil MOUTH-TO-MOUTH MARKETING di iBooming":
    "在iBooming进行的口牌营销结果",
  "pages.商家入驻.SEGERA DAFTARKAN BRAND ANDA": "立即注册您的品牌",
  "pages.商家入驻.INFLUENCER": "网红",
  "pages.商家入驻.BRAND": "品牌",
  "pages.商家入驻.Jordi": "Jordi",
  "pages.商家入驻.Willie Salim": "Willie Salim",
  "pages.商家入驻.Vilmei": "Vilmei",
  "pages.商家入驻.Awkarin": "Awkarin",
  "pages.商家入驻.Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus":
    "iBooming上的口碑营销可以通过成千上万的网红吸引数百万观众，创造数十亿的收入",
  "pages.商家入驻.Top Product Sales in Southeast Asia": "东南亚最畅销产品",
  "pages.商家入驻.Top Product Trending Skincare": "护肤品热销榜",
  "pages.商家入驻.Top Product Body Wash": "沐浴露热销榜",
  "pages.商家入驻.Top Product Best Selling Skincare": "护肤品热销榜",
  "pages.商家入驻.100jt+": "1亿+",
  "pages.商家入驻.Total Impresi": "总曝光量",
  "pages.商家入驻.1,3M+": "130万+",
  "pages.商家入驻.Total Penjualan": "总销售额",
  "pages.商家入驻.TikTok Southeast Asia Hot Product List":
    "TikTok东南亚热门产品列表上",
  "pages.商家入驻.400jt+": "4亿+",
  "pages.商家入驻.40.000+": "4万+",
  "pages.商家入驻.Penjualan Harian Rata-Rata": "每日平均销售额",
  "pages.商家入驻.No. 1": "护肤套餐中的No. 1",
  "pages.商家入驻.in Skincare Package": "",
  "pages.商家入驻.Penjualan Bulanan Rata-Rata": "每月平均销售额",
  "pages.商家入驻.100.000+": "100K+",
  "pages.商家入驻.Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu":
    "填写信息加入，iBooming团队将进行审查并立即联系您",
  "pages.商家入驻.Nama Perusahaan": "公司名称",
  "pages.商家入驻.申请入驻": "申请入驻",
  "pages.商家入驻.Jenis Usaha": "业务类型",
  "pages.商家入驻.Kategori Produk": "产品类别",
  "pages.商家入驻.Nomor Kontak Aktif": "联系号码",
  'pages.influencer.This Rate Card Generator feature is provided by iBooming Official (referred to as "we", "us", or "our") and by using our Rate Card Generator feature, you agree to provide accurate and up-to-date information and grant us a license to use it for generating the rate card. We make no warranties regarding the accuracy of the generated rate card and shall not be liable for any damages. If you have any questions, please contact us.':
    "此Rate Card生成器功能由iBooming官方提供（以下简称“我们”），通过使用我们的Rate Card生成器功能，您同意提供准确和最新的信息，并授予我们使用它来生成费率卡的许可。我们对生成的Rate Card的准确性不做任何保证，也不对任何损害负责。如果您有任何疑问，请与我们联系。",
};
