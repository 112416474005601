import React, { useState, useEffect } from "react";
import "./pcIndex.scss";
import logo from "../assest/iBooming-logo.png";
import logo2 from "../assest/logo2.png";
import icon from "../assest/icon.png";
import icon2 from "../assest/icon2.png";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Carousel, Dropdown } from "antd";
let num = 0;
let timer = null;
let isFirst = 0;
export default function PcHeader(props) {
  const [open, setOpen] = useState(false);
  const [navNum, setNavNum] = useState(-1);
  const { pathname } = window.location;
  console.log(pathname);
  const locale = localStorage.getItem("locale") || "en-us";
  const handleMenuClick = (e) => {
    const { key } = e;
    if (key) {
      localStorage.setItem("locale", key);
      let str = "";
      if (
        window.location.href.includes("locale=") &&
        !window.location.href.includes("lang=")
      ) {
        str = "&lang=1";
      }
      window.location.href = window.location.href + str;
      // window.location.reload();
    }
  };
  const menuProps = {
    items: [
      {
        label: "中文(简体)",
        key: "zh-cn",
      },
      {
        label: "English (us)",
        key: "en-us",
      },
      {
        label: "Bahasa Indonesia",
        key: "id-id",
      },
      {
        label: "ไทย",
        key: "th-th",
      },
      {
        label: "Tiếng Việt",
        key: "vn-vn",
      },
    ],
    selectable: true,
    defaultSelectedKeys: [locale],
    onClick: handleMenuClick,
  };
  // const [position, setPosition] = useState(0);

  // useEffect(() => {
  //   // const intervalId = setInterval(() => {
  //   //   // if (num / 30 > 1 && num % 30 < 20) {
  //   //   // } else {
  //   //   // }
  //   //   // setPosition((prevPosition) => {
  //   //   //   let newNum = prevPosition;
  //   //   //   if (prevPosition == 60) {
  //   //   //     newNum = -30;
  //   //   //   } else {
  //   //   //     newNum = prevPosition + 1;
  //   //   //   }
  //   //   //   return newNum;
  //   //   // });
  //   //   setPosition((prevPosition) => prevPosition + 1);
  //   // }, 3000);
  //   // return () => clearInterval(intervalId);
  //   if (timer) {
  //     clearInterval(timer);
  //   }
  //   console.log(isFirst);
  //   if (isFirst) {
  //     const oul = document.getElementById("oul");
  //     const olis = oul.getElementsByTagName("img");
  //     let indexNum = 0;
  //     const ofst = document.createElement("img");
  //     ofst.src = olis[1].src;
  //     ofst.className = olis[1].className;
  //     // ofst.innerText = olis[0].innerText;
  //     oul.appendChild(ofst);
  //     const liLens = olis.length;
  //     // 每隔1800ms执行
  //     timer = setInterval(() => {
  //       indexNum++;
  //       //判定是否到达最后行
  //       if (indexNum < liLens) {
  //         // recall = 0;
  //         oul.style.transition = "transform .5s ease-in"; //添加css过渡时的效果
  //         // 每次滚动的距离是 单行高*行数
  //         oul.style.transform = "translateY(-" + 30 * indexNum + "px)";
  //       } else {
  //         indexNum = 0;
  //         oul.style.transition = ""; //去除css过渡效果
  //         oul.style.transform = "translateY(-" + 30 * indexNum + "px)";
  //         setTimeout(function () {
  //           indexNum = 1;
  //           oul.style.transition = "transform .5s ease-in";
  //           oul.style.transform = "translateY(-" + 30 * indexNum + "px)";
  //         }, 100);
  //       }
  //     }, 1800);
  //   }
  //   isFirst++;
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  return (
    <div
      className={`header${
        ["id-id", "vn-vn"].includes(locale) ? " id-header" : ""
      } animated fadeInDownBig`}
    >
      <div>
        <Link to="/home">
          <div className="logo-warp">
            {/* <div className="flipInLogo" key="apc">
              <img className="logo" src={logo2} alt="FreeBox" />
              <img className="logo" src={logo} alt="FreeBox" />
              <img className="logo" src={logo2} alt="FreeBox" />
              <img className="logo" src={logo} alt="FreeBox" />
              <img className="logo" src={logo2} alt="FreeBox" />
              <img className="logo" src={logo} alt="FreeBox" />
              <img className="logo" src={logo2} alt="FreeBox" />
            </div> */}
            <Carousel dotPosition="right" autoplay dots={false}>
              <div>
                <img className="logo" src={logo} alt="ibooming" />
              </div>
              <div>
                <img className="logo" src={logo2} alt="tiktok" />
              </div>
            </Carousel>
          </div>
        </Link>
      </div>
      <div className={`flexB`} style={{ flex: 1 }}>
        <ul>
          <li
            className={
              pathname == "/home" || pathname == "/" || navNum == 0
                ? "active"
                : ""
            }
            onClick={() => {
              setNavNum(0);
            }}
          >
            <Link to="/home">
              <FormattedMessage id="pages.home.首页" defaultMessage="首页" />
            </Link>
          </li>
          <li
            className={pathname == "/business" || navNum == 1 ? "active" : ""}
            onClick={() => {
              setNavNum(1);
            }}
          >
            <Link to="/business">
              <FormattedMessage
                id="pages.home.我是商家"
                defaultMessage="我是商家"
              />
            </Link>
          </li>
          <li
            className={pathname == "/talent" || navNum == 2 ? "active" : ""}
            onClick={() => {
              setNavNum(2);
            }}
          >
            <Link to="/talent">
              <FormattedMessage
                id="pages.home.我是网红"
                defaultMessage="我是网红"
              />
            </Link>
          </li>
          <li className={pathname == "/about" ? "active" : ""}>
            <a href={pathname.includes("home") ? "#aboutMap" : "/home#about"}>
              <FormattedMessage
                id="pages.home.关于我们"
                defaultMessage="关于我们"
              />
            </a>
          </li>
          <li
            className={
              pathname == "/brand/detail" || pathname.includes("/brand/detail")
                ? "active"
                : ""
            }
          >
            <a href="/home#brands">
              <FormattedMessage
                id="pages.home.经典案例"
                defaultMessage="经典案例"
              />
            </a>
          </li>
          <li
            className={
              pathname == "/newslist" ||
              pathname.includes("/news/detail") ||
              navNum == 3
                ? "active"
                : ""
            }
            onClick={() => setNavNum(3)}
          >
            <Link to="/newslist">
              <FormattedMessage
                id="pages.home.平台新闻"
                defaultMessage="平台新闻"
              />
            </Link>
          </li>
          <li>
            {/*  */}
            <Dropdown menu={menuProps} open={open} onOpenChange={setOpen}>
              {open ? (
                <a style={{ color: "#FF1652" }}>
                  <img
                    style={{ verticalAlign: "sub", marginRight: 4 }}
                    width={20}
                    src={icon2}
                    alt=""
                  />
                  {(locale.split("-")[1] || "us").toUpperCase()}
                </a>
              ) : (
                <a style={{ color: "#FF1652" }}>
                  <img
                    style={{ verticalAlign: "sub", marginRight: 4 }}
                    width={20}
                    src={icon}
                    alt=""
                  />
                  {(locale.split("-")[1] || "us").toUpperCase()}
                </a>
              )}
            </Dropdown>
          </li>
        </ul>
      </div>
      <a className="header-btn" href="#contact">
        <FormattedMessage id="pages.home.联系我们" defaultMessage="联系我们" />
      </a>
    </div>
  );
}
