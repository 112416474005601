// 服务类型相关
import { Carousel } from "antd";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import sr1 from "../../assest/sr1.png";
import sr2 from "../../assest/sr2.png";
import sr3 from "../../assest/sr3.png";
import sr4 from "../../assest/sr4.png";
const titleArr = ["无前置费用", "矩阵分销", "快速测品", "本土服务"];
const descArr = [
  "iBooming不收取任何前置服务费，品牌商可以将所有推广费用花在刀刃上，为您的投产效益做到最大化。",
  "iBooming平台超大的网红活跃人数，可以快速让网红生产带货短视频以及直播带货，形成网红推品矩阵，让产品可以更大范围的覆盖到TikTok的消费用户，提升动销率，快速打爆产品。",
  "新品可以通过iBooming匹配小规模网红，以小微矩阵的形式快速获得市场多维度数据反馈，让品牌商可以更快的布局市场以及迭代产品。",
  "iBooming在东南亚各国都有本土的服务团队，提供免费咨询以及市场调研，更加本土化的基因可以规避品牌出海的水土不服。",
];
let oldIndex = 0;
const serviceList = [
  {
    title: "服务1",
    mainTitle: "AI matching TikTok Affiliate Partner",
    minTitle: "Product Analysis",
    desc: "iBooming analyse your product label and potential influencer demographics information through big data",
    img: sr1,
  },
  {
    title: "服务2",
    mainTitle: "Celebrity Marketing",
    minTitle: "AI Matching",
    desc: "iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility",
    img: sr2,
  },
  {
    title: "服务3",
    mainTitle: "Influencer Mega Campaign",
    minTitle: "Matrix Affiliate",
    desc: "iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement",
    img: sr3,
  },

  {
    title: "服务4",
    mainTitle: "TikTok Advertising",
    minTitle: "Keep Engaged",
    desc: "iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment",
    img: sr4,
  },
];
export default function APPServiceList(props) {
  const { type } = props;
  const locale = localStorage.getItem("locale") || "en-us";
  // const intl = useIntl();
  const ref = useRef();
  const [count, setCount] = useState(0);
  const [serviceNum, setServiceNum] = useState(0);

  const serviceOnClick = (index) => {
    const dom = document.querySelectorAll(".app-service .list");
    // dom[i].classList;
    // console.log(dom);
    console.log(oldIndex, index, 999);
    if (index < oldIndex) {
      console.log(111);
      // const arr = [...new Array(oldIndex + 1)];
      for (let i = oldIndex + 1; i > 0; i--) {
        if (i > index) {
          if (i === oldIndex) {
            dom[i].classList.remove("animation3");
            dom[i].classList.add("animation4");
            let timer = setTimeout(() => {
              dom[i].classList.add("none");
              clearTimeout(timer);
            }, 100);
          } else {
            const timer2 = setTimeout(() => {
              dom[i].classList.remove("animation3");
              dom[i].classList.add("animation4");
              let timer3 = setTimeout(() => {
                dom[i].classList.add("none");
                clearTimeout(timer3);
              }, 100);
              clearTimeout(timer2);
            }, (oldIndex + 1 - i) * 200);
          }
        }
      }
    } else if (index > oldIndex) {
      for (let i = 0; i <= index; i++) {
        // console.log(arr);
        if (i > oldIndex) {
          if (i == oldIndex + 1) {
            dom[i].classList.remove("none");
            dom[i].classList.remove("animation4");
            dom[i].classList.add("animation3");
          } else {
            let timer = setTimeout(() => {
              dom[i].classList.remove("none");
              dom[i].classList.remove("animation4");
              dom[i].classList.add("animation3");
              clearTimeout(timer);
            }, 300 * i);
          }
        }
      }
    } else {
      return;
    }
    oldIndex = index;
    setServiceNum(index);
  };
  return (
    <>
      <h3 className="app-main-title">
        <FormattedMessage
          id="pages.home.为什么选择iBooming"
          defaultMessage="为什么选择iBooming"
        />
      </h3>
      {/* 为什么选择iBooming？ */}
      <div
        className={`advantage ${locale === "zh-cn" ? "" : "app-en-advantage"}`}
      >
        <div className="flexB">
          {titleArr.map((item, index) => (
            <div
              className={`drop ${index === count ? " active" : ""}`}
              key={item}
              onClick={() => {
                ref.current.goTo(index);
              }}
            ></div>
          ))}
        </div>
        <Carousel
          // autoplay
          // effect="fade"
          ref={ref}
          dots={false}
          beforeChange={(from, to) => {
            setCount(to);
          }}
        >
          {titleArr.map((item, index) => (
            <div className="desc">
              <h3>
                <FormattedMessage
                  id={`pages.home.${item}`}
                  defaultMessage={item}
                />
              </h3>
              <p>
                <FormattedMessage
                  id={`pages.home.${descArr[index]}`}
                  defaultMessage={descArr[index]}
                />
              </p>
            </div>
          ))}
        </Carousel>
        <div className="num">
          <span>{`0${count + 1}`}</span>/04
        </div>
      </div>
      {/* iBooming服务类型 */}
      <h3 className="app-main-title">
        <FormattedMessage
          id="pages.home.iBooming服务类型"
          defaultMessage="iBooming服务类型"
        />
      </h3>
      <div
        className={`app-service ${locale == "zh-cn" ? "" : "en-app-service"}`}
      >
        <div className="flexB">
          {serviceList.map((item, index) => (
            <div
              key={item.title}
              className={`btn${serviceNum == index ? " active" : ""}`}
              onClick={() => {
                serviceOnClick(index);
              }}
            >
              <FormattedMessage
                id={`pages.home.${item.title}`}
                defaultMessage={item.title}
              />
            </div>
          ))}
          {/* <div className="btn active">
            <FormattedMessage id="pages.home.服务1" defaultMessage="服务1" />
          </div>
          <div className="btn">
            <FormattedMessage id="pages.home.服务2" defaultMessage="服务2" />
          </div>
          <div className="btn">
            <FormattedMessage id="pages.home.服务3" defaultMessage="服务3" />
          </div>
          <div className="btn">
            <FormattedMessage id="pages.home.服务4" defaultMessage="服务4" />
          </div> */}
        </div>
        {serviceList.map((item, index) => (
          <div
            key={item.mainTitle}
            className={`list service${index + 1}${index !== 0 ? " none" : ""}`}
            // className={`list service${index + 1} ${
            //   index === serviceNum ? "animation3" : ""
            // } ${index > serviceNum ? "none" : "block"}`}
          >
            <img src={item.img} alt="" />
            <h3>
              <FormattedMessage
                id={`pages.home.${item.mainTitle}`}
                defaultMessage={item.mainTitle}
              />
            </h3>
            <h4>
              <FormattedMessage
                id={`pages.home.${item.minTitle}`}
                defaultMessage={item.minTitle}
              />
            </h4>
            <p>
              <FormattedMessage
                id={`pages.home.${item.desc}`}
                defaultMessage={item.desc}
              />
            </p>
          </div>
        ))}
      </div>
    </>
  );
}
