export default {
  "pages.home.东南亚": "Southeast Asia's ",
  "pages.home.最大": "Largest",
  "pages.home.我是商家": "I'm Seller",
  "pages.home.我是网红": "I'm Influencer",
  "pages.home.联系我们": "Contact Us",
  // "pages.home.经典案例": "Our Achievements",
  "pages.home.平台新闻": "Platform News",
  "pages.home.中文": "中文",
  "pages.home.海外网红矩阵式分销平台": "Influencer Matrix Affiliation Platform",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台":
    "iBooming is a technology-based digital marketing platform.",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长":
    "Connect your products with the right audience through smart technology. Optimize your business growth with cost-effective strategies and efficient distribution.",
  "pages.home.我是网红": "I'm Influencer",
  "pages.home.我是商家": "I'm Seller",
  "pages.home.我是机构": "I'm Institution",
  "pages.home.活跃网红": "Active influencer",
  "pages.home.每日动销网红": "Influencer Daily Sales",
  "pages.home.合作商家": "Collaborating Merchants",
  "pages.home.我们的": "Our ",
  "pages.home.业务范围": "Business Scope",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台。":
    "iBooming is a technology-based digital marketing platform.",
  "pages.home.通过智能算法，为商家精准匹配大量营销带货网红，快速在TikTok以网红矩阵销售产品以及推广品牌；为网红精准匹配智能货盘以及营销任务，网红通过iBooming可以快速在TikTok提升账号动销率，提高收益。":
    "We create a dynamic matrix on TikTok by intelligently connecting sellers and influencers, accelerating sales and brand promotion. For influencers, iBooming's precise matching system enhances engagement and income on TikTok through smart affiliate tasks.",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长。":
    "Connect your products with the right audience through smart technology. Optimize your business growth with cost-effective strategies and efficient distribution.",
  "pages.home.团长招募": "Recruitment of Team Leaders",
  "pages.home.我们一直在寻找优秀的网红领导人，在iBooming您的团队可以快速为您创造更高的带货收益，并能轻松的智能化管理您的网红团队；如果您有网红资源，想在市场营销和娱乐领域创造一个稳定的事业，与全球互联网人才一起共创美好未来，我们将非常欢迎您的加入!": `Join as a Leading Influencer at iBooming!
  Your team can generate high income and manage creators smartly. If you have influencer resources, build a stable career with global talent at iBooming!`,
  // "pages.home.经典案例": "Our Achievements",
  "pages.home.最新动态": "Latest Updates",
  "pages.home.亿": "Million",
  "pages.home.总曝光量": "Total Impression",
  "pages.home.总销售额(美元)": "Total Sales(USD)",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo is an international brand focused on researching and producing beauty and personal care products.",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton is a beauty and personal care brand focused on developing whitening soaps.",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为":
    "Feali is an international brand focused on researching and producing cosmetics. In February 2023, Feali launched a unique skincare product called:",
  "pages.home.您好,": "Hello ",
  "pages.home.商家": "Seller",
  "pages.home.网红": "Influencer",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok达人组织建立快速响应系统":
    "iBooming builds a TikTok influencer affiliation matrix with countless team leaders, establishing a rapid response system with over 800,000 TikTok influencers.",
  "pages.home.立即报名": "Sign up now!",
  "pages.home.立即加入": "Join now!",
  "pages.home.80万+ 带货网红": "800 Thousand+ Influencer",
  "pages.home.我是TikTok": "I’m TikTok",
  "pages.home.头部卖家": "Top Seller",
  "pages.home.新卖家": "New Seller",
  "pages.home.我是其他": "I am a Seller of",
  "pages.home.类型商家": "other categories",
  "pages.home.为什么选择iBooming": "Why Choose iBooming",
  "pages.home.无前置费用": "No Upfront Fees",
  "pages.home.矩阵分销": "Matrix Affiliation",
  "pages.home.快速测品": "Rapid Product Testing",
  "pages.home.本土服务": "Local Services",
  "pages.home.iBooming不收取任何前置服务费，品牌商可以将所有推广费用花在刀刃上，为您的投产效益做到最大化。":
    "iBooming does not charge any upfront service fees, allowing seller partners to allocate all promotion expenses effectively, maximizing your return on investment.",
  "pages.home.iBooming服务类型": "Types of iBooming Services",
  "pages.home.抢占海外网红分销的红利":
    "Seize the benefits of overseas influencer affiliation",
  "pages.home.立即匹配": "Match Up Now",
  "pages.home.#服务1": "#Service 1",
  "pages.home.#服务2": "#Service 2",
  "pages.home.#服务3": "#Service 3",
  "pages.home.#服务4": "#Service 4",
  "pages.home.服务1": "Service 1",
  "pages.home.服务2": "Service 2",
  "pages.home.服务3": "Service 3",
  "pages.home.服务4": "Service 4",
  "pages.home.AI matching TikTok Affiliate Partner":
    "AI matching TikTok Affiliate Partner",
  "pages.home.Product Analysis": "Product Analysis",
  "pages.home.iBooming analyse your product label and potential influencer demographics information through big data":
    "iBooming analyse your product label and potential influencer demographics information through big data",
  "pages.home.Celebrity Marketing": "Celebrity Marketing",
  "pages.home.Top Influencer Effect": "Top Influencer Effect",
  "pages.home.iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility":
    "iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility.",
  "pages.home.Influencer Matrix Promotion": "Influencer Matrix Promotion",
  "pages.home.Matrix Affiliate": "Matrix Affiliate",
  "pages.home.iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement":
    "iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement.",
  "pages.home.TikTok Advertising": "TikTok Advertising",
  "pages.home.Improve results": "Improve results",
  "pages.home.iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment":
    "iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment.",
  "pages.home.为您精准匹配智能货盘以及营销任务，通过iBooming您可以快速在TikTok提升账号动销率，提高收益":
    "iBooming precisely match influencers with Artificial Intelligence (AI) pallets and marketing tasks for you, so that influencer can rapidly increase their account sales rates and enhance earnings on TikTok through iBooming!",
  "pages.home.已有": "Already have",
  "pages.home.万+": "Thousands +",
  "pages.home.动销网红加入我们": "Influencers Joined Us",
  "pages.home.已入驻": "Established in",
  "pages.home.国家（印尼，泰国，越南，马来西亚，菲律宾）":
    "Countries (Indonesia, Thailand, Vietnam, Malaysia, the Philippines)",
  "pages.home.当前已开通5个国家，突破80万带货网红，成为东南亚最大的TikTok网红分销平台":
    "Currently operating in 5 countries, with over 800,000 affiliation product sales, we have become the largest TikTok influencer affiliation platform in Southeast Asia.",
  "pages.home.TOP": "TOP ",
  "pages.home.网红2": "Influencer",
  "pages.home.快加入我们": "Join us now!",
  "pages.home.加入网红社群": "Join the Influencers Community",
  "pages.home.八十万网红池": "800,000 Influencer Pool",
  "pages.home.各类目网红满足您的商业需求":
    "Various influencer in every category to meet your business needs",
  "pages.home.立即咨询我们": "Contact us now",
  "pages.home.提交": "Submit",
  "pages.home.#步骤1": "#Step 1",
  "pages.home.#步骤2": "#Step 2",
  "pages.home.#步骤3": "#Step 3",
  "pages.home.#步骤4": "#Step 4",
  "pages.home.注册填写您的品牌": "Register and fill in your brand information",
  "pages.home.AI Booms 工具扫描您的店铺信息":
    "AI Booms scans your store information",
  "pages.home.AI Booms 为您提供符合您企业的最佳推广方案":
    "AI Booms provides you with the best promotional solution for your business",
  "pages.home.享受您的品牌流量": "Enjoy your brand's traffic",
  "pages.home.平台新闻": "Platform News",
  "pages.home.不要错过与iBooming的每一刻": "Don't miss a moment with iBooming",
  "pages.home.继续阅读": "Continue reading",
  "pages.home.您的企业名称": "Your Company Name",
  "pages.home.企业所在国家": "Company's Location",
  "pages.home.选择类目": "Choose a Category",
  "pages.home.印度尼西亚": "Indonesia",
  "pages.home.泰国": "Thailand",
  "pages.home.越南": "Vietnam",
  "pages.home.马来西亚": "Malaysia",
  "pages.home.菲律宾": "Philippines",
  "pages.home.美妆": "Beauty and Cosmetics",
  "pages.home.电子": "Electronics",
  "pages.home.服饰": "Apparel",
  "pages.home.食品": "Food",
  "pages.home.家居生活": "Home and Lifestyle",
  "pages.home.母婴": "Baby and Maternity",
  "pages.home.个护和健康": "Personal Care and Health",
  "pages.home.恭喜您提交成功":
    "Congratulations, you have submitted successfully!",
  "pages.home.我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作":
    "Our staff will contact you as soon as possible. You can also directly sign up for TAP collaboration",
  "pages.home.前往 TikTok TAP报名": "Go to TikTok TAP Registration",
  "pages.home.iBooming平台超大的网红活跃人数，可以快速让网红生产带货短视频以及直播带货，形成网红推品矩阵，让产品可以更大范围的覆盖到TikTok的消费用户，提升动销率，快速打爆产品。":
    "The iBooming platform has many active influencers, enabling quick creation of short videos and live sales. It forms a product matrix driven by influencers, expanding product reach to TikTok's consumer base, boosting sales, and rapidly popularizing products.",
  "pages.home.新品可以通过iBooming匹配小规模网红，以小微矩阵的形式快速获得市场多维度数据反馈，让品牌商可以更快的布局市场以及迭代产品。":
    "New products can be matched with small-scale influencers through iBooming, obtaining multidimensional market feedback quickly, allowing brand partners to market and iterate products more rapidly.",
  "pages.home.iBooming在东南亚各国都有本土的服务团队，提供免费咨询以及市场调研，更加本土化的基因可以规避品牌出海的水土不服。":
    "iBooming has local service teams in various Southeast Asian countries, providing free consultation and market research. More localized approach helps mitigate challenges when expanding brands internationally.",
  "pages.home.新": "New ",
  "pages.home.卖家": "Seller",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok网红组织建立快速响应系统":
    "iBooming builds a TikTok influencer affiliation matrix with countless team leaders, establishing a rapid response system with over 80,000 TikTok influencers.",
  "pages.home.其他类型": "Other Types ",
  "pages.home.首页": "Home",
  "pages.home.About iBooming": "About iBooming",
  "pages.home.iBooming is a technology company specializing in developing artificial intelligence solutions for the digital content industry. Through AI, iBooming enables influencers and brands to optimize content strategies, expand reach, and increase monetization. With a commitment to innovation, iBooming aims to be a leader in AI-powered industry change, enabling influencers and brands to increase product visibility and achieve success in the global marketplace. For more information about iBooming, visit www.iboomingglobal.com":
    'iBooming is a technology company specializing in developing artificial intelligence solutions for the digital content industry. Through AI, iBooming enables influencers and brands to optimize content strategies, expand reach, and increase monetization. With a commitment to innovation, iBooming aims to be a leader in AI-powered industry change, enabling influencers and brands to increase product visibility and achieve success in the global marketplace. For more information about iBooming, visit www.iboomingglobal.com"',
  "pages.home.TikTok username": "TikTok username",
  "pages.home.更多高佣商品与带货补贴":
    "More high-commission products and affiliated product subsidies",
  "pages.home.粉丝数": "Number of Followers",
  "pages.home.拍摄一个视频的价格": "Price for one video",
  "pages.home.其他账号": "Other Accounts",
  "pages.home.提交成功": "Submission successful",
  "pages.home.印尼": "Indonesia",
  "pages.home.中国": "China",
  "pages.home.1,000,000 以上": "Above 1,000,000",
  "pages.home.500,000 至 1,000,000": "500,000 to 1,000,000",
  "pages.home.100,000 至 500,000": "100,000 to 500,000",
  "pages.home.50,000 至 100,000": "50,000 to 100,000",
  "pages.home.10,000 至 50,000": "10,000 to 50,000",
  "pages.home.10,000 以下": "Below 10,000",
  "pages.home.在线品牌商": "Online Brand Seller",
  "pages.home.我是网红": "I'm Influencer",
  "pages.home.我们的业务范围": "Our Business Scope",
  "pages.home.电子邮箱": "Email",
  "pages.home.关于我们": "About Us",
  "pages.home.经典案例": "Special Cases",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过100万TikTok达人组织建立快速响应系统":
    "iBooming builds a TikTok influencer affiliation matrix with countless team leaders, establishing a rapid response system with over 1 million TikTok influencers.",
  "pages.home.抢占海外达人分销的红利":
    "Earn bonuses from overseas influencer affiliate sales",
  "pages.home.联系号码": "Contact Number",
  "pages.home.微信": "WeChat",
  "pages.home.合作商家": "Collaborating Seller",
  "pages.home.立即加入网红社群": "Join the Influencer Community Now!",
  "pages.home.合作媒体": "Media Partners",
  "pages.home.网红加入": "Influencer Join",
  "pages.home.合作团长": "Colaborative Team Leaders",
  "pages.home.扫码下载APP": "Scan to download the App",
  "pages.home.复制成功": "Copy Successfully",
  "pages.home.护肤": "Skincare",
  "pages.home.彩妆": "Makeup",
  "pages.home.立即下载": "Download Now",
  "pages.home.下载iBooming APP, 参与活动领福利":
    "Download iBooming app, participate in the activities and receive benefits",
  "pages.home.平均日销量": "Average Daily sales",
  "pages.home.1亿": "Million",
  "pages.home.200亿": "Million",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton is a beauty brand dedicated to developing whitening soap.",
  "pages.home.该品牌过去依赖当地的经销商来销售产品，像是传统电商平台Tokopedia，Shopee或Lazada等其他渠道进行销售。":
    "The brand used to rely sales on local distributors platforms like Tokopedia, Shopee, or Lazada.",
  "pages.home.然而，由于推广的数量以及力度不足，销售一直停滞不前。":
    "However, due to insufficient promotions, sales remained stagnant.",
  "pages.home.2022年10月，Kojic Plankton与iBooming合作，首次利用KOL（关键意见领袖）和KOC（关键意见消费者）在TikTok上推广其产品。":
    "In October 2022, Kojic Plankton partnered with iBooming to promote its products on TikTok using KOL (Key Opinion Leader) and Key Opinion Consumers (KOCs).",
  "pages.home.iBooming的团队花费了两个月的时间寻找适合该产品的创作者，并测试了各种内容、定价策略和推广方式等等，以提高产品销售。":
    "iBooming took two months finding suitable influencers for the product, testing various contents, price strategies, and promotional methods to boost sales.",
  "pages.home.到12月底，iBooming的团队成功将该产品推动到东南亚TikTok销售排行榜的前列，在仅仅1天内就获得了超过70,000个订单，对比以往的销量提高了百分比的成绩。":
    "iBooming successfully promote the product to the top of TikTok sales charts in Southeast Asia, securing over 70000 orders in just 1 day which is a remarkable increase compared to previous sales.",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo is an international brand focused on beauty personal care.",
  "pages.home.在2022年初，他们推出了MeToo漱口水，并与iBooming合作。":
    "MeToo launched mouthwash in collaborating with iBooming in early 2022.",
  "pages.home.在两个月的时间里，iBooming精准地将该产品分配给与MeToo品牌价值观相符的内容创作者":
    "iBooming precisely allocated product to content creators aligned with MeToo's brand values within 2 months.",
  "pages.home.除了在TikTok上的MeToo Smile官方活动，品牌商也斥巨资邀请了一位当地备受瞩目的当红明星作为该产品的品牌大使，以及各大新闻媒体的报道支持，促成了MeToo在TikTok上迅速走红。":
    "MeToo invested significantly in inviting local celebrities as their ambassadors, in addition to the MeToo Smile event on TikTok, garnerning support from major news media and resulting in rapid TikTok popularity.",
  "pages.home.在Metoo漱口水上市仅三天内，根据数据显示，每日有超过上千名的网红通过发布短视频或直播带货的方式销售产品，快速推动了商品销量，平均日销量超过了40,000瓶。":
    "The product rapidly achieved over 40000 daily sales with thousands of influencers promoting it through video affiliations and live sessions within 3 days of the MeToo Mouthwash launch.",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为 FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream。":
    'Feali is an international brand focusing on developing cosmetics. They launched a unique skincare product called "FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream" in February 2023.',
  "pages.home.该产品使用了市场上罕见的天然成分，当涂抹于皮肤时会产生白色气泡，使其在视觉上也非常吸引人。":
    "The product contains rare natural ingredients, producing white bubbles when applied to the skin for an appealing effect.",
  "pages.home.iBooming用了两周的时间，根据后台数据智能匹配了与该产品标签相符的带货网红，并快速积极地完成了产品的市场试错，为接下来的大型推广扎稳了根基。，并有效地推广了FEALI面霜。":
    "iBooming intelligently match influencers with the product label within 2 months, actively conducts market trials, and establishes a solid foundation which effectively boost the promotion of the FEALI cream.",
  "pages.home.MORE": "CREATE ",
  "pages.home.CREATE": "MORE",
  "pages.home.MORE2": "EARN ",
  "pages.home.BENEFIT": "MORE",
  "pages.home.业务所在国家": "Country of Business",
  "pages.home.所在国家": "Country",
  "pages.home.其他账号（选填）": "Other Account (Optional)",
  "pages.home.仅仅一个月时间，Feali就成功提升了销量并取得了令人瞩目的成果，充分诠释了有针对性的营销策略和制作创意内容所带来的成效是无可比拟的。":
    "In just one month, Feali's success in boosting sales and achieving remarkable results showcases the incomparable effectiveness of targeted marketing strategies and creative content production.",
  "pages.home.企业微信": "WeCom",
  "pages.home.前往TikTok TAP报名": "Join TikTok TAP now",

  "pages.mcn.获得": "Unlock ",
  "pages.mcn.更多": "More",
  "pages.mcn.好处": "Benefits",
  "pages.mcn.加入正确MCN，你可获得":
    "Join the Right MCN: What Exclusive Benefits Await You",
  "pages.mcn.官方流量扶持": "Official traffic support",
  "pages.mcn.官方每个月的佣金补贴": "Official monthly commission subsidies",
  "pages.mcn.享有直播特价福利": "Exclusive LIVE privileges",
  "pages.mcn.短视频/直播投流补贴": "Video/ LIVE promotion subsidies",
  "pages.mcn.立即": "Choose ",
  "pages.mcn.选择": " Now",
  "pages.mcn.获得官方政策": "to access official policies",
  "pages.mcn.我们": "We offer:",
  "pages.mcn.零抽佣，品牌佣金100%给创作者":
    "0% Commission Cut, 100% brand commission to creators",
  "pages.mcn.无限制，自由选择带货":
    "Unlimited freedom to choose affiliated products",
  "pages.mcn.专业导师培训TikTok最新功能":
    "Professional mentorship on TikTok's latest features",
  "pages.mcn.官方限时加入": "Limited-time Enrollment",
  "pages.mcn.扫码打开链接填写信息":
    "Scan the code, open the link, and fill in your information",
  "pages.mcn.审核通过结果请查收您的TikTok站内信":
    "Check your TikTok inbox for approval results",
  "pages.mcn.步骤1": "Step 1",
  "pages.mcn.填写信息注册": "Fill in the Information",
  "pages.mcn.填写信息申请加入": "Fill in the information to apply",
  "pages.mcn.TikTok 账号": "TikTok Account",
  "pages.mcn.粉丝数": "Followers",
  "pages.mcn.选择类目": "Category Selection",
  "pages.mcn.拍摄一个视频的价格": "Price per video",
  "pages.mcn.立即申请": "Apply Now",
  "pages.mcn.步骤2": "Step 2",
  "pages.mcn.查看审核结果": "Check Review Results",
  "pages.mcn.打开TikTok站内信查看审核结果": "Open TikTok inbox",
  "pages.mcn.如果通过，您会收到来自": "If approved, expect an invitation from ",
  "pages.mcn.的邀约": " in your TikTok inbox",
  "pages.mcn.步骤3": "Step 3",
  "pages.mcn.点击同意": "Accept Invitation",
  "pages.mcn.点击同意，恭喜您加入成功":
    "Accept Invitation and Congratulations on Joining!",
  "pages.mcn.提交申请成功": "Submission successful",
  "pages.mcn.一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信":
    "Once approved, we will send an invitation to you. Please check TikTok inbox.",
  "pages.mcn.好的": "OK",
  "pages.mcn.提交失败，请再尝试一次": "Submission failed. Please try again.",
  "pages.mcn.已申请": "Submitted",
  "pages.mcn.填写信息加入，审核通过结果请查收您的TikTok站内信":
    "Fill in the information to join. Please check your TikTok inbox for the review results.",
  "pages.mcn.填写信息申请": "Fill in the information to apply",
  "pages.home.AI Matching": "AI Matching",
  "pages.home.Matrix Affiliate": "Matrix Affiliate",
  "pages.home.Keep Engaged": "Keep Engaged",
  "pages.home.Influencer Mega Campaign": "Influencer Mega Campaign",
  "pages.home.其他": "Others",

  "pages.商家入驻.BOOMING": "BOOMING",
  "pages.商家入驻.YOUR BRAND WITH": "YOUR BRAND WITH",
  "pages.商家入驻.MOUTH-TO-MOUTH": "MOUTH-TO-MOUTH",
  "pages.商家入驻.MARKETING": "MARKETING",
  "pages.商家入驻.iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara":
    "iBooming utilizes Artificial Intelligence (AI) to connect Brands with massive and precise Influencer Communities across Indonesia and even Southeast Asia.",
  "pages.商家入驻.Influencer": "Influencer",
  "pages.商家入驻.Product": "Brand",
  "pages.商家入驻.Brand": "Product",
  "pages.商家入驻.Matchmaking Brand dan Influencer dengan AI Tools":
    "Matchmaking Brands and Influencers with AI Tools",
  "pages.商家入驻.Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia": `Extensive Influencer Community 
Network Spanning Indonesia and Southeast Asia.`,
  "pages.商家入驻.Minimalisir Biaya Operasional dan Sumber Daya Manusia":
    "Minimize Operational Costs and Human Resources",
  "pages.商家入驻.Maksimalkan Trafik Persentase Penjualan Produk":
    "Maximize Product Sales Traffic Percentage",
  "pages.商家入驻.One Stop Solution Services dalam Satu Platform":
    "One-Stop Solution Services All on One Platform",
  "pages.商家入驻.Mengapa MOUTH-TO-MOUTH MARKETING di iBooming":
    "Why MOUTH-TO-MOUTH MARKETING on iBooming",
  "pages.商家入驻.Peran Nano-Influencer Marketing":
    "Role of Nano-Influencer Marketing",
  "pages.商家入驻.Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan":
    "The future greatly relies on Nano-Influencers for product promotion and sales enhancement",
  "pages.商家入驻.More Trusted": "More Trusted",
  "pages.商家入驻.Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth":
    "Nano-Influencers build stronger personal relationships with followers, thus creating Mouth-to-Mouth trust",
  "pages.商家入驻.More Profit": "More Profit",
  "pages.商家入驻.Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat":
    "Brands recommended by Nano-Influencers experience exponential sales increases",
  "pages.商家入驻.More Massive": "More Massive",
  "pages.商家入驻.Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer":
    "Unlimited Nano-Influencers with Extensive Reach through Influencer Community",
  "pages.商家入驻.Hasil MOUTH-TO-MOUTH MARKETING di iBooming":
    "Results of MOUTH-TO-MOUTH Marketing on iBooming",
  "pages.商家入驻.SEGERA DAFTARKAN BRAND ANDA": "REGISTER YOUR BRAND NOW",
  "pages.商家入驻.INFLUENCER": "INFLUENCER",
  "pages.商家入驻.BRAND": "BRAND",
  "pages.商家入驻.Jordi": "Jordi",
  "pages.商家入驻.Willie Salim": "Willie Salim",
  "pages.商家入驻.Vilmei": "Vilmei",
  "pages.商家入驻.Awkarin": "Awkarin",
  "pages.商家入驻.Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus":
    "Mouth-to-Mouth Marketing on iBooming can generate billions in revenue through millions of viewers from thousands of Influencers simultaneously",
  "pages.商家入驻.Top Product Sales in Southeast Asia":
    "Top Product Sales in Southeast Asia",
  "pages.商家入驻.Top Product Trending Skincare":
    "Top Product Trending Skincare",
  "pages.商家入驻.Top Product Body Wash": "Top Product Body Wash",
  "pages.商家入驻.Top Product Best Selling Skincare":
    "Top Product Best Selling Skincare",
  "pages.商家入驻.100jt+": "100 million+",
  "pages.商家入驻.Total Impresi": "Total Impressions",
  "pages.商家入驻.1,3M+": "1.3M+",
  "pages.商家入驻.Total Penjualan": "Total Sales",
  "pages.商家入驻.TikTok Southeast Asia Hot Product List":
    "TikTok Southeast Asia Hot Product List",
  "pages.商家入驻.400jt+": "400M+",
  "pages.商家入驻.40.000+": "40K+",
  "pages.商家入驻.Penjualan Harian Rata-Rata": "Average Daily Sales",
  "pages.商家入驻.No. 1": "No. 1",
  "pages.商家入驻.in Skincare Package": "in Skincare Package",
  "pages.商家入驻.Penjualan Bulanan Rata-Rata": "Average Monthly Sales",
  "pages.商家入驻.100.000+": "100.000+",
  "pages.商家入驻.Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu":
    "Fill in the information to join. Our iBooming team will review and connect with you directly",
  "pages.商家入驻.Nama Perusahaan": "Company Name",
  "pages.商家入驻.申请入驻": "Apply",
  "pages.商家入驻.Jenis Usaha": "Business Type",
  "pages.商家入驻.Kategori Produk": "Product Category ",
  "pages.商家入驻.Nomor Kontak Aktif": "Active Contact No.",

  "pages.influencer.RATE CARD": "RATE CARD",
  "pages.influencer.Updated": "Updated",
  "pages.influencer.Category": "Category",
  "pages.influencer.Followers": "Followers",
  "pages.influencer.Accounts Reached": "Accounts Reached",
  "pages.influencer.Accounts Engaged": "Accounts Engaged",
  "pages.influencer.Gender Audience": "Gender Audience",
  "pages.influencer.Average Views": "Average Views",
  "pages.influencer.GMV": "GMV",
  "pages.influencer.Subscribers": "Subscribers",
  "pages.influencer.The above are all data for the past 30 days":
    "The above are all data for the past 30 days",
  "pages.influencer.Short Video TikTok": "Short Video TikTok",
  "pages.influencer.Live Stream TikTok": "Live Stream TikTok",
  "pages.influencer.Reels Instagram": "Reels Instagram",
  "pages.influencer.Feed Instagram": "Feed Instagram",
  "pages.influencer.Story Instagram": "Story Instagram",
  "pages.influencer.Short Video Youtube": "Short Video YouTube",
  "pages.influencer.Video Youtube": "Video YouTube",
  "pages.influencer.Per Video or Per Live Stream Session":
    "Per Video or Per Live Stream Session",
  "pages.influencer.Terms & Condition": "Terms & Conditions",
  "pages.influencer.简介": "Biodata",
  "pages.influencer.已认证": "Verified",
  "pages.influencer.未认证": "Unverified",
};

// TODO":我是其他类型商家这个英文要单独处理上面多个单词不然放不下
