import React from "react";
import new2 from "../../assest/new2-1.png";
import new22 from "../../assest/new2-2.png";
import { FormattedMessage } from "react-intl";
export default function Kojic() {
  return (
    <div>
      <img className="img1" src={new2} alt="" />
      <p>
        <FormattedMessage
          id="pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。"
          defaultMessage="Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.该品牌过去依赖当地的经销商来销售产品，像是传统电商平台Tokopedia，Shopee或Lazada等其他渠道进行销售。"
          defaultMessage="该品牌过去依赖当地的经销商来销售产品，像是传统电商平台Tokopedia，Shopee或Lazada等其他渠道进行销售。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.然而，由于推广的数量以及力度不足，销售一直停滞不前。"
          defaultMessage="然而，由于推广的数量以及力度不足，销售一直停滞不前。"
        />
      </p>
      <img className="img3" src={new22} alt="" />
      <p>
        <FormattedMessage
          id="pages.home.2022年10月，Kojic Plankton与iBooming合作，首次利用KOL（关键意见领袖）和KOC（关键意见消费者）在TikTok上推广其产品。"
          defaultMessage="2022年10月，Kojic Plankton与iBooming合作，首次利用KOL（关键意见领袖）和KOC（关键意见消费者）在TikTok上推广其产品。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.iBooming的团队花费了两个月的时间寻找适合该产品的创作者，并测试了各种内容、定价策略和推广方式等等，以提高产品销售。"
          defaultMessage="iBooming的团队花费了两个月的时间寻找适合该产品的创作者，并测试了各种内容、定价策略和推广方式等等，以提高产品销售。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.到12月底，iBooming的团队成功将该产品推动到东南亚TikTok销售排行榜的前列，在仅仅1天内就获得了超过70,000个订单，对比以往的销量提高了百分比的成绩。"
          defaultMessage="到12月底，iBooming的团队成功将该产品推动到东南亚TikTok销售排行榜的前列，在仅仅1天内就获得了超过70,000个订单，对比以往的销量提高了百分比的成绩。"
        />
      </p>
    </div>
  );
}
