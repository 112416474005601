import React, { useState, useEffect } from "react";
import "./appIndex.scss";
import logo from "../assest/iBooming-logo.png";
import logo2 from "../assest/logo2.png";
import { FormattedMessage } from "react-intl";
import loca from "../assest/icon.png";
import menuClosee from "../warp/images/menu-close.png";
import menu from "../warp/images/menu.png";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "antd";

let appTimer = null;
export default function AppHeader() {
  const navigate = useNavigate();
  // 1是餐单、2是多语言
  const [show, setShow] = useState("");
  const { pathname } = window.location;
  const locale = localStorage.getItem("locale") || "en-us";
  const switchLanguage = (value) => {
    localStorage.setItem("locale", value);
    let str = "";
    if (
      window.location.href.includes("locale=") &&
      !window.location.href.includes("lang=")
    ) {
      str = "&lang=1";
    }
    window.location.href = window.location.href + str;
    // window.location.reload();
  };
  // useEffect(() => {
  //   if (appTimer) {
  //     clearInterval(appTimer);
  //   }
  //   // console.log(appIsFirst);
  //   // if (appIsFirst) {
  //   // }
  //   const oul = document.getElementById("oul");
  //   const olis = oul.getElementsByTagName("img");
  //   let indexNum = 0;
  //   const ofst = document.createElement("img");
  //   ofst.src = olis[1].src;
  //   ofst.className = olis[1].className;
  //   oul.appendChild(ofst);
  //   const liLens = olis.length;
  //   // 每隔1800ms执行
  //   appTimer = setInterval(() => {
  //     indexNum++;
  //     //判定是否到达最后行
  //     if (indexNum < liLens) {
  //       // recall = 0;
  //       oul.style.transition = "transform .5s ease-in"; //添加css过渡时的效果
  //       // 每次滚动的距离是 单行高*行数
  //       oul.style.transform = "translateY(-" + 0.4 * indexNum + "rem)";
  //     } else {
  //       indexNum = 0;
  //       oul.style.transition = ""; //去除css过渡效果
  //       oul.style.transform = "translateY(-" + 0.4 * indexNum + "rem)";
  //       setTimeout(function () {
  //         indexNum = 1;
  //         oul.style.transition = "transform .5s ease-in";
  //         oul.style.transform = "translateY(-" + 0.4 * indexNum + "rem)";
  //       }, 100);
  //     }
  //   }, 1800);
  //   return () => {
  //     clearInterval(appTimer);
  //   };
  // }, []);
  const localeEnum = [
    {
      label: "中文(简体)",
      key: "zh-cn",
    },
    {
      label: "English (us)",
      key: "en-us",
    },
    {
      label: "Bahasa Indonesia",
      key: "id-id",
    },
    {
      label: "ไทย",
      key: "th-th",
    },
    {
      label: "Tiếng Việt",
      key: "vn-vn",
    },
  ];
  return (
    <div className="app-head">
      <div className="flexB">
        <a className="app-logo-warp" href={window.location.origin + "/home"}>
          {/* <div id="oul">
            <img className="app-logo" src={logo} alt="ibooming" />
            <img className="app-logo" src={logo2} alt="tiktok" />
            <img className="app-logo" src={logo} alt="ibooming" />
            <img className="app-logo" src={logo2} alt="tiktok" />
            <img className="app-logo" src={logo} alt="ibooming" />
            <img className="app-logo" src={logo2} alt="tiktok" />
            <img className="app-logo" src={logo} alt="ibooming" />
          </div> */}
          {/* <img className="app-logo" src={logo} alt="iBooming" /> */}
          <Carousel dotPosition="right" autoplay dots={false}>
            <div className="height">
              <img className="app-logo" src={logo} alt="ibooming" />
            </div>
            <div className="height2">
              <img className="app-logo logo1" src={logo2} alt="tiktok" />
            </div>
            <div className="height">
              <img className="app-logo" src={logo} alt="ibooming" />
            </div>
            <div className="height2">
              <img className="app-logo logo1" src={logo2} alt="tiktok" />
            </div>
          </Carousel>
        </a>
        <div>
          <a className="app-menu" onClick={() => setShow(show ? "" : "1")}>
            <img src={show == "1" ? menuClosee : menu} />
          </a>
          <a className="app-lang" onClick={() => setShow(show ? "" : "2")}>
            {show == "2" ? (
              <img
                style={{
                  marginRight: 0,
                }}
                src={menuClosee}
              />
            ) : (
              <>
                <img src={loca} alt="" />
                {(locale.split("-")[1] || "us").toUpperCase()}
              </>
            )}
          </a>
        </div>
      </div>
      <div className="app-modal" style={{ display: show ? "block" : "none" }}>
        <div className="app-modal-content">
          {show === "1" ? (
            <ul onClick={() => setShow("")}>
              <li
                className={
                  pathname == "/home" || pathname == "/" ? "active" : ""
                }
              >
                <a href="/home">
                  <FormattedMessage
                    id="pages.home.首页"
                    defaultMessage="首页"
                  />
                </a>
              </li>
              <li className={pathname == "/business" ? "active" : ""}>
                <a href="/business">
                  <FormattedMessage
                    id="pages.home.我是商家"
                    defaultMessage="我是商家"
                  />
                </a>
              </li>
              <li className={pathname == "/talent" ? "active" : ""}>
                <a href="/talent">
                  <FormattedMessage
                    id="pages.home.我是网红"
                    defaultMessage="我是网红"
                  />
                </a>
              </li>
              <li className={pathname == "/about" ? "active" : ""}>
                <a
                  href={pathname.includes("home") ? "#aboutMap" : "/home#about"}
                >
                  <FormattedMessage
                    id="pages.home.关于我们"
                    defaultMessage="关于我们"
                  />
                </a>
              </li>
              <li
                className={
                  pathname == "/brand" || pathname.includes("/brand/detail")
                    ? "active"
                    : ""
                }
              >
                <a
                  href={pathname.includes("home") ? "#brands" : "/home#brands"}
                >
                  <FormattedMessage
                    id="pages.home.经典案例"
                    defaultMessage="经典案例"
                  />
                </a>
              </li>
              <li
                className={
                  pathname == "/newslist" || pathname.includes("/news/detail")
                    ? "active"
                    : ""
                }
              >
                <a href="/newslist">
                  <FormattedMessage
                    id="pages.home.平台新闻"
                    defaultMessage="平台新闻"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              {localeEnum.map((item) => (
                <li
                  onClick={() => switchLanguage(item.key)}
                  key={item.key}
                  className={item.key === locale ? "active" : ""}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* <div className='app-mark'></div> */}
      </div>
    </div>
  );
}
