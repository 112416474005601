import React, { useState, useEffect } from "react";
import PCWeb from "./pc";
import APPWeb from "./warp";
import JoinMCN from "./warp/MCN";
import Settle from "./warp/Settle";
import "./App.scss";
// import "antd/build/antd.min.css";
// import "antd/dist/antd.css";
export default function App() {
  const pathname = window.location.pathname;
  // return <PCWeb />;
  if (pathname === "/mcn") {
    return <JoinMCN />;
  } else if (pathname === "/settle") {
    return <Settle />;
  }
  return document.body.clientWidth > 800 ? <PCWeb /> : <APPWeb />;
}
