import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import en from "./locales/en-US";
import ID from "./locales/id-ID";
import VN from "./locales/vn-VN";
import TH from "./locales/th-TH";
import ZH from "./locales/zh-CN";

const root = ReactDOM.createRoot(document.getElementById("root"));
const messages = {
  "en-us": en,
  "id-id": ID,
  "vn-vn": VN,
  "th-th": TH,
  "zh-cn": ZH,
};

const locale = localStorage.getItem("locale") || "en-us";
root.render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={messages[locale]}>
      <App />
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
