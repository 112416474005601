import React, { useState, useEffect } from "react";
import logo from "../assest/iBooming-logo.png";
import icon from "../assest/facebook.png";
import icon1 from "../assest/instagram-fill.png";
import tiktok from "../assest/tiktok.png";
import { FormattedMessage, useIntl } from "react-intl";
import { message } from "antd";
import wechat1 from "../assest/wechat1.png";
import wi from "../assest/ws.png";
import wi2 from "../assest/ws2.png";
import wi3 from "../assest/ws3.png";
import wi4 from "../assest/ws4.png";
import wi5 from "../assest/ws5.png";
import country1 from "../assest/country1.png";
import country2 from "../assest/country2.png";
import country3 from "../assest/country3.png";
import country4 from "../assest/country4.png";
import country5 from "../assest/country5.png";
import country6 from "../assest/country6.png";
// import partners from "../assest/partners.png";
// import partners1 from "../assest/partners1.png";
// import partners2 from "../assest/partners2.png";
// import partners3 from "../assest/partners3.png";
import ftBrand from "../assest/footer_brand.png";
import wechat from "../assest/wechat.png";
import whats1 from "../assest/whats1.png";
import whats2 from "../assest/wi2.png";
import whats3 from "../assest/wi3.png";
import whats5 from "../assest/wi5.png";
import copyimg from "../assest/copy.png";
import copy from "copy-to-clipboard";
import { useLocation } from "react-router-dom";
import "./pcIndex.scss";
let timer = null;
export default function PcFooter() {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    console.log("pathname:", pathname);
  }, [pathname]);

  const onCopy = (num) => {
    copy(num);
    message.success({
      content: intl.formatMessage({
        id: "pages.home.复制成功",
        defaultMessage: "复制成功",
      }),
      className: "pc-toast-warp",
      style: {
        marginTop: "47vh",
      },
    });
  };
  return pathname.includes("/certified") ? null : (
    <>
      <div id="contact"></div>
      <div className="footer-border"></div>
      <div className="pc-content">
        <h3 className="sub-title">
          <FormattedMessage
            id="pages.home.联系我们"
            defaultMessage="联系我们"
          />
        </h3>
        <div className="pc-contact">
          {/* 印尼 */}
          <div>
            <div className="country-info">
              <img src={country1} alt="" />
              <span>
                <FormattedMessage id="pages.home.印尼" defaultMessage="印尼" />
              </span>
            </div>
            <div className="flexB">
              <img width={80} src={wi} alt="" />
              <div className="fnML24">
                <h3 className="title">
                  <img
                    width={18}
                    style={{ float: "left", marginRight: 12 }}
                    src={whats1}
                    alt=""
                  />
                  WhatsApp
                </h3>
                <p className="phone">
                  +62 87767073446
                  <img
                    onClick={() => onCopy("+62 87767073446")}
                    className="pc-middle"
                    src={copyimg}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* 泰国 */}
          <div>
            <div className="country-info">
              <img src={country2} alt="" />
              <span>
                <FormattedMessage id="pages.home.泰国" defaultMessage="泰国" />
              </span>
            </div>
            <div className="flexB">
              <img width={80} src={wi2} alt="" />
              <div className="fnML24">
                <h3 className="title">
                  <img
                    width={18}
                    style={{ float: "left", marginRight: 12 }}
                    src={whats2}
                    alt=""
                  />
                  LINE
                </h3>
                <p className="phone">
                  +66 621768356
                  <img
                    onClick={() => onCopy("+66 621768356")}
                    className="pc-middle"
                    src={copyimg}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* 越南 */}
          <div>
            <div className="country-info">
              <img src={country3} alt="" />
              <span>
                <FormattedMessage id="pages.home.越南" defaultMessage="越南" />
              </span>
            </div>
            <div className="flexB">
              <img width={80} src={wi3} alt="" />
              <div className="fnML24">
                <h3 className="title">
                  <img
                    width={18}
                    style={{ float: "left", marginRight: 12 }}
                    src={whats3}
                    alt=""
                  />
                  Zalo
                </h3>
                <p className="phone">
                  +84 587189402
                  <img
                    onClick={() => onCopy("+84 587189402")}
                    className="pc-middle"
                    src={copyimg}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* 马来西亚 */}
          <div>
            <div className="country-info">
              <img src={country4} alt="" />
              <span>
                <FormattedMessage
                  id="pages.home.马来西亚"
                  defaultMessage="马来西亚"
                />
              </span>
            </div>
            <div className="flexB">
              <img width={80} src={wi4} alt="" />
              <div className="fnML24">
                <p className="phone">Fast Moving Tech Sdn Bhd</p>
                <h3 className="title">
                  <img
                    width={18}
                    style={{ float: "left", marginRight: 12 }}
                    src={whats1}
                    alt=""
                  />
                  WhatsApp
                </h3>
                <p className="phone">
                  +60 1161636185
                  <img
                    onClick={() => onCopy("+60 1161636185")}
                    className="pc-middle"
                    src={copyimg}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* 菲律宾 */}
          <div>
            <div className="country-info">
              <img src={country5} alt="" />
              <span>
                <FormattedMessage
                  id="pages.home.菲律宾"
                  defaultMessage="菲律宾"
                />
              </span>
            </div>
            <div className="flexB">
              <img width={80} src={wi5} alt="" />
              <div className="fnML24">
                <h3 className="title">
                  <img
                    width={18}
                    style={{ float: "left", marginRight: 12 }}
                    src={whats5}
                    alt=""
                  />
                  Viber
                </h3>
                <p className="phone">
                  +63 9660614731
                  <img
                    onClick={() => onCopy("+63 9660614731")}
                    className="pc-middle"
                    src={copyimg}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* 中国 */}
          <div>
            <div className="country-info">
              <img src={country6} alt="" />
              <span>
                <FormattedMessage id="pages.home.中国" defaultMessage="中国" />
              </span>
            </div>
            <div className="flexB">
              <img width={80} src={wechat} alt="" />
              <div className="fnML24">
                <h3 className="title">
                  <img
                    width={18}
                    style={{ float: "left", marginRight: 12 }}
                    src={wechat1}
                    alt=""
                  />
                  <FormattedMessage
                    id="pages.home.企业微信"
                    defaultMessage="企业微信"
                  />
                </h3>
                {/* <p className="phone">iBooming</p> */}
              </div>
            </div>
          </div>
        </div>
        <h3 className="sub-title">
          <FormattedMessage
            id="pages.home.合作媒体"
            defaultMessage="合作媒体"
          />
        </h3>
        <img className="pc-brand-img" src={ftBrand} alt="" />
        {/* <div className="flexB pc-contact" style={{ marginBottom: 0 }}>
          <div className="flexB">
            <img width={56} src={partners} alt="" />
            <div className="fnML24">
              <h3 className="other-title">
                <FormattedMessage
                  id="pages.home.滴答狗"
                  defaultMessage="滴答狗"
                />
              </h3>
              <a
                target="_blank"
                href="https://www.didadog.com/"
                className="website"
              >
                www.didadog.com
              </a>
            </div>
          </div>
          <div className="flexB">
            <img width={64} src={partners2} alt="" />
            <div className="fnML24">
              <h3 className="other-title">
                <FormattedMessage
                  id="pages.home.大卫国际物流"
                  defaultMessage="大卫国际物流"
                />
              </h3>
              <a
                target="_blank"
                href="https://www.dwlogistics365.com"
                className="website"
              >
                www.dwlogistics365.com
              </a>
            </div>
          </div>
          <div className="flexB">
            <img width={56} src={partners1} alt="" />
            <div className="fnML24">
              <h3 className="other-title">
                <FormattedMessage
                  id="pages.home.DNY123"
                  defaultMessage="DNY123"
                />
              </h3>
              <a
                target="_blank"
                href="https://www.dny123.com"
                className="website"
              >
                www.dny123.com
              </a>
            </div>
          </div>
          <div className="flexB">
            <img width={56} src={partners3} alt="" />
            <div className="fnML24">
              <h3 className="other-title">CPSEA</h3>
              <a
                target="_blank"
                href="https://www.cpsea.com"
                className="website"
              >
                www.cpsea.com
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <div className="footer">
        <div className="flexB">
          <div>
            <img className="logo" src={logo} alt="FreeBox" />
            {/* <p className="email">Website developed by iBooming</p> */}
            <div className="text">
              <a href="/privacy/index.html">Privacy policy</a>
              <a href="/tc/index.html" className="fnML43">
                Term of service
              </a>
            </div>
            <p className="email">Website developed by iBooming</p>
            {/* <p className="email">Website develop by PT Tik Star Media</p> */}
          </div>
          <div style={{ width: 220 }}>
            <div className="icon">
              <a href="https://www.facebook.com/iBoomingOfficial">
                <img width={24} height={24} src={icon} alt="" />
              </a>
              <a
                href="https://www.instagram.com/ibooming_id"
                style={{ marginLeft: 10 }}
              >
                <img width={24} src={icon1} alt="" />
              </a>
              <a
                href="https://www.tiktok.com/@iboomingofficial"
                style={{ marginLeft: 10 }}
              >
                <img width={24} src={tiktok} alt="" />
              </a>
            </div>
            <p className="email" style={{ marginTop: 20 }}>
              <FormattedMessage
                id="pages.home.电子邮箱"
                defaultMessage="电子邮箱"
              />
            </p>
            <p className="email" style={{ marginTop: 8 }}>
              support@iboomingglobal.com{" "}
              <img
                onClick={() => onCopy("support@iboomingglobal.com")}
                className="pc-middle"
                src={copyimg}
                alt="ibooming.care@gmail.com"
              />
            </p>
          </div>
        </div>
      </div>
      {show && (
        <div className="pc-toast-warp">
          <div className="toast-main">
            <FormattedMessage
              id="pages.home.复制成功"
              defaultMessage="Copy successfully"
            />
          </div>
        </div>
      )}
    </>
  );
}
