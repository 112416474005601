import React, { useEffect, useState } from "react";
import "./index.scss";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import config from "../../utils/config";
import newdright from "../../assest/newdright.png";
import { getOSSImgUrl } from "../../utils";
export default function APPNewsDetail() {
  const id = useParams().id;
  const [data, setData] = useState({});
  useEffect(() => {
    const url = `${config.newsUrl}/bg/api/resource/info`;
    const param = {
      id,
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        console.log(res);
        setData(res?.data?.data || {});
      })
      .catch((err) => {
        console.log("onError", err);
      });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  // useEffect(() => {
  //   try {
  //     console.log(111);
  //     // 获取 video 元素
  //     const video = document.getElementById("myVideo");

  //     // 监听 video 的 loadeddata 事件
  //     // 创建一个 Canvas 元素
  //     var canvas = document.createElement("canvas");
  //     canvas.width = video.videoWidth;
  //     canvas.height = video.videoHeight;

  //     // 获取 2D 上下文
  //     var context = canvas.getContext("2d");

  //     // 在 Canvas 上绘制视频的第一帧
  //     context.drawImage(video, 0, 0, canvas.width, canvas.height);

  //     // 将 Canvas 转换为 data URL
  //     var dataURL = canvas.toDataURL("image/jpeg");

  //     // 创建一个图片元素用于显示封面图
  //     var img = document.createElement("img");
  //     img.src = dataURL;

  //     // 将图片元素添加到页面
  //     document.body.appendChild(img);
  //     // video.addEventListener("loadeddata", function () {
  //     // });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);
  const content = (data.detailContent || "").split("\n") || [];
  const renderVideo = () => {
    const extraConfig = data.extraConfig
      ? JSON.parse(data.extraConfig || "")
      : {};
    const videoUrl = extraConfig.videoUrl || "";
    // const videoCover = extraConfig.videoCover || "";
    const videoCover = extraConfig.videoCover
      ? getOSSImgUrl(extraConfig.videoCover, 750, 90)
      : "";
    if (data.detailHeadImg && !data.detailHeadImg.includes(".mp4")) {
      return <img src={getOSSImgUrl(data.detailHeadImg, 750, 90)} alt="" />;
    } else if (
      videoUrl ||
      (data.detailHeadImg && data.detailHeadImg.includes(".mp4"))
    ) {
      return (
        <video
          poster={videoCover}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "0.4rem",
            marginBottom: "0.32rem",
          }}
          controls
          autoPlay
          loop="loop"
        >
          <source
            src={videoUrl || data.detailHeadImg}
            type="video/mp4"
          ></source>
          Your browser does not support the video tag.
        </video>
      );
    }
  };
  const renderContent = () => {
    if ((data.detailContent || "").includes("<p>")) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: data.detailContent || "" }}
        ></div>
      );
    }
    const content = (data.detailContent || "").split("\n") || [];
    return content.map((item, index) => <p key={index}>{item}</p>);
  };
  return (
    <div className="app-new-content">
      {/* <div className="crumbs">
        <Link to="/home">
          <FormattedMessage id="pages.home.首页" defaultMessage="首页" />
        </Link>
        <img src={newdright} alt="" />
        <Link to="/newslist">
          <FormattedMessage
            id="pages.home.平台新闻"
            defaultMessage="平台新闻"
          />
        </Link>
        <img src={newdright} alt="" />
        <a className="active">{data.showTitle}</a>
      </div> */}
      <h3 className="title">{data.showTitle}</h3>
      <div className="news-detail">
        {renderVideo()}
        {/* {content.map((item, index) => (
          <p key={index}>{item}</p>
        ))} */}
        {renderContent()}
        <div className="fix-text">
          <h4>About iBooming</h4>
          <p>
            iBooming is a technology company specializing in developing
            artificial intelligence solutions for the digital content industry.
            Through AI, iBooming enables influencers and brands to optimize
            content strategies, expand reach, and increase monetization. With a
            commitment to innovation, iBooming aims to be a leader in AI-powered
            industry change, enabling influencers and brands to increase product
            visibility and achieve success in the global marketplace. For more
            information about iBooming, visit www.iboomingglobal.com
          </p>
        </div>
      </div>
    </div>
  );
}
