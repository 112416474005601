export default {
  "pages.home.东南亚": "ใหญ่ที่สุด",
  "pages.home.最大": "ในเอเชียตะวันออกเฉียงใต้",
  "pages.home.我是商家": "ฉันคือผู้ขาย",
  "pages.home.我是网红": "ฉันคือครีเอเตอร์",
  "pages.home.联系我们": "ติดต่อเรา",
  "pages.home.经典案例": "ความสำเร็จของเรา",
  "pages.home.平台新闻": "ข่าวสารเเพลตฟอร์ม",
  "pages.home.中文": "中文",
  "pages.home.海外网红矩阵式分销平台": "แพลตฟอร์มการกระจายสินค้า",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台":
    "iBooming เป็นแพลตฟอร์มการตลาดที่ขับเคลื่อนด้วยเทคโนโลยี",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长": `"ส่งเสริมให้คนที่ใช่ได้เลือกสินค้า Affiliate ที่เหมาะสม 
    และมั่นใจว่าสินค้าที่ดีได้ถูกจับคู่กับคนที่มีคุณภาพ 
    เพิ่มขีดความสามารถทางธุรกิจของคุณโดยการลดต้นทุน
    และเพิ่มประสิทธิภาพทางธุรกิจการจัดจำหน่าย
    ให้มีการเติบโตอย่างก้าวกระโดดผ่านระบบ AI อัจฉริยะ"`,
  "pages.home.我是网红": "ฉันคือครีเอเตอร์",
  "pages.home.我是商家": "ฉันคือผู้ขาย",
  "pages.home.我是机构": "ฉันคือเอเจนซี่",
  "pages.home.活跃网红": "ครีเอเตอร์ที่คล่องแคล่ว",
  "pages.home.每日动销网红": "ยอดขายรายวันของครีเอเตอร์",
  "pages.home.合作商家": `เข้าร่วมเป็น
  พาร์ทเนอร์กับแบรนด์`,
  "pages.home.我们的": "ขอบเขตธุรกิจ",
  "pages.home.业务范围": "ของเรา",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台。":
    "iBooming เป็นแพลตฟอร์มการตลาดดิจิทัลที่ขับเคลื่อนด้วยเทคโนโลยี",
  "pages.home.通过智能算法，为商家精准匹配大量营销带货网红，快速在TikTok以网红矩阵销售产品以及推广品牌；为网红精准匹配智能货盘以及营销任务，网红通过iBooming可以快速在TikTok提升账号动销率，提高收益。":
    "ระบบ AI อัจฉริยะจะช่วยจับคู่แบรนด์ผลิตภัณฑ์กับครีเอเตอร์ที่มีความเชี่ยวชาญด้านการขายสินค้าได้อย่างแม่นยำ ผ่านการสร้าง Dynamic matrix สำหรับการขายผลิตภัณฑ์และการโปรโมตแบรนด์บน TikTok โดยที่ระบบการจับคู่ของเราจะจับคู่เหล่าครีเอเตอร์กับผลิตภัณฑ์บนตลาดสินค้ารวมไปถึงมิชชั่นการขาย เพื่อช่วยให้เหล่าครีเอเตอร์สามารถเพิ่มยอดขายสินค้าและเพิ่มรายได้บนช่อง TikTokได้อย่างรวดเร็วผ่านช่องทางของ iBooming",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长。":
    "ส่งเสริมให้คนที่ใช่ได้เลือกสินค้า Affiliate ที่เหมาะสม และมั่นใจว่าสินค้าที่ดีได้ถูกจับคู่กับคนที่มีคุณภาพ เพิ่มขีดความสามารถทางธุรกิจของคุณโดยการลดต้นทุนและเพิ่มประสิทธิภาพทางธุรกิจการจัดจำหน่ายให้มีการเติบโตอย่างก้าวกระโดดผ่านระบบอัจฉริยะ",
  "pages.home.团长招募": "รับสมัครหัวหน้าทีม",
  "pages.home.我们一直在寻找优秀的网红领导人，在iBooming您的团队可以快速为您创造更高的带货收益，并能轻松的智能化管理您的网红团队；如果您有网红资源，想在市场营销和娱乐领域创造一个稳定的事业，与全球互联网人才一起共创美好未来，我们将非常欢迎您的加入!": `เรามองหาผู้นำครีเอเตอร์ที่มีความสามารถโดดเด่นอยู่เสมอ 
    การทำงานร่วมกับiBooming จะช่วยให้ทีมของคุณ
    สร้างรายได้ที่สูงขึ้นจากการขายสินค้าให้กับคุณได้อย่างรวดเร็ว 
    และช่วยจัดการทีมของคุณผ่านระบบ AI ที่ชาญฉลาดได้อย่างง่ายดาย 
    หากคุณมีเหล่าครีเอเตอร์ที่ต้องการสร้างอาชีพที่มั่นคง
    ในด้านการตลาดและความบันเทิงอยู่ในมือ มาร่วมสร้างอนาคตที่ดีกว่า
    กับผู้ที่มีความเชี่ยวชาญในโลกตลอดออนไลน์ พวกเรารอต้อนรับคุณอยู่นะ!`,
  "pages.home.最新动态": "อัปเดตล่าสุด",
  "pages.home.亿": "ล้าน",
  "pages.home.总曝光量": "จำนวนเข้าชมทั้งหมด",
  "pages.home.总销售额(美元)": "ยอดขายทั้งหมด(USD)",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo เป็นแบรนด์ระดับสากลที่มุ่งการพัฒนาและผลิตสินค้าด้านความงามและของใช้ส่วนตัว",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton เป็นแบรนด์ความงามและของใช้ส่วนตัวที่เชี่ยวชาญด้านการพัฒนาสบู่ไวท์เทนนิ่ง",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为":
    "Feali เป็นแบรนด์ระดับสากลที่เชี่ยวชาญด้านการพัฒนาและผลิตเครื่องสำอางค์ ในเดือนกุมภาพันธ์ 2023 Feali ได้เปิดตัวผลิตภัณฑ์ดูแลผิวอันเป็นเอกลักษณ์ที่เรียกว่า:",
  "pages.home.您好,": "สวัสดี",
  "pages.home.商家": "ผู้ขาย",
  "pages.home.网红": "ครีเอเตอร์",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok达人组织建立快速响应系统":
    "iBooming ร่วมมือกับเหล่าผู้นำทีมจำนวนนับไม่ถ้วนในการสร้าง TikTok Influencer Affiliation Matrix ซึ่งเป็นตัวช่วยในการกระจายบนแพลตฟอร์ม TikTok และสร้างระบบตอบสนองที่รวดเร็ว เพื่อมารองรับเหล่าครีเอเตอร์มากกว่า 800,000 คน บนTikTok",
  "pages.home.立即报名": "สมัครตอนนี้!",
  "pages.home.立即加入": "เข้าร่วมตอนนี้!",
  "pages.home.80万+ 带货网红": "800K+ Creators",
  "pages.home.我是TikTok": "TikTok",
  "pages.home.头部卖家": "ผู้ขายยอดขายดี",
  "pages.home.新卖家": "ผู้ขายหน้าใหม่",
  "pages.home.我是其他": "ฉันคือ",
  "pages.home.类型商家": "ผู้ขายประเภทอื่นๆ",
  "pages.home.为什么选择iBooming": "ทำไมต้องเลือกiBooming",
  "pages.home.无前置费用": `ไม่มีค่าธรรมเนียม
  ล่วงหน้า`,
  "pages.home.矩阵分销": "Matrix Affiliation",
  "pages.home.快速测品": `การทดสอบ
  ผลิตภัณฑ์ที่รวดเร็ว`,
  "pages.home.本土服务": "พื้นที่ให้บริการ",
  "pages.home.iBooming不收取任何前置服务费，品牌商可以将所有推广费用花在刀刃上，为您的投产效益做到最大化。":
    "iBooming ไม่มีการเรียกเก็บค่าบริการล่วงหน้า เจ้าของแบรนด์สามารถใช้ต้นทุนการโปรโมตสินค้าทั้งหมด ในการจัดสรรเปลี่ยนเป็นการเพิ่มประสิทธิภาพแก่การผลิตที่สูงสุด",
  "pages.home.iBooming服务类型": "ประเภทการให้บริการของiBooming",
  "pages.home.抢占海外网红分销的红利":
    "ไขว่คว้าผลประโยชน์จากครีเอเตอร์ของบริษัทในต่างประเทศ",
  "pages.home.立即匹配": "จับคู่ตอนนี้",
  "pages.home.#服务1": "บริการที่ 1",
  "pages.home.#服务2": "บริการที่ 2",
  "pages.home.#服务3": "บริการที่ 3",
  "pages.home.#服务4": "บริการที่ 4",
  "pages.home.服务1": "บริการที่ 1",
  "pages.home.服务2": "บริการที่ 2",
  "pages.home.服务3": "บริการที่ 3",
  "pages.home.服务4": "บริการที่ 4",
  "pages.home.AI matching TikTok Affiliate Partner":
    "AI matching TikTok Affiliate Partner",
  "pages.home.Product Analysis": "การวิเคราะห์ผลิตภัณฑ์",
  "pages.home.iBooming analyse your product label and potential influencer demographics information through big data":
    "iBooming มีการใช้ชุดข้อมูลขนาดใหญ่ในการวิเคราะห์ฉลากผลิตภัณฑ์และข้อมูลการขายผลิตภัณฑ์Affiliate เพื่อความแม่นยำในการจับคู่ผลิตภัณฑ์กับครีเอเตอร์",
  "pages.home.Celebrity Marketing": "Celebrity Marketing",
  "pages.home.Top Influencer Effect": "Top Creator Effect",
  "pages.home.iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility":
    "iBooming มีความเป็นมืออาชีพในการนำผลิตภัณฑ์ของคุณจับคู่เข้ากับดาราหรือท็อปครีเอเตอร์ที่มีความเหมาะสมที่สุด เพื่อช่วยเพิ่มการรับรู้ถึงแบรนด์ของคุณได้อย่างรวดเร็ว",
  "pages.home.Influencer Matrix Promotion": "Influencer Matrix Promotion",
  "pages.home.Matrix Affiliate": "Matrix Affiliate",
  "pages.home.iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement": `iBooming เป็นแพลตฟอร์มกระจายสินค้า ที่มีการดำเนินงานโดยครีเอเตอร์ที่มีความเชี่ยวชาญ
    ช่วยให้เกิดการขยายการมองเห็นผลิตภัณฑ์ได้อย่างมีประสิทธิภาพ และทำให้ครีเอเตอร์มี engagement ที่ดีขึ้น`,
  "pages.home.TikTok Advertising": "TikTok Advertising",
  "pages.home.Improve results": "ยกระดับผลลัพธ์",
  "pages.home.iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment":
    "บริการยิงโฆษณา(Ads) ของทาง iBooming มีการใช้ทรัพยากรเนื้อหาคอนเท้นต์ที่มีคุณภาพมากมาย เพื่อให้มั่นใจได้ว่าจะสามารถเพิ่มประสิทธิภาพและได้รับผลตอบแทนสูงสุดจากการลงทุนโฆษณา",
  "pages.home.为您精准匹配智能货盘以及营销任务，通过iBooming您可以快速在TikTok提升账号动销率，提高收益":
    "iBooming จะทำการจับคู่เหล่าครีเอเตอร์กับผลิตภัณฑ์บนตลาดสินค้ารวมไปถึงมิชชั่นการขาย เพื่อช่วยให้เหล่าครีเอเตอร์สามารถเพิ่มยอดขายสินค้าและเพิ่มรายได้บนช่อง TikTokได้อย่างรวดเร็วผ่านช่องทางของ iBooming",
  "pages.home.加入网红社群": "เข้าร่วมคอมมูนิตี้ของเหล่าครีเอเตอร์",
  "pages.home.已有": "มีจำนวน",
  "pages.home.万+": "หมื่น+",
  "pages.home.动销网红加入我们": "ครีเอเตอร์ที่เข้าร่วม",
  "pages.home.已入驻": "เปิดตัวแล้ว",
  "pages.home.国家（印尼，泰国，越南，马来西亚，菲律宾）":
    "ประเทศ (อินโดนีเซีย ไทย เวียดนาม มาเลเซีย ฟิลิปปินส์)",
  "pages.home.当前已开通5个国家，突破80万带货网红，成为东南亚最大的TikTok网红分销平台":
    "ปัจจุบันได้เปิดทำการแล้วใน 5 ประเทศ มีครีเอเตอร์มากกว่า 80K ที่ทำการขายสินค้า Affiliate ทำให้เราเป็นแพลตฟอร์มการกระจายสินค้า Affiliate บน TikTok ที่ใหญ่ที่สุดในเอเชียตะวันออกเฉียงใต้",
  "pages.home.TOP": "TOP",
  "pages.home.网红2": " Creators",
  "pages.home.快加入我们": "เข้าร่วมกับเราตอนนี้",
  "pages.home.加入网红社群": "เข้าร่วมคอมมูนิตี้ของเหล่าครีเอเตอร์",
  "pages.home.八十万网红池": "ครีเอเตอร์กว่า 800,000 คน",
  "pages.home.各类目网红满足您的商业需求":
    "ครีเอเตอร์มากมายหลายประเภทเพื่อตอบสนองความต้องการทางธุรกิจของคุณ",
  "pages.home.立即咨询我们": "ติดต่อเราทันที",
  "pages.home.提交": "ส่ง",
  "pages.home.#步骤1": "ขั้นตอนที่ 1",
  "pages.home.#步骤2": "ขั้นตอนที่ 2",
  "pages.home.#步骤3": "ขั้นตอนที่ 3",
  "pages.home.#步骤4": "ขั้นตอนที่ 4",
  "pages.home.注册填写您的品牌": "ลงทะเบียนและกรอกข้อมูลแบรนด์ของคุณ",
  "pages.home.AI Booms 工具扫描您的店铺信息":
    "เครื่องมือ AI Booms สแกนข้อมูลร้านค้าของคุณ",
  "pages.home.AI Booms 为您提供符合您企业的最佳推广方案":
    "AI Booms มอบแนวทางส่งเสริมการขายที่ดีและเหมาะสมที่สุดสำหรับธุรกิจของคุณ",
  "pages.home.享受您的品牌流量": "เพลิดเพลินกับการเข้าชมที่แบรนด์ของคุณได้รับ",
  "pages.home.平台新闻": "ข่าวสารเเพลตฟอร์ม",
  "pages.home.不要错过与iBooming的每一刻": "อย่าพลาดช่วงเวลาสำคัญกับ iBooming",
  "pages.home.继续阅读": "อ่านต่อ",
  "pages.home.您的企业名称": "ชื่อธุรกิจของคุณ",
  "pages.home.企业所在国家": "ประเทศที่บริษัทตั้งอยู่",
  "pages.home.选择类目": "เลือกประเภท",
  "pages.home.印度尼西亚": "อินโดนีเซีย",
  "pages.home.泰国": "ไทย",
  "pages.home.越南": "เวียดนาม",
  "pages.home.马来西亚": "มาเลเซีย",
  "pages.home.菲律宾": "ฟิลิปปินส์",
  "pages.home.美妆": "ความงามและเครื่องสำอางค์",
  "pages.home.电子": "อิเล็กทรอนิกส์",
  "pages.home.服饰": "เสื้อผ้า",
  "pages.home.食品": "อาหาร",
  "pages.home.家居生活": "บ้านและไลฟ์สไตล์",
  "pages.home.母婴": "แม่และเด็ก",
  "pages.home.个护和健康": "ของใช้ส่วนตัวและสุขภาพ",
  "pages.home.恭喜您提交成功": "ยินดีด้วย คุณส่งสำเร็จแล้ว",
  "pages.home.我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作":
    "เจ้าหน้าที่ของเราจะติดต่อหาคุณโดยเร็วที่สุด นอกจากนี้ คุณยังสามารถลงทะเบียน TAP เพื่อร่วมงานกับทางเราได้โดยตรง",
  "pages.home.前往 TikTok TAP报名": "ไปที่ TikTok TAP เพื่อลงทะเบียน",
  "pages.home.iBooming平台超大的网红活跃人数，可以快速让网红生产带货短视频以及直播带货，形成网红推品矩阵，让产品可以更大范围的覆盖到TikTok的消费用户，提升动销率，快速打爆产品。":
    "แพลตฟอร์ม iBooming มีครีเอเตอร์จำนวนมาก สามารถให้เหล่าครีเอเตอร์สร้างวิดีโอสั้นและไลฟ์สดเพื่อโปรโมตผลิตภัณฑ์ได้อย่างรวดเร็ว เมทริกซ์ที่ช่วยแนะนำผลิตภัณฑ์ให้กับเหล่าครีเอเตอร์ มีผลให้ตัวผลิตภัณฑ์สามารถครอบคลุมกลุ่มผู้บริโภคบน TikTok ได้กว้างขวางยิ่งขึ้น และช่วยเพิ่มยอดขาย ทำให้ผลิตภัณฑ์ขายดีได้อย่างรวดเร็ว",
  "pages.home.新品可以通过iBooming匹配小规模网红，以小微矩阵的形式快速获得市场多维度数据反馈，让品牌商可以更快的布局市场以及迭代产品。":
    "สินค้าใหม่ๆสามารถจับคู่กับกลุ่มครีเอเตอร์ระดับทั่วไปผ่านทาง iBooming เพื่อให้ได้รับฟีดแบคจากกลุ่มตลาดสินค้าอย่างรวดเร็ว ช่วยให้ทางแบรนด์สามารถรับรู้ถึงปัญหาและคิดหากลยุทธ์เพื่อมาปรับเปลี่ยนวิธีการทำการตลาดได้อย่างรวดเร็ว",
  "pages.home.iBooming在东南亚各国都有本土的服务团队，提供免费咨询以及市场调研，更加本土化的基因可以规避品牌出海的水土不服。":
    "iBooming มีทีมงานคอยให้บริการสำหรับพื้นที่ต่างๆในประเทศโซนเอเชียตะวันออกเฉียงใต้ โดยมีการให้คำปรึกษาและช่วยวิจัยการตลาดฟรี ซึ่งเป็นแนวทางที่เหมาะสมกับแต่ละพื้นที่มากขึ้น ช่วยลดความท้าทายเมื่อมีการขยายแบรนด์ไปในต่างประเทศ",
  "pages.home.新": "ใหม่",
  "pages.home.卖家": "ผู้ขาย",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok网红组织建立快速响应系统":
    "iBooming ร่วมมือกับเหล่าผู้นำทีมจำนวนนับไม่ถ้วนในการสร้าง TikTok Influencer Affiliation Matrix ซึ่งเป็นตัวช่วยในการกระจายบนแพลตฟอร์ม TikTok และสร้างระบบตอบสนองที่รวดเร็ว เพื่อมารองรับเหล่าครีเอเตอร์มากกว่า 800,000 คน บนTikTok",
  "pages.home.其他类型": "ประเภทอื่นๆ",
  "pages.home.首页": "หน้าแรก",
  "pages.home.About iBooming": "เกี่ยวกับiBooming",
  "pages.home.iBooming is a technology company specializing in developing artificial intelligence solutions for the digital content industry. Through AI, iBooming enables influencers and brands to optimize content strategies, expand reach, and increase monetization. With a commitment to innovation, iBooming aims to be a leader in AI-powered industry change, enabling influencers and brands to increase product visibility and achieve success in the global marketplace. For more information about iBooming, visit www.iboomingglobal.com":
    "",
  "pages.home.TikTok username": "",
  "pages.home.更多高佣商品与带货补贴":
    "ผลิตภัณฑ์ค่าคอมมิชชั่นสูงเพิ่มเติมและเงินอุดหนุนจากการขายผลิตภัณฑ์ Affiliate",
  "pages.home.粉丝数": "ยอดผู้ติดตาม",
  "pages.home.拍摄一个视频的价格": "ราคาต่อการถ่าย1วิดีโอ",
  "pages.home.其他账号": "บัญชีอื่นๆ",
  "pages.home.提交成功": "ส่งสำเร็จ",
  "pages.home.印尼": "อินโดนีเซีย",
  "pages.home.中国": "จีน",
  "pages.home.1,000,000 以上": "1,000,000ขึ้นไป",
  "pages.home.500,000 至 1,000,000": "500,000 ถึง 1,000,000",
  "pages.home.100,000 至 500,000": "100,000 ถึง 500,000",
  "pages.home.50,000 至 100,000": "50,000 ถึง 100,000",
  "pages.home.10,000 至 50,000": "10,000 ถึง 50,000",
  "pages.home.10,000 以下": "10,000 ลงไป",
  "pages.home.在线品牌商": "แบรนด์ออนไลน์",
  "pages.home.我是网红": "ฉันคือครีเอเตอร์",
  "pages.home.我们的业务范围": "ขอบข่ายธุรกิจของเรา",
  "pages.home.电子邮箱": "อีเมล์",
  "pages.home.关于我们": "เกี่ยวกับเรา",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过100万TikTok达人组织建立快速响应系统":
    "iBooming ร่วมมือกับเหล่าผู้นำทีมจำนวนนับไม่ถ้วนในการสร้าง TikTok Influencer Affiliation Matrix ซึ่งเป็นตัวช่วยในการกระจายบนแพลตฟอร์ม TikTok และสร้างระบบตอบสนองที่รวดเร็ว เพื่อมารองรับเหล่าครีเอเตอร์มากกว่า 1,000,000 คน บนTikTok",
  "pages.home.抢占海外达人分销的红利":
    "คว้าเงินปันผลจากการกระจายความสามารถในต่างประเทศ",
  "pages.home.联系号码": "เบอร์ติดต่อ",
  "pages.home.微信": "WeChat",
  "pages.home.立即加入网红社群":
    "เข้าร่วมคอมมูนิตี้ของเหล่าครีเอเตอร์ตอนนี้เลย",
  "pages.home.合作媒体": "สื่อพันธมิตร",
  "pages.home.网红加入": "ครีเอเตอร์เข้าร่วม",
  "pages.home.合作团长": "ผู้นำแห่งการร่วมมือ",
  "pages.home.扫码下载APP": "สแกนโค้ดเพื่อดาวน์โหลดแอป",
  "pages.home.复制成功": "คัดลอกสำเร็จ",
  "pages.home.护肤": "สกินแคร์",
  "pages.home.彩妆": "เครื่องสำอางค์",
  "pages.home.立即下载": "ดาวน์โหลดเลย",
  "pages.home.下载iBooming APP, 参与活动领福利":
    "ดาวน์โหลดแอป iBooming เข้าร่วมแคมเปญและรับผลประโยชน์มากมาย",
  "pages.home.平均日销量": "ยอดขายเฉลี่ยต่อวัน",
  "pages.home.1亿": "ล้าน",
  "pages.home.200亿": "ล้าน",
  "pages.home.立即匹配": "จับคู่ตอนนี้",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton เป็นแบรนด์ความงามที่มุ่งพัฒนาด้านสบู่ไวท์เทนนิ่ง",
  "pages.home.该品牌过去依赖当地的经销商来销售产品，像是传统电商平台Tokopedia，Shopee或Lazada等其他渠道进行销售。":
    "แบรนด์เคยพึ่งพาการขายบนแพลตฟอร์มท้องถิ่น เช่น Tokopedia Shopee หรือ Lazada",
  "pages.home.然而，由于推广的数量以及力度不足，销售一直停滞不前。":
    "อย่างไรก็ตาม เนื่องด้วยโปรโมชันที่ไม่เพียงพอ ทำให้ยอดการขายยังคงซบเซา",
  "pages.home.2022年10月，Kojic Plankton与iBooming合作，首次利用KOL（关键意见领袖）和KOC（关键意见消费者）在TikTok上推广其产品。":
    "ในเดือนตุลาคม ปี 2022 Kojic Plankton ได้ร่วมเป็นพาร์ทเนอร์กับ iBooming เพื่อใช้ KOL (Key Opinion Leaders) และ KOC (Key Opinion Consumers) เป็นครั้งแรกในการโปรโมตสินค้าของแบรนด์บน Tiktok",
  "pages.home.iBooming的团队花费了两个月的时间寻找适合该产品的创作者，并测试了各种内容、定价策略和推广方式等等，以提高产品销售。":
    "ทางiBooming ใช้เวลาสองเดือนในการค้นหาครีเอเตอร์ที่เหมาะสมกับผลิตภัณฑ์ ทดลองทำหลายคอนเทนต์ กลยุทธ์ด้านการกำหนดราคา รวมถึงวิธีการส่งเสริมการขายเพื่อกระตุ้นยอดขายผลิตภัณฑ์",
  "pages.home.到12月底，iBooming的团队成功将该产品推动到东南亚TikTok销售排行榜的前列，在仅仅1天内就获得了超过70,000个订单，对比以往的销量提高了百分比的成绩。":
    "ภายในสิ้นเดือนธันวาคม ทีมiBoomingประสบความสำเร็จในการโปรโมตผลิตภัณฑ์ให้ขึ้นไปสู่ระดับสูงสุดของอันดับยอดขายTiktokใน เอเชียตะวันออกเฉียงใต้ โดยได้รับคำสั่งซื้อมากกว่า 70,000 รายการในวันเดียว ซึ่งเพิ่มยอดขายอย่างน่าทึ่ง เมื่อเทียบกับยอดขายก่อนหน้านี้",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo เป็นแบรนด์ระดับสากลที่มุ่งไปที่การพัฒนาด้านความงามและของใช้ส่วนตัว",
  "pages.home.在2022年初，他们推出了MeToo漱口水，并与iBooming合作。":
    "ในช่วงต้นปี 2022 ทางแบรนด์ได้เปิดตัวน้ำยาบ้วนปากMeToo ซึ่งร่วมมือกับทางiBooming",
  "pages.home.在两个月的时间里，iBooming精准地将该产品分配给与MeToo品牌价值观相符的内容创作者":
    "ตลอดระยะเวลา 2 เดือน iBooming ได้มอบหมายผลิตภัณฑ์ให้ครีเอเตอร์สร้างคอนเทนต์ที่สอดคล้องกับคุณค่าของแบรนด์",
  "pages.home.除了在TikTok上的MeToo Smile官方活动，品牌商也斥巨资邀请了一位当地备受瞩目的当红明星作为该产品的品牌大使，以及各大新闻媒体的报道支持，促成了MeToo在TikTok上迅速走红。":
    "นอกเหนือจากอีเวนท์ MeToo Smile บน Tiktok แบรนด์ยังลงทุนใช้เวลาในการเชิญดาราดังท้องถิ่นที่มาเป็นแบรนด์แอมบาสเดอร์ของผลิตภัณฑ์ ตลอดจนการรายงานและการสนับสนุนของสื่อข่าวสำคัญๆ ซึ่งมีส่วนช่วยโปรโมต MeToo บน TikTok จนกลายเป็นกระแสไวรัล",
  "pages.home.在Metoo漱口水上市仅三天内，根据数据显示，每日有超过上千名的网红通过发布短视频或直播带货的方式销售产品，快速推动了商品销量，平均日销量超过了40,000瓶。":
    "ภายในเวลาเพียงสามวันหลังจากเปิดตัวน้ำยาบ้วนปาก MeToo ข้อมูลแสดงให้เห็นว่าครีเอเตอร์มากกว่าหนึ่งพันคนขายผลิตภัณฑ์ทุกวันโดยการโพสต์วิดีโอสั้น ๆ หรือไลฟ์สด ซึ่งช่วยเพิ่มยอดขายผลิตภัณฑ์ได้อย่างรวดเร็ว โดยมียอดขายเฉลี่ยเกิน 40,000 ขวดต่อวัน",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为 FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream。":
    'Feali เป็นแบรนด์ระดับสากลที่เชี่ยวชาญด้านการพัฒนาและผลิตเครื่องสำอางค์ ในเดือนกุมภาพันธ์ 2023 Feali ได้เปิดตัวผลิตภัณฑ์ดูแลผิวที่เป็นเอกลักษณ์ชื่อว่า "FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream"',
  "pages.home.该产品使用了市场上罕见的天然成分，当涂抹于皮肤时会产生白色气泡，使其在视觉上也非常吸引人。":
    "ผลิตภัณฑ์ประกอบด้วยส่วนผสมทางธรรมชาติที่หายาก สร้างฟองสีขาวที่เมื่อทาลงบนผิว ทำให้ผิวดูน่าดึงดูด",
  "pages.home.iBooming用了两周的时间，根据后台数据智能匹配了与该产品标签相符的带货网红，并快速积极地完成了产品的市场试错，为接下来的大型推广扎稳了根基。，并有效地推广了FEALI面霜。":
    "ทาง iBooming ใช้เวลาสองเดือนในการจับคู่ครีเอเตอร์ผู้ที่เหมาะสมกับฉลากสินค้า ดำเนินการทดลองตลาด และสร้างรากฐานแบรนด์ที่มั่นคงซึ่งจะช่วยส่งเสริมครีม FEALI ได้อย่างมีประสิทธิภาพ",
  // "pages.home.MORE": "สร้างเ",
  // "pages.home.CREATE": "พิ่ม",
  // "pages.home.MORE2": "ได้รับเ",
  // "pages.home.BENEFIT": "พิ่ม",
  "pages.home.MORE": "CREATE ",
  "pages.home.CREATE": "MORE",
  "pages.home.MORE2": "EARN ",
  "pages.home.BENEFIT": "MORE",
  "pages.home.业务所在国家": "ประเทศของธุรกิจ",
  "pages.home.所在国家": "ประเทศ",
  "pages.home.其他账号（选填）": "บัญชีอื่น ๆ (ไม่บังคับ)",
  "pages.home.仅仅一个月时间，Feali就成功提升了销量并取得了令人瞩目的成果，充分诠释了有针对性的营销策略和制作创意内容所带来的成效是无可比拟的。":
    "ในเวลาเพียงหนึ่งเดือน Feali ประสบความสำเร็จในการเพิ่มยอดขายและได้รับผลลัพธ์ที่น่าประทับใจ ซึ่งแสดงให้เห็นว่ากลยุทธ์การกำหนดเป้าหมายทางการตลาดและการผลิตเนื้อหาเชิงสร้างสรรค์ทำให้เกิดผลลัพธ์ที่ไม่มีใครเทียบได้",
  "pages.home.企业微信": "WeCom",
  "pages.home.前往TikTok TAP报名": "ไปที่ Tiktok TAP เพื่อลงทะเบียน",

  "pages.mcn.获得": "ได้รับ",
  "pages.mcn.更多": "มากกว่า",
  "pages.mcn.好处": "ข้อดี",
  "pages.mcn.加入正确MCN，你可获得":
    "เข้าร่วมMCNที่เหมาะสม เพื่อรับสิทธิประโยชน์สุดพิเศษ",
  "pages.mcn.官方流量扶持": "สนับสนุนยอดเข้าชมอย่างเป็นทางการ",
  "pages.mcn.官方每个月的佣金补贴":
    "เงินสนับสนุนค่าคอมมิชชันรายเดือนอย่างเป็นทางการ",
  "pages.mcn.享有直播特价福利": "เพลิดเพลินกับสิทธิประโยชน์ในการทำไลฟ์สด",
  "pages.mcn.短视频/直播投流补贴": "เงินสนับสนุนการยิงAds วิดีโอสั้น/ไลฟ์สด",
  // TODO:翻译给了要改
  "pages.mcn.立即": "เลือกเลย ",
  "pages.mcn.选择": " ",
  "pages.mcn.获得官方政策": "เพื่อเข้าถึงนโยบายอย่างเป็นทางการ",
  "pages.mcn.我们": "สิ่งที่เรามอบให้:",
  "pages.mcn.零抽佣，品牌佣金100%给创作者":
    "ค่าคอมมิชชั่นทั้งหมดจะถูกจ่ายให้ครีเอเตอร์ โดยที่ทางเราไม่มีการหักเปอร์เซ็นต์ค่าคอมมิชชั่นแต่อย่างใด",
  "pages.mcn.无限制，自由选择带货": "เลือกใช้ลิงก์สินค้าได้อย่างอิสระ",
  "pages.mcn.专业导师培训TikTok最新功能":
    "ฟีเจอร์ใหม่ของTikTok การอบรมให้คำปรึกษาจากผู้เชี่ยวชาญมืออาชีพ",
  "pages.mcn.官方限时加入": "การลงทะเบียนอย่างเป็นทางการในเวลาที่จำกัด",
  "pages.mcn.扫码打开链接填写信息": "สแกน QR Code เพื่อเปิดลิงก์และกรอกข้อมูล",
  "pages.mcn.审核通过结果请查收您的TikTok站内信":
    "ตรวจสอบผ่านแล้ว โปรดตรวจสอบกล่องข้อความบน TikTok ของคุณ",
  "pages.mcn.步骤1": "ขั้นตอนที่ 1",
  "pages.mcn.填写信息注册": "กรอกข้อมูลเพื่อลงทะเบียน",
  "pages.mcn.填写信息申请加入": "กรอกข้อมูลเพื่อลงทะเบียนเข้าร่วม",
  "pages.mcn.TikTok 账号": "บัญชี TikTok",
  "pages.mcn.粉丝数": "จำนวนผู้ติดตาม",
  "pages.mcn.选择类目": "เลือกประเภท",
  "pages.mcn.拍摄一个视频的价格": "ราคาการถ่ายวิดีโอต่อคลิป",
  "pages.mcn.立即申请": "ลงมะเบียนตอนนี้",
  "pages.mcn.步骤2": "ขั้นตอนที่ 2",
  "pages.mcn.查看审核结果": "ตรวจสอบผลการลงทะเบียน",
  "pages.mcn.打开TikTok站内信查看审核结果":
    "เปิดกล่องข้อความบนTikTok เพื่อดูผลการตรวจสอบ",
  "pages.mcn.如果通过，您会收到来自": "หากตรวจสอบผ่าน จะได้รับข้อเสนอจาก ",
  "pages.mcn.的邀约": "  ",
  "pages.mcn.步骤3": "ขั้นตอนที่ 3",
  "pages.mcn.点击同意": "กดเพื่อยอมรับ",
  "pages.mcn.点击同意，恭喜您加入成功":
    "ยินดีด้วย หลังจากกดยอมรับจะถือว่าคุณเข้าร่วมสำเร็จแล้ว",
  "pages.mcn.提交申请成功": "ลงทะเบียนเรียบร้อยแล้ว",
  "pages.mcn.一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信":
    "เมื่อได้รับการอนุมัติแล้ว คุณสามารถตรวจสอบคำเชิญที่ทางเราส่งให้ได้ทางกล่องจดหมายบน TikTok",
  "pages.mcn.好的": "โอเค",
  "pages.mcn.提交失败，请再尝试一次": "การส่งล้มเหลว โปรดลองอีกครั้ง",
  "pages.mcn.已申请": "ลงทะเบียนแล้ว",
  "pages.mcn.填写信息加入，审核通过结果请查收您的TikTok站内信":
    "กรอกข้อมูลเพื่อลงทะเบียนเข้าร่วม จากนั้นตรวจสอบผลได้ทางกล่องจดหมายบน TikTok ",
  "pages.mcn.填写信息申请": "กรอกข้อมูลเพื่อลงทะเบียน",
  "pages.home.AI Matching": "AI Matching",
  "pages.home.Matrix Affiliate": "Matrix Affiliate",
  "pages.home.Keep Engaged": "Keep Engaged",
  "pages.home.Influencer Mega Campaign": "Influencer Mega Campaign",
  "pages.home.其他": "อื่น ๆ ",

  "pages.商家入驻.BOOMING": "BOOMING",
  "pages.商家入驻.YOUR BRAND WITH": "ผลักดันแบรนด์ของคุณ",
  "pages.商家入驻.MOUTH-TO-MOUTH": "ด้วยการตลาดในรูปแบบ",
  "pages.商家入驻.MARKETING": "ปากต่อปาก",
  "pages.商家入驻.iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara": `iBooming ใช้ระบบปัญญาประดิษฐ์ (AI) ที่มีความเสถียรและแม่นยำ
  ในการเชื่อมโยงแบรนด์เข้ากับคอมมูนิตี้ของครีเอเตอร์ขนาดใหญ่
  ทั่วประเทศไทย รวมไปถึงในโซนเอเชียตะวันออกเฉียงใต้`,
  "pages.商家入驻.Influencer": "ครีเอเตอร์",
  "pages.商家入驻.Product": "แบรนด์",
  "pages.商家入驻.Brand": "ผลิตภัณฑ์",
  "pages.商家入驻.Matchmaking Brand dan Influencer dengan AI Tools":
    "การจับคู่แบรนด์กับครีเอเตอร์ด้วยเครื่องมือ AI",
  "pages.商家入驻.Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia": `คอมมูนิตี้ของครีเอเตอร์ที่กว้างขวาง และเป็นเครือข่าย
  ที่ครอบคลุมทั้งในประเทศไทยและเอเชียตะวันออกเฉียงใต้`,
  "pages.商家入驻.Minimalisir Biaya Operasional dan Sumber Daya Manusia":
    "ลดต้นทุนในการดำเนินงานและลดทรัพยากรบุคคล",
  "pages.商家入驻.Maksimalkan Trafik Persentase Penjualan Produk":
    "เพิ่มเปอร์เซ็นต์ยอดขายให้มีมากขึ้น",
  "pages.商家入驻.One Stop Solution Services dalam Satu Platform":
    "บริการแบบครบวงจรบนแพลตฟอร์มเดียว",
  "pages.商家入驻.Mengapa MOUTH-TO-MOUTH MARKETING di iBooming": `ทำไมต้องเลือกการตลาด
  แบบปากต่อปากของ iBooming`,
  "pages.商家入驻.Peran Nano-Influencer Marketing":
    "บทบาทการตลาดแบบนาโนครีเอเตอร์",
  "pages.商家入驻.Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan": `ในอนาคตจำเป็นต้องต้องอาศัยนาโนครีเอเตอร์
  ในการส่งเสริมการขายและเพิ่มยอดขาย
  ให้กับผลิตภัณฑ์เป็นอย่างมาก`,
  "pages.商家入驻.More Trusted": "ได้รับความไว้วางใจมากขึ้น",
  "pages.商家入驻.Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth": `นาโนครีเอเตอร์มีการสร้างสร้างความสัมพันธ์
  ที่ใกล้ชิดกับผู้ติดตามมากขึ้น จึงสามารถสร้าง
  ความไว้วางใจในการทำการตลาดแบบปากต่อปาก`,
  "pages.商家入驻.More Profit": "ทำกำไรได้มากขึ้น",
  "pages.商家入驻.Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat": `แบรนด์ที่ได้รับการแนะนำโดยนาโน
  ครีเอเตอร์จะมียอดขายเพิ่มขึ้นแบบทวีคูณ`,
  "pages.商家入驻.More Massive": "คอมมูนิตี้ที่ใหญ่ขึ้น",
  "pages.商家入驻.Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer": `นาโนครีเอเตอร์ที่ไม่จำกัดพร้อมการเข้าถึง
  ที่กว้างขวางผ่านคอมมูนิตี้ของครีเอเตอร์`,
  "pages.商家入驻.Hasil MOUTH-TO-MOUTH MARKETING di iBooming":
    "ผลลัพธ์การตลาดแบบปากต่อปากที่ iBooming",
  "pages.商家入驻.SEGERA DAFTARKAN BRAND ANDA":
    "ลงทะเบียนแบรนด์ของคุณตอนนี้เลย",
  "pages.商家入驻.INFLUENCER": "ครีเอเตอร์",
  "pages.商家入驻.BRAND": "แบรนด์",
  "pages.商家入驻.Jordi": "Jordi",
  "pages.商家入驻.Willie Salim": "Willie Salim",
  "pages.商家入驻.Vilmei": "Vilmei",
  "pages.商家入驻.Awkarin": "Awkarin",
  "pages.商家入驻.Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus": `การตลาดแบบปากต่อปากบน iBooming 
  สามารถทำได้ผ่านครีเอเตอร์นับพันราย
  ที่ช่วยดึงดูดผู้ชมหลายล้านคนและช่วย
  สร้างรายได้นับพันล้าน`,
  "pages.商家入驻.Top Product Sales in Southeast Asia":
    "ผลิตภัณฑ์ยอดขายสูงในเอเชียตะวันออกเฉียงใต้",
  "pages.商家入驻.Top Product Trending Skincare": "ผลิตภัณฑ์สกินแคร์ยอดนิยม",
  "pages.商家入驻.Top Product Body Wash": "ผลิตภัณฑ์ครีมอาบน้ำยอดนิยม",
  "pages.商家入驻.Top Product Best Selling Skincare":
    "ผลิตภัณฑ์สกินแคร์ยอดขายสูง",
  "pages.商家入驻.100jt+": "100M+",
  "pages.商家入驻.Total Impresi": "จำนวนเข้าชมทั้งหมด",
  "pages.商家入驻.1,3M+": "1.3M+",
  "pages.商家入驻.Total Penjualan": "ยอดขายทั้งหมด",
  "pages.商家入驻.TikTok Southeast Asia Hot Product List":
    "รายการสินค้ายอดนิยมในเอเชียตะวันออกเฉียงใต้บน TikTok",
  "pages.商家入驻.400jt+": "400M+",
  "pages.商家入驻.40.000+": "40K+",
  "pages.商家入驻.Penjualan Harian Rata-Rata": "ยอดขายเฉลี่ยต่อวัน",
  "pages.商家入驻.No. 1": "อับดับ 1 ",
  "pages.商家入驻.in Skincare Package": "ของแพ็คเกจสกินแคร์",
  "pages.商家入驻.Penjualan Bulanan Rata-Rata": "ยอดขายเฉลี่ยต่อเดือน",
  "pages.商家入驻.100.000+": "100K+",
  "pages.商家入驻.Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu": `กรอกข้อมูลเพื่อเข้าร่วม หลังจากนั้น
ทีมงานของ iBooming จะตรวจสอบ
และติดต่อกลับหาคุณโดยทันที`,
  "pages.商家入驻.Nama Perusahaan": "ชื่อบริษัท",
  "pages.商家入驻.申请入驻": "การลงทะเบียนร้านค้า",
  "pages.商家入驻.Jenis Usaha": "ประเภทธุรกิจ",
  "pages.商家入驻.Kategori Produk": "หมวดหมู่ผลิตภัณฑ์",
  "pages.商家入驻.Nomor Kontak Aktif": "หมายเลขติดต่อ",

  "pages.influencer.RATE CARD": "RATE CARD",
  "pages.influencer.Updated": "อัปเดตแล้ว",
  "pages.influencer.Category": "หมวดหมู่",
  "pages.influencer.Followers": "ยอดผู้ติดตาม",
  "pages.influencer.Accounts Reached": "Accounts Reached",
  "pages.influencer.Accounts Engaged": "Accounts Engaged",
  "pages.influencer.Gender Audience": "เพศของผู้ติดตาม",
  "pages.influencer.Average Views": "ยอดวิวเฉลี่ย",
  "pages.influencer.GMV": "GMV",
  "pages.influencer.Subscribers": "Subscribers",
  "pages.influencer.The above are all data for the past 30 days":
    "ด้านบนคือข้อมูลทั้งหมดในช่วง 30 วัน ที่ผ่านมา",
  "pages.influencer.Short Video TikTok": "Short Video TikTok",
  "pages.influencer.Live Stream TikTok": "Live Stream TikTok",
  "pages.influencer.Reels Instagram": "Reels Instagram",
  "pages.influencer.Feed Instagram": "Feed Instagram",
  "pages.influencer.Story Instagram": "Story Instagram",
  "pages.influencer.Short Video Youtube": "Shorts Video YouTube",
  "pages.influencer.Video Youtube": "Video YouTube",
  "pages.influencer.Per Video or Per Live Stream Session":
    "ต่อวิดีโอหรือต่อเซสชั่นไลฟ์สด",
  "pages.influencer.Terms & Condition": "ข้อกำหนดและเงื่อนไข",
  "pages.influencer.简介": "บทนำ",
  "pages.influencer.已认证": "รับรองแล้ว",
  "pages.influencer.未认证": "ยังไม่ได้รับรอง",

  'pages.influencer.This Rate Card Generator feature is provided by iBooming Official (referred to as "we", "us", or "our") and by using our Rate Card Generator feature, you agree to provide accurate and up-to-date information and grant us a license to use it for generating the rate card. We make no warranties regarding the accuracy of the generated rate card and shall not be liable for any damages. If you have any questions, please contact us.':
    '"Rate Card Generator" เป็นฟีเจอร์ที่ให้บริการโดย iBooming Official (เรียกว่า "เรา") การใช้ "Rate Card Generator" หมายถึงคุณตกลงที่จะให้ข้อมูลที่ถูกต้องและอัปเดตเป็นข้อมูลล่าสุดเสมอ นอกจากนี้ยังยินยอมให้สิทธิ์เราในการสร้างใบเสนอราคา ซึ่งทางเราไม่รับประกันความถูกต้องของ Rate Card ที่สร้างขึ้น และไม่รับผิดชอบต่อความเสียหายใดๆ หากคุณมีคำถาม โปรดติดต่อเรา',
};
