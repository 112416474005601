import React from "react";
import new1 from "../../assest/new1-1.png";
import new12 from "../../assest/new1-2.png";
import { FormattedMessage } from "react-intl";
export default function MeToo() {
  const locale = localStorage.getItem("locale") || "en-us";
  return (
    <div>
      <img className="img1" src={new1} alt="" />
      <p>
        <FormattedMessage
          id="pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。"
          defaultMessage="MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.在2022年初，他们推出了MeToo漱口水，并与iBooming合作。"
          defaultMessage="在2022年初，他们推出了MeToo漱口水，并与iBooming合作。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.在两个月的时间里，iBooming精准地将该产品分配给与MeToo品牌价值观相符的内容创作者"
          defaultMessage="在两个月的时间里，iBooming精准地将该产品分配给与MeToo品牌价值观相符的内容创作者。"
        />
      </p>
      <img className="img2" src={new12} alt="" />
      <p>
        <FormattedMessage
          id="pages.home.除了在TikTok上的MeToo Smile官方活动，品牌商也斥巨资邀请了一位当地备受瞩目的当红明星作为该产品的品牌大使，以及各大新闻媒体的报道支持，促成了MeToo在TikTok上迅速走红。"
          defaultMessage="除了在TikTok上的MeToo Smile官方活动，品牌商也斥巨资邀请了一位当地备受瞩目的当红明星作为该产品的品牌大使，以及各大新闻媒体的报道支持，促成了MeToo在TikTok上迅速走红。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.在Metoo漱口水上市仅三天内，根据数据显示，每日有超过上千名的网红通过发布短视频或直播带货的方式销售产品，快速推动了商品销量，平均日销量超过了40,000瓶。"
          defaultMessage="在Metoo漱口水上市仅三天内，根据数据显示，每日有超过上千名的网红通过发布短视频或直播带货的方式销售产品，快速推动了商品销量，平均日销量超过了40,000瓶。"
        />
      </p>
    </div>
  );
}
