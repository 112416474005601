/**
 * 商家中心
 */
import React, { useEffect, useState } from "react";
import { WOW } from "wowjs";
import "./index.scss";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import AppClassicCases from "../Home/ClassicCases";
import APPServiceList from "./ServiceList";
import APPJoinUS from "./JoinUS";
import arrow from "../../assest/arrow2.png";
import arrow1 from "../../assest/arrow3.png";
import arrow4 from "../../assest/arrow7.png";
import arrow5 from "../../assest/arrow8.png";
import arrow6 from "../../assest/arrow9.png";

export default function APPBusiness() {
  const locale = localStorage.getItem("locale") || "en-us";
  const [search] = useSearchParams();
  // type 1是头号卖家，2是新卖家，3是其他商家
  const type = search.get("type") || "1";

  useEffect(() => {
    const wow = new (window.WOW || WOW)({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  }, []);
  return (
    <div className="app-business">
      {/* banner */}
      <div className={`streamer streamer${type}`}>
        <div className="streamer-content">
          <h3 className="wow fadeInUp" data-wow-duration="1s">
            {type == 1 && (
              <>
                <FormattedMessage
                  id="pages.home.您好,"
                  defaultMessage="您好,"
                />
                <span>
                  <FormattedMessage
                    id="pages.home.商家"
                    defaultMessage="商家"
                  />
                </span>
              </>
            )}
            {type == 3 &&
              (locale === "id-id" ? (
                <>
                  <span>Jenis Seller </span>
                  Lainnya
                </>
              ) : (
                <>
                  <span>
                    <FormattedMessage
                      id="pages.home.其他类型"
                      defaultMessage="其他类型"
                    />
                  </span>
                  <FormattedMessage
                    id="pages.home.商家"
                    defaultMessage="商家"
                  />
                </>
              ))}
            {type == 2 && (
              <>
                <span>
                  <FormattedMessage id="pages.home.新" defaultMessage="新" />
                </span>
                <FormattedMessage id="pages.home.卖家" defaultMessage="卖家" />
              </>
            )}
          </h3>
          <div
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <p>
              <FormattedMessage
                id="pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok达人组织建立快速响应系统"
                defaultMessage="iBooming与无数团长构建的TikTok网红分销矩阵；与超过80万TikTok网红组织建立快速响应系统"
              />
            </p>
            {type != 3 && (
              <a className="btn" href="#join">
                <FormattedMessage
                  id="pages.home.立即报名"
                  defaultMessage="立即报名"
                />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="app-warp-content">
        {/* 新卖家里面的内容 */}
        {type == 2 && (
          <div className="step-warp">
            <div className="step">
              <a className="btn">
                <FormattedMessage
                  id="pages.home.#步骤1"
                  defaultMessage="#步骤1"
                />
              </a>
              <p style={{ padding: 0 }}>
                <FormattedMessage
                  id="pages.home.注册填写您的品牌"
                  defaultMessage="注册填写您的品牌"
                />
              </p>
              <img src={arrow4} alt="" />
            </div>
            <div className="step step2">
              <a className="btn">
                <FormattedMessage
                  id="pages.home.#步骤2"
                  defaultMessage="#步骤2"
                />
              </a>
              <p>
                <FormattedMessage
                  id="pages.home.AI Booms 工具扫描您的店铺信息"
                  defaultMessage="AI Booms 工具扫描您的店铺信息"
                />
              </p>
              <img src={arrow5} alt="" />
            </div>
            <div
              className="step step3"
              style={
                locale == "en-us" || locale == "vn-vn"
                  ? {
                      padding: "0.32rem 0.2rem",
                      height: locale == "vn-vn" ? "2.65rem" : "2.18rem",
                    }
                  : {}
              }
            >
              <a className="btn">
                <FormattedMessage
                  id="pages.home.#步骤3"
                  defaultMessage="#步骤3"
                />
              </a>
              <p>
                <FormattedMessage
                  id="pages.home.AI Booms 为您提供符合您企业的最佳推广方案"
                  defaultMessage="AI Booms 为您提供符合您企业的最佳推广方案"
                />
              </p>
              <img src={arrow6} alt="" />
            </div>
            <div className="step">
              <a className="btn">
                <FormattedMessage
                  id="pages.home.#步骤4"
                  defaultMessage="#步骤4"
                />
              </a>
              <p>
                <FormattedMessage
                  id="pages.home.享受您的品牌流量"
                  defaultMessage="享受您的品牌流量"
                />
              </p>
            </div>
          </div>
        )}
        {/* 商家报名 */}
        <div className="application-list">
          {type == 1 && (
            <h3>
              <span style={{ marginBottom: "0.16rem" }}>
                <FormattedMessage
                  id="pages.home.立即匹配"
                  defaultMessage="立即匹配"
                />
              </span>
              <p>
                <FormattedMessage
                  id="pages.home.80万+ 带货网红"
                  defaultMessage="80万+ 带货网红"
                />
              </p>
            </h3>
          )}
          {type == 3 && (
            <h3>
              <p style={{ marginBottom: "0.16rem" }}>
                <FormattedMessage
                  id="pages.home.八十万网红池"
                  defaultMessage="八十万网红池"
                />
              </p>
              <span>
                <FormattedMessage
                  id="pages.home.各类目网红满足您的商业需求"
                  defaultMessage="各类目网红满足您的商业需求 "
                />
              </span>
            </h3>
          )}
          {/* other-merchants */}
          <div
            className={`${type == 1 ? "scroll-warp" : ""} ${
              locale.includes("id") ? "id-merchants" : ""
            } ${locale.includes("en") ? "en-merchants" : ""}`}
          >
            <div
              className={`${type == 1 ? "merchant-list" : "other-merchants"}`}
            >
              {/* tiktok头部卖家 */}
              <a
                className="list"
                href={type == 1 ? "#join" : "/business?type=1"}
              >
                <p>
                  <FormattedMessage
                    id="pages.home.我是TikTok"
                    defaultMessage="我是TikTok"
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="pages.home.头部卖家"
                    defaultMessage="头部卖家"
                  />
                </p>
                <div className="more">
                  <FormattedMessage
                    id="pages.home.立即报名"
                    defaultMessage="立即报名"
                  />
                  <img className="img2" src={arrow1} alt="" />
                </div>
              </a>
              {/* 新卖家 */}
              {type != 2 && (
                <a className="list" href="/business?type=2">
                  <p>
                    <FormattedMessage
                      id="pages.home.我是TikTok"
                      defaultMessage="我是TikTok"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="pages.home.新卖家"
                      defaultMessage="新卖家"
                    />
                  </p>
                  <div className="more">
                    <FormattedMessage
                      id="pages.home.立即报名"
                      defaultMessage="立即报名"
                    />
                    <img className="img2" src={arrow1} alt="" />
                  </div>
                </a>
              )}
              {/* 其他类型商家 */}
              {type != 3 && (
                <a
                  style={
                    locale.includes("vn") && type == 1
                      ? {
                          width: "3.85rem",
                        }
                      : {}
                  }
                  className="list"
                  href="/business?type=3"
                >
                  <p>
                    <FormattedMessage
                      id="pages.home.我是其他"
                      defaultMessage="我是其他"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="pages.home.类型商家"
                      defaultMessage="类型商家"
                    />
                  </p>
                  <div
                    className="more"
                    style={
                      locale.includes("vn") && type == 2
                        ? {
                            marginTop: "0.1rem",
                          }
                        : {}
                    }
                  >
                    <FormattedMessage
                      id="pages.home.立即报名"
                      defaultMessage="立即报名"
                    />
                    <img className="img2" src={arrow1} alt="" />
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
        {/* 经典案例 */}
        <AppClassicCases />
        <APPServiceList type={type} />

        <div id="join"></div>
        <APPJoinUS type={type} />
      </div>
    </div>
  );
}
