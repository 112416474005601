import React, { useEffect, useState } from "react";
import "../../utils/setRem";
import "./index.scss";
import axios from "axios";
import TopTalent from "../Talent/TopTalent";
import { FormattedMessage } from "react-intl";
import logo from "../images/mcn1.png";
import logo2 from "../images/mcnlogo.png";
import banner from "../images/mcn2.png";
import bannerBorder from "../images/mcn3.png";
import mright from "../images/mright.png";
import mleft from "../images/mleft.png";
import mcn4 from "../images/mcn4.png";
import hand from "../images/mcn_hand.png";
import fire from "../images/mcn5.png";
import selected from "../images/mcn6.png";
import arrow from "../images/mcnarrow.png";
import t1 from "../images/mcn7.png";
import t2 from "../images/mcn8.png";
import t3 from "../images/mcn9.png";
import t4 from "../images/mcn10.png";
import JoinUS from "../Business/JoinUS";
import { Drawer, Space } from "antd";
import config from "../../utils/config";
const countryNum = {
  ID: 1,
  TH: 2,
  VN: 3,
  MY: 1,
  PH: 1,
};
export default function JoinMCN() {
  const locale = localStorage.getItem("locale") || "en-us";
  const country = localStorage.getItem("country") || "ID";
  const [info, setInfo] = useState({});
  const [typeIds, setTypeIds] = useState([]);
  const [tikCategoryList, setTikCategoryList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const search = window.location.search;
  const [showTop, setShowTop] = useState(false);
  let token;
  if (search.includes("token=")) {
    token = (search.split("token=")[1] || "").split("&")[0];
  }
  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflowY = "hidden";
    if (token) {
      const url = `${config.BASEURL}/bg/api/user/info`;
      axios
        .get(url, {
          params: {
            lang: "en",
          },
          headers: {
            token,
          },
        })
        .then((res) => {
          const data = res?.data?.data || {};
          setInfo(data);
        })
        .catch((err) => {
          console.log("onError", err);
        });
      getCategory();
      getCategoryOptions();
    } else if (window.ReactNativeWebView) {
      const newData = {
        actionType: 0,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(newData));
    }
    setShowTop(true);
  }, []);
  const getCategory = () => {
    const url = `${config.BASEURL}/bg/api/category/sale/info`;
    axios
      .get(url, {
        params: {},
        headers: {
          token,
        },
      })
      .then((res) => {
        const typeList = res?.data?.data?.goodsTypeList || [];
        if (typeList && typeList.length > 0) {
          setTypeIds(
            typeList.filter((w) => w.parentId === 0).map((item) => item.name)
          );
        }
        // setInfo(data);
      })
      .catch((err) => {
        console.log("onError", err);
      });
  };
  const getCategoryOptions = () => {
    const url = `${config.BASEURL}/bg/api/goods/tree_type_list`;
    axios
      .get(url, {
        params: {
          parentId: 0,
        },
        headers: {
          token,
        },
      })
      .then((res) => {
        const data = res?.data?.data || [];
        setTikCategoryList(data);
      })
      .catch((err) => {
        console.log("onError", err);
      });
  };
  const onClose = () => {
    setShowModal(false);
  };
  return (
    <div
      className={
        document.body.clientWidth > 801 ? "app-mcn num-input-mcn" : "app-mcn"
      }
      style={{
        overflowY: "scroll",
        height: "100vh",
      }}
    >
      <div
        style={{
          padding: "0.2rem 0.48rem 0",
        }}
      >
        {country == "ID" ? (
          <img className="logo" src={logo2} alt="TikTok Affiliate Partner" />
        ) : (
          <img className="logo" src={logo} alt="TikTok Affiliate Partner" />
        )}
      </div>
      <div className="banner">
        <div className="flexB">
          {locale == "id-id" ? (
            <h3>
              <FormattedMessage
                id="pages.mcn.Get More"
                defaultMessage="Get More"
              />
              <br />
              <FormattedMessage
                id="pages.mcn.Traffic"
                defaultMessage="Traffic"
              />
              <br />
              <span>
                <FormattedMessage id="pages.mcn.Boost" defaultMessage="Boost" />
              </span>
            </h3>
          ) : (
            <h3>
              <FormattedMessage
                id="pages.mcn.Get More"
                defaultMessage="Get More"
              />
              <br />
              <span>
                <FormattedMessage
                  id="pages.mcn.Traffic"
                  defaultMessage="Traffic"
                />
              </span>
              <br />
              <FormattedMessage id="pages.mcn.Boost" defaultMessage="Boost" />
            </h3>
          )}
          <img className="img1" src={banner} alt="" />
        </div>
        <img className="img2" src={bannerBorder} alt="" />
      </div>
      {/* 达人 */}
      {showTop && <TopTalent />}
      {/* <TopTalent /> */}
      {/* 立即选择 */}
      <h3
        className={locale == "id-id" ? "mcn-title id-mcn-title" : "mcn-title"}
      >
        <img
          style={{
            marginLeft: 0,
          }}
          src={mleft}
          alt=""
        />
        <FormattedMessage id="pages.mcn.立即" defaultMessage="立即选择" />
        <span>MCN</span>
        {locale != "zh-cn" && (
          <FormattedMessage id="pages.mcn.选择" defaultMessage="选择" />
        )}
        <img
          style={{
            marginRight: 0,
          }}
          src={mright}
          alt=""
        />
      </h3>
      <p className="sub-title">
        <FormattedMessage
          id="pages.mcn.获得官方政策"
          defaultMessage="获得官方政策"
        />
      </p>
      <div className="introduce-mcn">
        <h3>
          <FormattedMessage
            id="pages.mcn.加入正确MCN，你可获得"
            defaultMessage="加入正确MCN，你可获得"
          />
          <span> ?</span>
        </h3>
        <img className="horn" src={mcn4} alt="" />
        <div className="text">
          <Space align="start" size={6}>
            <img className="img1" src={fire} alt="" />
            <p>
              <FormattedMessage
                id="pages.mcn.官方流量扶持"
                defaultMessage="官方流量扶持"
              />
              <img className="img2" src={selected} alt="" />
            </p>
          </Space>
        </div>
        <div className="text">
          <Space align="start" size={6}>
            <img className="img1" src={fire} alt="" />
            {locale == "th-th" ? (
              <p>
                เงินสนับสนุนค่าคอมมิชชั่นรายเดือน
                <br />
                อย่างเป็นทางการ
                <img className="img2" src={selected} alt="" />
              </p>
            ) : (
              <p>
                <FormattedMessage
                  id="pages.mcn.官方每个月的佣金补贴"
                  defaultMessage="官方每个月的佣金补贴"
                />
                <img className="img2" src={selected} alt="" />
              </p>
            )}
          </Space>
        </div>
        <div className="text">
          <Space align="start" size={6}>
            <img className="img1" src={fire} alt="" />
            {locale == "th-th" ? (
              <p>
                เพลิดเพลินกับสิทธิประโยชน์
                <br />
                ในการทำไลฟ์สด
                <img className="img2" src={selected} alt="" />
              </p>
            ) : (
              <p>
                <FormattedMessage
                  id="pages.mcn.享有直播特价福利"
                  defaultMessage="享有直播特价福利"
                />
                <img className="img2" src={selected} alt="" />
              </p>
            )}
          </Space>
        </div>
        <div className="text">
          <Space align="start" size={6}>
            <img className="img1" src={fire} alt="" />
            <p>
              <FormattedMessage
                id="pages.mcn.短视频/直播投流补贴"
                defaultMessage="短视频/直播投流补贴"
              />
              <img className="img2" src={selected} alt="" />
            </p>
          </Space>
        </div>
      </div>
      <img className="arrow-down" src={arrow} alt="" />
      {/* 我们 */}
      <div className="introduce-mcn introduce2">
        <h3>
          <FormattedMessage id="pages.mcn.我们" defaultMessage="我们" />
        </h3>
        <img className="horn" src={mcn4} alt="" />
        {locale == "th-th" ? (
          <>
            <div className="text">
              <Space align="start" size={6}>
                <img className="img1" src={fire} alt="" />
                <p>
                  คอมมิชชั่นที่ไม่จำกัด
                  <br />
                  ค่าคอมมิชชั่นจากสินค้าทั้งหมด
                  <br />
                  100%จะถูกส่งมอบให้ครีเอเตอร์
                </p>
              </Space>
            </div>
            <div className="text">
              <Space align="start" size={6}>
                <img className="img1" src={fire} alt="" />
                <p>
                  ไม่มีข้อจำกัด
                  <br />
                  เลือกขายสินค้า Affiliate ได้อย่างอิสระ
                </p>
              </Space>
            </div>
            <div className="text">
              <Space align="start" size={6}>
                <img className="img1" src={fire} alt="" />
                <p>
                  ฟีเจอร์ใหม่จาก TikTok การอบรม
                  <br />
                  ให้คำปรึกษาจากผู้เชี่ยวชาญมืออาชีพ
                </p>
              </Space>
            </div>
          </>
        ) : (
          <>
            <div className="text">
              <Space align="start" size={6}>
                <img className="img1" src={fire} alt="" />
                <FormattedMessage
                  id="pages.mcn.零抽佣，品牌佣金100%给创作者"
                  defaultMessage="零抽佣，品牌佣金100%给创作者"
                />
              </Space>
            </div>
            <div className="text">
              <Space align="start" size={6}>
                <img className="img1" src={fire} alt="" />
                <FormattedMessage
                  id="pages.mcn.无限制，自由选择带货"
                  defaultMessage="无限制，自由选择带货"
                />
              </Space>
            </div>
            <div className="text">
              <Space align="start" size={6}>
                <img className="img1" src={fire} alt="" />
                <FormattedMessage
                  id="pages.mcn.专业导师培训TikTok最新功能"
                  defaultMessage="专业导师培训TikTok最新功能"
                />
              </Space>
            </div>
          </>
        )}
      </div>
      {/* 官方限时加入 */}
      <h3 className="mcn-title exceed">
        <img src={mleft} alt="" />
        {locale == "th-th" ? (
          <p>
            การลงทะเบียน
            <br />
            อย่างเป็นทางการ
            <br />
            ในเวลาจำกัด
          </p>
        ) : (
          <p>
            <FormattedMessage
              id="pages.mcn.官方限时加入"
              defaultMessage="官方限时加入"
            />
          </p>
        )}
        <img src={mright} alt="" />
      </h3>
      <div className="desc">
        <FormattedMessage
          id="pages.mcn.填写信息加入，审核通过结果请查收您的TikTok站内信"
          defaultMessage="填写信息加入，审核通过结果请查收您的TikTok站内信"
        />
      </div>
      {/* 填写信息 */}
      <div className="info-body">
        <h3>
          <img src={t1} alt="" />
          <p>
            <FormattedMessage
              id="pages.mcn.填写信息申请"
              defaultMessage="填写信息申请"
            />
          </p>
        </h3>
        <div className="clear">
          <div className="big-drop"></div>
          <div className="line">
            <div className="red-line"></div>
          </div>
          <div className="min-drop"></div>
        </div>
        <div className="relative">
          <div className="vertical-line"></div>
          <div className="title-warp">
            <div className="drop">
              <img src={t4} alt="" />
            </div>
            <div className="btn-warp">
              <div className="title-btn">
                <FormattedMessage
                  id="pages.mcn.填写信息申请加入"
                  defaultMessage="填写信息申请加入"
                />
              </div>
            </div>
          </div>
          <div className="info-content">
            <JoinUS
              type="5"
              userInfo={info}
              typeIds={typeIds}
              tikCategory={tikCategoryList}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </div>
        </div>
      </div>
      {/* 查看审核结果 */}
      <div className="info-body">
        <h3>
          <img src={t2} alt="" />
          <p>
            <FormattedMessage
              id="pages.mcn.查看审核结果"
              defaultMessage="查看审核结果"
            />
          </p>
        </h3>
        <div className="clear">
          <div className="big-drop"></div>
          <div className="line">
            <div className="red-line"></div>
          </div>
          <div className="min-drop"></div>
        </div>
        <div className="relative">
          <div className="vertical-line"></div>
          <div className="title-warp">
            <div className="drop">
              <img src={t4} alt="" />
            </div>
            <div className="btn-warp">
              {locale == "th-th" ? (
                <div className="title-btn">
                  เปิดกล่องจดหมายบนTikTok
                  <br />
                  เพื่อดูผลการตรวจสอบ
                </div>
              ) : (
                <div className="title-btn">
                  <FormattedMessage
                    id="pages.mcn.打开TikTok站内信查看审核结果"
                    defaultMessage="打开TikTok站内信查看审核结果"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="info-content">
            {/* TODO:马来站需要图片替换 */}
            {!!country && (
              <img
                className="iphone1"
                src={require(`../images/mcn${countryNum[country] || 1}1.png`)}
                alt=""
              />
            )}
            {!!country && (
              <img
                className="iphone1"
                style={{ marginLeft: "0.08rem" }}
                src={require(`../images/mcn${countryNum[country] || 1}2.png`)}
                alt=""
              />
            )}
            <p className="text">
              <FormattedMessage
                id="pages.mcn.如果通过，您会收到来自"
                defaultMessage="如果通过，您会收到来自"
              />
              {country === "ID" ? (
                <span>Tik Star Media</span>
              ) : (
                <span>FAST MOVING</span>
              )}
              <FormattedMessage id="pages.mcn.的邀约" defaultMessage="的邀约" />
            </p>
          </div>
        </div>
      </div>
      {/* 点击同意 */}
      <div className="info-body">
        <h3>
          <img src={t3} alt="" />
          <p>
            <FormattedMessage
              id="pages.mcn.点击同意"
              defaultMessage="点击同意"
            />
          </p>
        </h3>
        <div className="clear">
          <div className="big-drop"></div>
          <div className="line">
            <div className="red-line"></div>
          </div>
          <div className="min-drop"></div>
        </div>
        <div className="relative">
          <div className="vertical-line"></div>
          <div className="title-warp">
            <div className="drop">
              <img src={t4} alt="" />
            </div>
            <div className="btn-warp">
              {locale == "th-th" ? (
                <div className="title-btn">
                  ยินดีด้วย หลังจากกดยอมรับจะถือว่า
                  <br />
                  คุณเข้าร่วมMCNของเราเรียบร้อยแล้ว
                </div>
              ) : (
                <div className="title-btn">
                  <FormattedMessage
                    id="pages.mcn.点击同意，恭喜您加入成功"
                    defaultMessage="点击同意，恭喜您加入成功"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="info-content">
            <img
              className="iphone2"
              src={require(`../images/mcn${countryNum[country] || 1}3.png`)}
              alt=""
            />
          </div>
        </div>
      </div>
      <p className="footer-text">2023 iBooming All rights reserved</p>
      <Drawer
        open={showModal}
        placement="bottom"
        width="100%"
        onClose={onClose}
        className="app-mcn-drawer"
        // style={{ display: drawerType ? 'block' : 'none' }}
      >
        <img className="img" src={hand} alt="" />
        <h3>
          <FormattedMessage
            id={`pages.mcn.提交申请成功`}
            defaultMessage="提交申请成功"
          />
        </h3>
        <p className="modal-desc">
          <FormattedMessage
            id={`pages.mcn.一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信`}
            defaultMessage="一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信"
          />
        </p>
        <div className="adm-button-primary" onClick={onClose}>
          <FormattedMessage id={`pages.mcn.好的`} defaultMessage="好的" />
        </div>
      </Drawer>
    </div>
  );
}
