import React from "react";
import { FormattedMessage } from "react-intl";
import "./pcIndex.scss";
import { Modal } from "antd";
import close from "../assest/close.png";
import wechat from "../assest/wechat.png";
import whats from "../assest/ws.png";
import wechat1 from "../assest/wechat1.png";
import whats1 from "../assest/whats1.png";
import { Button } from "antd";
const textEnum = {
  1: {
    title: "与我们联系",
  },
  2: {
    title: "恭喜您提交成功",
  },
};
export default function PCModal(props) {
  // 1是投资我们，2商家提交，3是mcn提交成功
  const { type, setType, tapUrl, isApp } = props;
  return !!type ? (
    <>
      {!isApp && (
        <div className="pc-modal">
          <div className="pc-modal-mark"></div>
          <div className="pc-modal-body">
            <div className="modal-close">
              <img
                onClick={() => setType("")}
                width={40}
                height={40}
                src={close}
                alt=""
              />
            </div>
            <h3>
              <FormattedMessage
                id={`pages.home.${textEnum[type].title}`}
                defaultMessage={textEnum[type].title}
              />
            </h3>
            <p className="modal-desc">
              <FormattedMessage
                id={`pages.home.我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作`}
                defaultMessage="我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作"
              />
            </p>
            <div style={{ textAlign: "center" }}>
              <a className="modal-btn" href={tapUrl} target="_blank">
                <FormattedMessage
                  id={`pages.home.前往TikTok TAP报名`}
                  defaultMessage="Join TikTok TAP now"
                />
              </a>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={!!type && isApp}
        title={null}
        footer={null}
        onCancel={() => setType("")}
        wrapClassName={"app-business-modal"}
        width={"6.24rem"}
      >
        {type == 3 ? (
          <>
            <h3>
              <FormattedMessage
                id={`pages.mcn.提交申请成功`}
                defaultMessage="提交申请成功"
              />
            </h3>
            <p className="modal-desc">
              <FormattedMessage
                id={`pages.mcn.一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信`}
                defaultMessage="一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信"
              />
            </p>
          </>
        ) : (
          <>
            <h3>
              <FormattedMessage
                id={`pages.home.${textEnum[type].title}`}
                defaultMessage={textEnum[type].title}
              />
            </h3>
            <p className="modal-desc">
              <FormattedMessage
                id={`pages.home.我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作`}
                defaultMessage="我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作"
              />
            </p>
            <div style={{ textAlign: "center" }}>
              <a className="modal-btn" href={tapUrl} target="_blank">
                <FormattedMessage
                  id={`pages.home.前往TikTok TAP报名`}
                  defaultMessage="Join TikTok TAP now"
                />
              </a>
            </div>
          </>
        )}
      </Modal>
    </>
  ) : null;
}
