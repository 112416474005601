import React from "react";
import new3 from "../../assest/new3-1.png";
import new32 from "../../assest/new3-2.png";
import { FormattedMessage } from "react-intl";
export default function Feail() {
  const locale = localStorage.getItem("locale") || "en-us";
  return (
    <div>
      <img className="img1" src={new3} alt="" />
      <p>
        <FormattedMessage
          id="pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为 FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream。"
          defaultMessage="Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为 FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.该产品使用了市场上罕见的天然成分，当涂抹于皮肤时会产生白色气泡，使其在视觉上也非常吸引人。"
          defaultMessage="该产品使用了市场上罕见的天然成分，当涂抹于皮肤时会产生白色气泡，使其在视觉上也非常吸引人。"
        />
      </p>
      <img className="img2" src={new32} alt="" />
      <p>
        <FormattedMessage
          id="pages.home.iBooming用了两周的时间，根据后台数据智能匹配了与该产品标签相符的带货网红，并快速积极地完成了产品的市场试错，为接下来的大型推广扎稳了根基。，并有效地推广了FEALI面霜。"
          defaultMessage="iBooming用了两周的时间，根据后台数据智能匹配了与该产品标签相符的带货网红，并快速积极地完成了产品的市场试错，为接下来的大型推广扎稳了根基。，并有效地推广了FEALI面霜。"
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.home.仅仅一个月时间，Feali就成功提升了销量并取得了令人瞩目的成果，充分诠释了有针对性的营销策略和制作创意内容所带来的成效是无可比拟的。"
          defaultMessage="仅仅一个月时间，Feali就成功提升了销量并取得了令人瞩目的成果，充分诠释了有针对性的营销策略和制作创意内容所带来的成效是无可比拟的。"
        />
      </p>
    </div>
  );
}
