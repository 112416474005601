/**
 * 认证达人信息
 */
import React, { useEffect, useState } from "react";
import "../../utils/setRem";
import "./index.scss";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import logo from "../images/logo2.png";
import axios from "axios";
import config from "../../utils/config";
import moment from "moment";
import { formatFansNum, formatNumber, getOSSImgUrl } from "../../utils";
import cemail from "../images/cemail.png";
import cphone from "../images/cphone.png";
import ci01 from "../images/ci01.png";
import ci1 from "../images/ci1.png";
import ci2 from "../images/ci2.png";
import ci3 from "../images/ci3.png";
import ci4 from "../images/ci4.png";
import ci5 from "../images/ci5.png";
import ci6 from "../images/ci6.png";
import ci7 from "../images/ci7.png";
import ci8 from "../images/ci8.png";
import ci9 from "../images/ci9.png";
import ci10 from "../images/ci10.png";
import ci11 from "../images/ci11.png";
import ci12 from "../images/ci12.png";
import ci13 from "../images/ci13.png";
import ci14 from "../images/ci14.png";
import ci15 from "../images/ci15.png";
import ci16 from "../images/ci16.png";
import ci17 from "../images/ci17.png";
import ci19 from "../images/ci19.png";
import ci20 from "../images/ci20.png";
import mcn4 from "../images/mcn4.png";

import { Avatar, Space, Divider } from "antd";
const CountryEnum = {
  ID: "印度尼西亚",
  TH: "泰国",
  VN: "越南",
  MY: "马来西亚",
  PH: "菲律宾",
};
export default function APPCertifiedInfluencer() {
  const country = localStorage.getItem("country") || "ID";
  const id = useParams().id;
  const [info, setInfo] = useState({});
  useEffect(() => {
    const url = `${config.BASEURL}/bg/api/ratecard/info`;
    axios
      .get(url, {
        params: {
          userId: id,
        },
      })
      .then((res) => {
        setInfo(res?.data?.data || {});
      })
      .catch((err) => {
        console.log("onError", err);
      });
  }, []);
  useEffect(() => {
    try {
      var userAgent = navigator.userAgent;
      var phoneModel = "";
      var model = "";
      var sss = userAgent.split(";");
      if (/(iPhone|iPad|iPod|iOS|Mac)/i.test(userAgent)) {
        if (sss[1] && sss[1].includes(") AppleWebKit/")) {
          phoneModel = sss[1].split(") AppleWebKit/")[0];
        }
        if (/(iPhone)/i.test(userAgent)) {
          model = "iPhone";
        } else if (/(ipad)/i.test(userAgent)) {
          model = "ipad";
        } else if (/(Mac)/i.test(userAgent)) {
          model = "Mac";
        }
      } else {
        var i = sss.findIndex((item) => item.includes("Build/"));
        if (i > -1) {
          if (sss[i].includes(") AppleWebKit/")) {
            phoneModel = sss[i].split(") AppleWebKit/")[0];
          } else {
            phoneModel = sss[i];
          }
          var sStr = phoneModel.split("Build/");
          model = sStr[0];
          phoneModel = sStr[1];
        }
      }
      const url = `${config.BASEURL}/bg/api/event/save`;
      axios
        .post(url, {
          object: 200,
          type: 2,
          os: "web", //平台
          param2: "h5达人报价单",
          param1: id,
          phoneBrand: model,
          phoneModel: phoneModel,
        })
        .then(() => {});
    } catch (error) {}
  }, []);
  return (
    <div className="app-certified">
      {/* 顶部 */}
      <div className="in-header">
        <div>
          <h3 className="title1">
            <FormattedMessage
              id="pages.influencer.RATE CARD"
              defaultMessage="RATE CARD"
            />
          </h3>
          <p>
            <FormattedMessage
              id="pages.influencer.Updated"
              defaultMessage="Updated"
            />
            {": "}
            {info.updateTime ? info.updateTime.split(" ")[0] : ""}
          </p>
          <p>
            <img src={ci01} alt="" />
            {info.city || ""}
            {/* <FormattedMessage
              id={`pages.home.${CountryEnum[country]}`}
              defaultMessage={CountryEnum[country]}
            /> */}
          </p>
        </div>
        <img className="logo2" src={logo} alt="iBooming" />
      </div>

      {/* 达人信息 */}
      <div className="info-warp">
        <Avatar src={getOSSImgUrl(info.userHead, 200, 90)}></Avatar>
        <div>
          <h2>
            {info.fullname}

            {info.checkStatus === 1 ? (
              <span className="authentication">
                <img src={ci19} alt="" />
                <FormattedMessage
                  id="pages.influencer.已认证"
                  defaultMessage="已认证"
                />
              </span>
            ) : (
              <span className="authentication no">
                <img src={ci20} alt="" />
                <FormattedMessage
                  id="pages.influencer.未认证"
                  defaultMessage="未认证"
                />
              </span>
            )}
          </h2>
          <p className="code-text">
            <FormattedMessage
              id="pages.influencer.Authentication code"
              defaultMessage="Authentication code"
            />
            {": "}
            <span>{info.code}</span>
          </p>
          <h3>
            <FormattedMessage
              id="pages.influencer.Category"
              defaultMessage="Category"
            />{" "}
            {": "}
            {(info.goodsTypeNameList || []).map((item, index) => (
              <>
                {item}
                {info.goodsTypeNameList?.length - 1 === index ? "" : " | "}
              </>
            ))}
          </h3>
          <p>{info.introduce}</p>
          <h3>
            <img className="img1" src={cemail} alt="" />
            {info.email}
          </h3>
          <h3>
            <img className="img1" src={cphone} alt="" />
            {info.whatsApp || info.line || info.zalo || ""}
          </h3>
        </div>
      </div>
      {/* 其他报价 */}
      <div className="quoted-price">
        <img className="img1" src={mcn4} alt="" />
        <div className="head">
          <FormattedMessage
            id="pages.influencer.RATE CARD"
            defaultMessage="RATE CARD"
          />
        </div>
        <div className="quoted-body">
          <div className="list-warp">
            <h3>
              <img src={ci11} alt="" />
              <FormattedMessage
                id="pages.influencer.Short Video TikTok"
                defaultMessage="Short Video TikTok"
              />
              <span className="right">
                {formatNumber(info.tiktokVideoPrice)}
                <span className="star">*</span>
              </span>
            </h3>
            <h3>
              <img
                style={{
                  width: "0.4rem",
                  verticalAlign: "inherit",
                }}
                src={ci12}
                alt=""
              />
              <FormattedMessage
                id="pages.influencer.Live Stream TikTok"
                defaultMessage="Live Stream TikTok"
              />
              <span className="right">
                {formatNumber(info.tiktokLivePrice)}
                <span className="star">*</span>
              </span>
            </h3>
          </div>
          {!!info.instagramUsername && (
            <div className="list-warp">
              <h3>
                <img src={ci13} alt="" />
                <FormattedMessage
                  id="pages.influencer.Reels Instagram"
                  defaultMessage="Reels Instagram"
                />
                <span className="right">
                  {formatNumber(info.instagramReels)}
                  <span className="star">*</span>
                </span>
              </h3>
              <h3>
                <img
                  src={ci14}
                  style={{
                    width: "0.4rem",
                    verticalAlign: "middle",
                    marginLeft: "-0.03rem",
                  }}
                  alt=""
                />
                <FormattedMessage
                  id="pages.influencer.Feed Instagram"
                  defaultMessage="Feed Instagram"
                />
                <span className="right">
                  {formatNumber(info.instagramFeed)}
                  <span className="star">*</span>
                </span>
              </h3>
              <h3>
                <img src={ci15} alt="" />
                <FormattedMessage
                  id="pages.influencer.Story Instagram"
                  defaultMessage="Story Instagram"
                />
                <span className="right">
                  {formatNumber(info.instagramStory)}
                  <span className="star">*</span>
                </span>
              </h3>
            </div>
          )}

          {!!info.youtubeUsername && (
            <div
              className="list-warp"
              style={{ border: "none", paddingBottom: 0 }}
            >
              <h3>
                <img src={ci16} alt="" />
                <FormattedMessage
                  id="pages.influencer.Short Video Youtube"
                  defaultMessage="Short Video Youtube"
                />
                <span className="right">
                  {formatNumber(info.youtubeShortVideoPrice)}
                  <span className="star">*</span>
                </span>
              </h3>
              <h3>
                <img src={ci17} alt="" />
                <FormattedMessage
                  id="pages.influencer.Video Youtube"
                  defaultMessage="Video Youtube"
                />
                <span className="right">
                  {formatNumber(info.youtubeVideoPrice)}
                  <span className="star">*</span>
                </span>
              </h3>
            </div>
          )}
          <p className="notice">
            <span className="star">*</span>
            <FormattedMessage
              id="pages.influencer.Per Video or Per Live Stream Session"
              defaultMessage="Per Video or Per Live Stream Session"
            />
          </p>
          <h3 className="title">
            <FormattedMessage
              id="pages.influencer.Terms & Condition"
              defaultMessage="Terms & Condition"
            />
          </h3>
          <p className="desc">
            <FormattedMessage
              id='pages.influencer.This Rate Card Generator feature is provided by iBooming Official (referred to as "we", "us", or "our") and by using our Rate Card Generator feature, you agree to provide accurate and up-to-date information and grant us a license to use it for generating the rate card. We make no warranties regarding the accuracy of the generated rate card and shall not be liable for any damages. If you have any questions, please contact us.'
              defaultMessage='This Rate Card Generator feature is provided by iBooming Official (referred to as "we", "us", or "our") and by using our Rate Card Generator feature, you agree to provide accurate and up-to-date information and grant us a license to use it for generating the rate card. We make no warranties regarding the accuracy of the generated rate card and shall not be liable for any damages. If you have any questions, please contact us.'
            />
          </p>
        </div>
      </div>
      {/* 社交账号信息 */}
      <div className="social-warp">
        {!!info.instagramUsername && (
          <div className="bg1">
            <div className="head head1">
              <img src={ci1} alt="Instagram" />
              <div>
                <h3>Instagram</h3>
                <p>@{info.instagramUsername}</p>
              </div>
            </div>
            <div className="social-body">
              <div>
                <img src={ci4} alt="" />
                <div>
                  <h3>{formatFansNum(info.instagramFollowers)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Followers"
                      defaultMessage="Followers"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci5} alt="" />
                <div>
                  <h3>{formatFansNum(info.instagramReachedAccounts)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Accounts Reached"
                      defaultMessage="Accounts Reached"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci6} alt="" />
                <div>
                  <h3>{formatFansNum(info.instagramEngagedAccounts)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Accounts Engaged"
                      defaultMessage="Accounts Engaged"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci7} alt="" />
                <div>
                  <h3>
                    <span className="red">{info.instagramMaleRate}% </span>
                    <span className="blue"> {info.instagramFemaleRate}%</span>
                  </h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Gender Audience"
                      defaultMessage="Gender Audience"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {!info.youtubeUsername && !info.instagramUsername ? (
          <div className="bg2 no-other-social">
            <div className="head head2">
              <img src={ci2} alt="Instagram" />
              <div>
                <h3>TikTok</h3>
                <p>@{info.username}</p>
              </div>
            </div>
            <div className="social-body">
              <div
                className="flex-warp"
                style={{
                  flex: "0 0 55%",
                }}
              >
                <div>
                  <img src={ci4} alt="" />
                  <div>
                    <h3>{formatFansNum(info.followers)}</h3>
                    <p>
                      <FormattedMessage
                        id="pages.influencer.Followers"
                        defaultMessage="Followers"
                      />
                    </p>
                  </div>
                </div>

                <div>
                  <img src={ci8} alt="" />
                  <div>
                    <h3>{formatFansNum(info.tiktokAverageViews)}</h3>
                    <p>
                      <FormattedMessage
                        id="pages.influencer.Average Views"
                        defaultMessage="Average Views"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <Divider type="vertical" />
              <div className="flex-warp">
                <div>
                  <img src={ci9} alt="" />
                  <div>
                    <h3>
                      {formatFansNum(info.tiktokGmv, country === "ID", true)}
                    </h3>
                    <p>
                      <FormattedMessage
                        id="pages.influencer.GMV"
                        defaultMessage="GMV"
                      />
                    </p>
                  </div>
                </div>

                <div>
                  <img src={ci7} alt="" />
                  <div>
                    <h3>
                      <span className="red">{info.tiktokMaleRate}% </span>
                      <span className="blue"> {info.tiktokFemaleRate}%</span>
                    </h3>
                    <p>
                      <FormattedMessage
                        id="pages.influencer.Gender Audience"
                        defaultMessage="Gender Audience"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg2">
            <div className="head head2">
              <img src={ci2} alt="Instagram" />
              <div>
                <h3>TikTok</h3>
                <p>@{info.username}</p>
              </div>
            </div>
            <div className="social-body">
              <div>
                <img src={ci4} alt="" />
                <div>
                  <h3>{formatFansNum(info.followers)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Followers"
                      defaultMessage="Followers"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci8} alt="" />
                <div>
                  <h3>{formatFansNum(info.tiktokAverageViews)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Average Views"
                      defaultMessage="Average Views"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci9} alt="" />
                <div>
                  <h3>
                    {formatFansNum(info.tiktokGmv, country === "ID", true)}
                  </h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.GMV"
                      defaultMessage="GMV"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci7} alt="" />
                <div>
                  <h3>
                    <span className="red">{info.tiktokMaleRate}% </span>
                    <span className="blue"> {info.tiktokFemaleRate}%</span>
                  </h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Gender Audience"
                      defaultMessage="Gender Audience"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {!!info.youtubeUsername && (
          <div className="bg3">
            <div className="head head3">
              <img src={ci3} alt="Instagram" />
              <div>
                <h3>Youtube</h3>
                <p>@{info.youtubeUsername}</p>
              </div>
            </div>
            <div className="social-body">
              <div>
                <img src={ci10} alt="" />
                <div>
                  <h3>{formatFansNum(info.youtubeSubscribers)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Subscribers"
                      defaultMessage="Subscribers"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci8} alt="" />
                <div>
                  <h3>{formatFansNum(info.youtubeAverageViews)}</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Average Views"
                      defaultMessage="Average Views"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci6} alt="" />
                <div>
                  <h3>{info.youtubeEngagementRate}%</h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Engagement Rate"
                      defaultMessage="Engagement Rate"
                    />
                  </p>
                </div>
              </div>

              <div>
                <img src={ci7} alt="" />
                <div>
                  <h3>
                    <span className="red">{info.youtubeMaleRate}% </span>
                    <span className="blue"> {info.youtubeFemaleRate}%</span>
                  </h3>
                  <p>
                    <FormattedMessage
                      id="pages.influencer.Gender Audience"
                      defaultMessage="Gender Audience"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <p className="social-desc">
        <FormattedMessage
          id="pages.influencer.The above are all data for the past 30 days"
          defaultMessage="The above are all data for the past 30 days"
        />
      </p>
    </div>
  );
}
