const { COMPILE_ENV = "prod" } = process.env;
let country = localStorage.getItem("country");
const languageEnum = {
  zh: "zh-cn",
  en: "en-us",
  id: "id-id",
  my: "en-us",
  th: "th-th",
  vn: "vn-vn",
  ph: "en-us",
};
const countryEnum = {
  EN: "ID",
  ID: "ID",
  MY: "MY",
  TH: "TH",
  VN: "VN",
  PH: "PH",
};
const search = window.location.search;
if (search && search.includes("locale=")) {
  const str = (search.split("locale=")[1] || "").split("&")[0];
  const newStr = str.split("_");
  const locale = languageEnum[newStr[0]] || "en-us";
  country = countryEnum[newStr[1]] || "ID";
  // 多语言切换
  if (!search.includes("lang=")) {
    localStorage.setItem("locale", locale);
  }
  localStorage.setItem("country", country);
  country = country;
} else {
  country = "ID";
}
const countryUrl = {
  test: {
    TH: "https://bg-th.deltar.xyz",
    ID: "https://bg.deltar.xyz",
    VN: "https://bg-vn.deltar.xyz",
    EN: "https://bg.deltar.xyz",
    ZH: "https://bg.deltar.xyz",
    PH: "https://bg-ph.deltar.xyz",
    MY: "https://bg-my.deltar.xyz",
  },
  prod: {
    TH: "https://api-th.ibooming.top",
    ID: "https://api.ibooming.top",
    VN: "https://api-vn.ibooming.top",
    EN: "https://api.ibooming.top",
    ZH: "https://api.ibooming.top",
    PH: "https://api-ph.ibooming.top",
    MY: "https://api-my.ibooming.top",
  },
};
const url = {
  test: countryUrl.test[country] || "https://bg.deltar.xyz",
  prod: countryUrl.prod[country] || "https://api.ibooming.top",
  dev: "",
};
const newsUrl = {
  test: "https://bg.deltar.xyz",
  prod: "https://api.ibooming.top",
};
export default {
  COMPILE_ENV: COMPILE_ENV,
  BASEURL: url[COMPILE_ENV],
  NAMESPACE: "bg-admin",
  HOSTNAME: "https://dev.chuangyishidai.com",
  APPNAMESPACE: "bg/api",
  COUNTRYURL: countryUrl,
  newsUrl: newsUrl[COMPILE_ENV],
};
