import React, { useEffect } from "react";
import "./index.scss";
import { WOW } from "wowjs";
import { FormattedMessage } from "react-intl";
import { Space } from "antd";
import CountDown from "../../components/CountDown";
import APPJoinUS from "../Business/JoinUS";
import tl1 from "../../assest/tl1.png";
import tl2 from "../../assest/tl2.png";
import tl3 from "../../assest/tl3.png";
import tc1 from "../../assest/tc1.png";
import tc2 from "../../assest/tc2.png";
import tc3 from "../../assest/tc3.png";
import tc4 from "../../assest/tc4.png";
import tc5 from "../../assest/tc5.png";
import time from "../images/time.png";
import TopTalent from "./TopTalent";

export default function APPTalent() {
  const country = localStorage.getItem("locale") || "en-us";
  useEffect(() => {
    const wow = new (window.WOW || WOW)({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  }, []);
  return (
    <div className="app-talent">
      {/* banner */}
      <div className="streamer">
        <h3 className="wow fadeInUp" data-wow-duration="1s">
          <FormattedMessage id="pages.home.您好," defaultMessage="您好," />
          <span>
            <FormattedMessage id="pages.home.网红" defaultMessage="网红" />
          </span>
        </h3>
        <div
          className="wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <p>
            <FormattedMessage
              id="pages.home.为您精准匹配智能货盘以及营销任务，通过iBooming您可以快速在TikTok提升账号动销率，提高收益"
              defaultMessage="为您精准匹配智能货盘以及营销任务，通过iBooming您可以快速在TikTok提升账号动销率，提高收益"
            />
          </p>
          <a className="btn" href="#joinus">
            <FormattedMessage
              id="pages.home.加入网红社群"
              defaultMessage="加入网红社群"
            />
          </a>
        </div>
      </div>
      <div className="talent-imgs">
        <img
          className="img1 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
          src={tl1}
          alt=""
        />
        <img
          className="img2 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
          src={tl2}
          alt=""
        />
        <img
          className="img3 wow fadeIn"
          data-wow-duration="1s"
          src={tl3}
          alt=""
        />
      </div>
      {/* 公司介绍 */}
      <div className="company-info">
        <div className="border">
          <h4>
            <FormattedMessage id="pages.home.已有" defaultMessage="已有" />
          </h4>
          <Space>
            {country === "zh-cn" ? (
              <h3>
                <CountDown count={80} dvalue={5} second={1} type={4} />
                <FormattedMessage id="pages.home.万+" defaultMessage="万+" />
              </h3>
            ) : (
              <h3>
                <CountDown count={800} dvalue={35} second={1} type={4} />
                K+
              </h3>
            )}
            <h4>
              <FormattedMessage
                id="pages.home.动销网红加入我们"
                defaultMessage="动销网红加入我们"
              />
            </h4>
          </Space>
        </div>
        <div className="border">
          <h4>
            <FormattedMessage id="pages.home.已入驻" defaultMessage="已入驻" />
          </h4>
          <Space>
            <h3>5</h3>
            <h4>
              <FormattedMessage
                id="pages.home.国家（印尼，泰国，越南，马来西亚，菲律宾）"
                defaultMessage="国家（印尼，泰国，越南，马来西亚，菲律宾）"
              />
            </h4>
          </Space>
        </div>
        <div className="border border3">
          <h4>
            <FormattedMessage id="pages.home.已有" defaultMessage="已有" />
          </h4>
          <Space>
            <h3>
              <CountDown count={2000} dvalue={288} second={1} type={6} />+
            </h3>
            <h4>
              {country === "th-th" ? (
                <>
                  เข้าร่วมเป็น
                  <br />
                  พาร์ทเนอร์กับแบรนด์
                </>
              ) : (
                <FormattedMessage
                  id="pages.home.合作商家"
                  defaultMessage="合作商家"
                />
              )}
            </h4>
          </Space>
        </div>
      </div>
      {/* 历程 */}
      <div className="course">
        <img src={time} alt="" />
        <div className="time-node">
          <p>2021</p>
          <p className="p2">2022</p>
          <p className="active">2023</p>
        </div>
        <div className="describe">
          <FormattedMessage
            id="pages.home.当前已开通5个国家，突破80万带货网红，成为东南亚最大的TikTok网红分销平台"
            defaultMessage="当前已开通5个国家，突破80万动销网红，成为东南亚最大的TikTok网红分销平台。"
          />
        </div>
      </div>
      {/* 更多创作者 */}
      <div className="more-create">
        <h3 className="aleft">
          <span className="blue">
            <FormattedMessage id="pages.home.MORE" defaultMessage="CREATE" />{" "}
          </span>
          <FormattedMessage id="pages.home.CREATE" defaultMessage="MORE" />
        </h3>
        <h3 className="aright">
          <span className="blue">
            <FormattedMessage id="pages.home.MORE2" defaultMessage="CREATE" />{" "}
          </span>
          <FormattedMessage id="pages.home.BENEFIT" defaultMessage="BENEFIT" />
        </h3>
        <div className="images">
          <img width="10.3%" src={tc1} alt="" />
          <img width="22%" style={{ marginTop: "14%" }} src={tc2} alt="" />
          <img width="29%" src={tc3} alt="" />
          <img width="22%" src={tc4} alt="" />
          <img width="10%" src={tc5} alt="" />
        </div>
        {/* top达人 */}
        <h3 className="acenter">
          <FormattedMessage id="pages.home.TOP" defaultMessage="TOP" />
          <span className="blue">
            <FormattedMessage id="pages.home.网红2" defaultMessage="网红" />
          </span>
        </h3>
        <TopTalent />
      </div>
      <div id="joinus"></div>
      <APPJoinUS type={4} />
    </div>
  );
}
