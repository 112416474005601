export default {
  "pages.home.东南亚": "Terbesar ",
  "pages.home.最大": "di Asia Tenggara",
  "pages.home.我是商家": "Saya Seller",
  "pages.home.我是网红": "Saya Influencer",
  "pages.home.联系我们": "Hubungi Kami",
  "pages.home.经典案例": "Kasus Spesial",
  "pages.home.平台新闻": "Berita Platform",
  "pages.home.中文": "中文",
  "pages.home.海外网红矩阵式分销平台": "Platform Afiliasi Matriks Influencer",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台":
    "iBooming adalah platform pemasaran digital berbasis teknologi. ",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长":
    "Menghubungkan produk dengan audiens yang tepat melalui teknologi pintar. Optimalkan pertumbuhan bisnis Anda dengan strategi hemat biaya dan distribusi yang efisien. ",
  "pages.home.我是网红": "Saya Influencer",
  "pages.home.我是商家": "Saya Seller",
  "pages.home.我是机构": "Saya Institusi",
  "pages.home.活跃网红": "Influencer Aktif",
  "pages.home.每日动销网红": "Influencer Berpenjualan Setiap Hari",
  "pages.home.合作商家": "Mitra Seller",
  "pages.home.我们的": "Bisnis ",
  "pages.home.业务范围": "Kami",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台。":
    "iBooming adalah platform pemasaran digital berbasis teknologi. ",
  "pages.home.通过智能算法，为商家精准匹配大量营销带货网红，快速在TikTok以网红矩阵销售产品以及推广品牌；为网红精准匹配智能货盘以及营销任务，网红通过iBooming可以快速在TikTok提升账号动销率，提高收益。":
    "Kami menciptakan matriks dinamis di TikTok dengan menghubungkan seller dan influencer secara cerdas, mempercepat penjualan dan promosi brand. Bagi influencer, sistem pencocokan akurat di iBooming membantu meningkatkan keterlibatan dan pendapatan di TikTok dengan tugas afiliasi yang cerdas.",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长。":
    "Menghubungkan produk dengan audiens yang tepat melalui teknologi pintar. Optimalkan pertumbuhan bisnis Anda dengan strategi hemat biaya dan distribusi yang efisien. ",
  "pages.home.团长招募": "Seleksi Leader",
  "pages.home.我们一直在寻找优秀的网红领导人，在iBooming您的团队可以快速为您创造更高的带货收益，并能轻松的智能化管理您的网红团队；如果您有网红资源，想在市场营销和娱乐领域创造一个稳定的事业，与全球互联网人才一起共创美好未来，我们将非常欢迎您的加入!":
    "Kami selalu mencari leader influencer unggulan. Di iBooming, tim Anda bisa menciptakan pendapatan tinggi dan mengelola kreator dengan cerdas. Jika Anda punya sumber daya influencer, raih karir stabil bersama talenta global di iBooming!",
  "pages.home.最新动态": "Berita Terbaru",
  "pages.home.亿": "Miliar",
  "pages.home.总曝光量": "Total Impresi",
  "pages.home.总销售额(美元)": "Total Sales(USD)",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo adalah brand internasional yang berfokus pada penelitian dan produksi produk kecantikan dan perawatan pribadi.",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton adalah brand perawatan kecantikan yang berfokus pada pengembangan sabun pemutih.",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为":
    "Feali adalah brand internasional yang berfokus pada penelitian dan produksi kosmetik. Pada bulan Februari 2023, Feali meluncurkan produk perawatan kulit yang unik bernama:",
  "pages.home.您好,": "Halo ",
  "pages.home.商家": "Seller",
  "pages.home.网红": "Influencer",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok达人组织建立快速响应系统":
    "iBooming membangun matriks afiliasi influencer TikTok dengan sejumlah leader tak terbatas, dan membentuk sistem tanggap cepat dengan lebih dari 800,000 influencer TikTok",
  "pages.home.立即报名": "Daftar sekarang juga!",
  "pages.home.立即加入": "Bergabung sekarang juga!",
  "pages.home.80万+ 带货网红": "800 ribu + Influencer",
  "pages.home.我是TikTok": "Saya adalah",
  "pages.home.头部卖家": "Top Seller TikTok",
  "pages.home.新卖家": "Seller Baru TikTok",
  "pages.home.我是其他": "Saya adalah Seller",
  "pages.home.类型商家": "kategori lain",
  "pages.home.为什么选择iBooming": "Mengapa Memilih iBooming",
  "pages.home.无前置费用": "Tanpa DP",
  "pages.home.矩阵分销": "Matriks Afiliasi",
  "pages.home.快速测品": "Uji Produk Cepat",
  "pages.home.本土服务": "Layanan Lokal",
  "pages.home.iBooming不收取任何前置服务费，品牌商可以将所有推广费用花在刀刃上，为您的投产效益做到最大化。":
    "iBooming tidak mengenakan biaya layanan di muka, memungkinkan mitra seller untuk mengalokasikan semua biaya promosi secara efektif, memaksimalkan pengembalian investasi Anda.",
  "pages.home.iBooming服务类型": "Jenis Layanan iBooming",
  "pages.home.抢占海外网红分销的红利":
    "Manfaatkan keuntungan dari afiliasi influencer internasional",
  "pages.home.立即匹配": "Cocokan Sekarang",
  "pages.home.#服务1": "#Layanan 1",
  "pages.home.#服务2": "#Layanan 2",
  "pages.home.#服务3": "#Layanan 3",
  "pages.home.#服务4": "#Layanan 4",
  "pages.home.服务1": "Layanan 1",
  "pages.home.服务2": "Layanan 2",
  "pages.home.服务3": "Layanan 3",
  "pages.home.服务4": "Layanan 4",
  "pages.home.AI matching TikTok Affiliate Partner":
    "Pencocokan AI untuk Mitra Afiliasi TikTok",
  "pages.home.Product Analysis": "Analisis Produk",
  "pages.home.iBooming analyse your product label and potential influencer demographics information through big data":
    "iBooming menganalisis label produk dan informasi demografis potensial influencer melalui big data",
  "pages.home.Celebrity Marketing": "Selebriti Marketing",
  "pages.home.Top Influencer Effect": "Efek Top Influencer",
  "pages.home.iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility":
    "iBooming berspesialisasi dalam mencocokkan produk Anda secara profesional dengan selebriti atau top influencer yang paling sesuai untuk kerjasama, dengan cepat meningkatkan visibilitas merek Anda.",
  "pages.home.Influencer Matrix Promotion": "Promosi Matrix Influencer ",
  "pages.home.Matrix Affiliate": "Matriks Afiliasi",
  "pages.home.iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement":
    "Sistem Distribusi Influencer iBooming memanfaatkan koneksi internet selebriti yang canggih dan sistem eksekusi yang efisien, efektif meningkatkan visibilitas produk dan meningkatkan keterlibatan pengguna.",
  "pages.home.TikTok Advertising": "Iklan TikTok",
  "pages.home.Improve results": "Tingkatkan Hasil",
  "pages.home.iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment":
    "Layanan pengiriman iklan iBooming memastikan Material Stabil, Tingkatkan Efisiensi, dan Maksimalkan ROI.",
  "pages.home.为您精准匹配智能货盘以及营销任务，通过iBooming您可以快速在TikTok提升账号动销率，提高收益":
    "iBooming dapat secara tepat mencocokkan influencer dengan Kecerdasan Buatan (AI) dan tugas pemasaran untuk Anda, sehingga influencer dapat dengan cepat meningkatkan tingkat penjualan akun mereka dan meningkatkan penghasilan di TikTok melalui iBooming!",
  "pages.home.已有": "Sudah ada",
  "pages.home.万+": "Ribuan +",
  "pages.home.动销网红加入我们": "Influencer Bergabung dengan Kami",
  "pages.home.已入驻": "Didirikan di",
  "pages.home.国家（印尼，泰国，越南，马来西亚，菲律宾）":
    "Negara (Indonesia, Thailand, Vietnam, Malaysia, Filipina).",
  "pages.home.当前已开通5个国家，突破80万带货网红，成为东南亚最大的TikTok网红分销平台":
    "Saat ini beroperasi di 5 negara, dengan lebih dari 800,000 penjualan afiliasi berpengaruh, kami telah menjadi platform afiliasi TikTok influencer terbesar di Asia Tenggara.",
  "pages.home.TOP": "TOP ",
  "pages.home.网红2": "Influencer",
  "pages.home.快加入我们": "Bergabunglah dengan kami segera!",
  "pages.home.加入网红社群": "Bergabunglah dengan Komunitas Influencer",
  "pages.home.八十万网红池": "Kumpulan 800,000 Influencer",
  "pages.home.各类目网红满足您的商业需求":
    "Berbagai jenis influencer untuk memenuhi kebutuhan bisnis Anda",
  "pages.home.立即咨询我们": "Hubungi kami sekarang",
  "pages.home.提交": "Kirim",
  "pages.home.#步骤1": "#Layanan 1",
  "pages.home.#步骤2": "#Langkah 2",
  "pages.home.#步骤3": "#Langkah 3",
  "pages.home.#步骤4": "#Langkah 4",
  "pages.home.注册填写您的品牌": "Daftar dan isi informasi brand Anda",
  "pages.home.AI Booms 工具扫描您的店铺信息":
    "AI Booms memindai informasi toko Anda",
  "pages.home.AI Booms 为您提供符合您企业的最佳推广方案":
    "AI Booms memberi Anda solusi promosi terbaik untuk bisnis Anda",
  "pages.home.享受您的品牌流量": "Nikmati trafik brand Anda",
  "pages.home.平台新闻": "Berita Platform",
  "pages.home.不要错过与iBooming的每一刻":
    "Jangan lewatkan setiap momen bersama iBooming",
  "pages.home.继续阅读": "Lanjutkan membaca",
  "pages.home.您的企业名称": "Nama Perusahaan Anda",
  "pages.home.企业所在国家": "Lokasi Perusahaan",
  "pages.home.选择类目": "Pilih Kategori",
  "pages.home.印度尼西亚": "Indonesia",
  "pages.home.泰国": "Thailand",
  "pages.home.越南": "Vietnam",
  "pages.home.马来西亚": "Malaysia",
  "pages.home.菲律宾": "Filipina",
  "pages.home.美妆": "Kecantikan",
  "pages.home.电子": "Barang elektronik",
  "pages.home.服饰": "Fashion",
  "pages.home.食品": "Makanan",
  "pages.home.家居生活": "Rumah & Gaya Hidup",
  "pages.home.母婴": "Ibu dan Bayi",
  "pages.home.个护和健康": "Perawatan & Kesehatan Pribadi",
  "pages.home.恭喜您提交成功": "Selamat, pengajuan Anda berhasil dikirim!",
  "pages.home.我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作":
    "Staf kami akan segera menghubungi Anda. Anda juga dapat mendaftar langsung untuk kerjasama TAP",
  "pages.home.前往 TikTok TAP报名": "Pergi ke pendaftaran TikTok TAP",
  "pages.home.iBooming平台超大的网红活跃人数，可以快速让网红生产带货短视频以及直播带货，形成网红推品矩阵，让产品可以更大范围的覆盖到TikTok的消费用户，提升动销率，快速打爆产品。":
    "Platform iBooming memiliki banyak influencer aktif, memungkinkan produksi video pendek dan penjualan live dengan cepat, membentuk matriks produk yang didorong oleh influencer, memperluas cakupan produk ke basis konsumen TikTok, meningkatkan tingkat penjualan, dan mempopulerkan produk dengan cepat.",
  "pages.home.新品可以通过iBooming匹配小规模网红，以小微矩阵的形式快速获得市场多维度数据反馈，让品牌商可以更快的布局市场以及迭代产品。":
    "Produk baru dapat dicocokan dengan influencer berskala kecil melalui iBooming, mendapatkan umpan balik pasar multidimensi dengan cepat, sehingga memungkinkan mitra brand untuk memasarkan dan mengembangkan produk lebih cepat.",
  "pages.home.iBooming在东南亚各国都有本土的服务团队，提供免费咨询以及市场调研，更加本土化的基因可以规避品牌出海的水土不服。":
    "iBooming memiliki tim layanan lokal di berbagai negara Asia Tenggara, memberikan konsultasi dan riset pasar secara gratis, dan pendekatannya yang lebih terlokalisasi membantu mengurangi tantangan saat mengembangkan brand secara internasional.",
  "pages.home.新": "Seller ",
  "pages.home.卖家": "Baru",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok网红组织建立快速响应系统":
    "iBooming membangun matriks afiliasi influencer TikTok dengan sejumlah leader tak terbatas, dan membentuk sistem tanggap cepat dengan lebih dari 80,000 influencer TikTok",
  "pages.home.其他类型": "Jenis Lainnya ",
  "pages.home.首页": "Beranda",
  "pages.home.About iBooming": "",
  "pages.home.iBooming is a technology company specializing in developing artificial intelligence solutions for the digital content industry. Through AI, iBooming enables influencers and brands to optimize content strategies, expand reach, and increase monetization. With a commitment to innovation, iBooming aims to be a leader in AI-powered industry change, enabling influencers and brands to increase product visibility and achieve success in the global marketplace. For more information about iBooming, visit www.iboomingglobal.com":
    "",
  "pages.home.TikTok username": "",
  "pages.home.更多高佣商品与带货补贴":
    "Lebih banyak produk dengan komisi tinggi dan subsidi afiliasi produk",
  "pages.home.粉丝数": "Jumlah pengikut",
  "pages.home.拍摄一个视频的价格": "Harga untuk satu video",
  "pages.home.其他账号": "Akun lainnya",
  "pages.home.提交成功": "Berhasil dikirim",
  "pages.home.印尼": "Indonesia",
  "pages.home.中国": "Tiongkok",
  "pages.home.1,000,000 以上": "Lebih dari 1,000,000",
  "pages.home.500,000 至 1,000,000": "500.000 hingga 1.000.000",
  "pages.home.100,000 至 500,000": "100.000 hingga 500.000",
  "pages.home.50,000 至 100,000": "50.000 hingga 100.000",
  "pages.home.10,000 至 50,000": "10.000 hingga 50.000",
  "pages.home.10,000 以下": "Kurang dari 10.000",
  "pages.home.在线品牌商": "Online Brand Seller",
  "pages.home.我是网红": "Saya Influencer",
  "pages.home.我们的业务范围": "Rentang Bisnis Kami",
  "pages.home.电子邮箱": "Email",
  "pages.home.关于我们": "Tentang Kami",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过100万TikTok达人组织建立快速响应系统":
    "iBooming membangun matriks afiliasi influencer TikTok dengan sejumlah leader tak terbatas, dan membentuk sistem tanggap cepat dengan lebih dari 1 juta influencer TikTok",
  "pages.home.抢占海外达人分销的红利":
    "Raih bonus dari penjualan afiliasi influencer luar negeri",
  "pages.home.联系号码": "Nomor Kontak",
  "pages.home.微信": "WeChat",
  "pages.home.合作商家": "Mitra Seller",
  "pages.home.立即加入网红社群":
    "Bergabunglah dengan Komunitas Influencer Sekarang!",
  "pages.home.合作媒体": "Mitra Media",
  "pages.home.网红加入": "Influencer Bergabung",
  "pages.home.合作团长": "Leader Kolaboratif",
  "pages.home.扫码下载APP": "Pindai untuk Unduh Aplikasi",
  "pages.home.复制成功": "Berhasil disalin",
  "pages.home.护肤": "Perawatan Kulit",
  "pages.home.彩妆": "Makeup",
  "pages.home.立即下载": "Unduh Sekarang",
  "pages.home.下载iBooming APP, 参与活动领福利":
    "Unduh Aplikasi iBooming, ikuti kegiatan dan dapatkan Bonus",
  "pages.home.平均日销量": "Penjualan Harian Rata-rata",
  "pages.home.1亿": "Juta",
  "pages.home.200亿": "Miliar",
  "pages.home.立即匹配": "Cocokan Sekarang",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton adalah brand kecantikan yang berfokus pada pengembangan sabun pemutih.",
  "pages.home.该品牌过去依赖当地的经销商来销售产品，像是传统电商平台Tokopedia，Shopee或Lazada等其他渠道进行销售。":
    "Brand ini sebelumnya mengandalkan penjualan melalui platform distributor lokal seperti Tokopedia, Shopee, atau Lazada.",
  "pages.home.然而，由于推广的数量以及力度不足，销售一直停滞不前。":
    "Namun, karena kurangnya promosi, penjualan tetap stagnan.",
  "pages.home.2022年10月，Kojic Plankton与iBooming合作，首次利用KOL（关键意见领袖）和KOC（关键意见消费者）在TikTok上推广其产品。":
    "Pada Oktober 2022, Kojic Plankton bermitra dengan iBooming untuk mempromosikan produknya di TikTok menggunakan KOL (Key Opinion Leader) dan KOC (Key Opinion Consumers).",
  "pages.home.iBooming的团队花费了两个月的时间寻找适合该产品的创作者，并测试了各种内容、定价策略和推广方式等等，以提高产品销售。":
    "Tim iBooming menghabiskan 2 bulan untuk mencari influencer yang cocok untuk produk ini, menguji berbagai konten, strategi harga, dan cara promosi untuk meningkatkan penjualan.",
  "pages.home.到12月底，iBooming的团队成功将该产品推动到东南亚TikTok销售排行榜的前列，在仅仅1天内就获得了超过70,000个订单，对比以往的销量提高了百分比的成绩。":
    "iBooming berhasil memajukan produk ini ke puncak penjualan TikTok di Asia Tenggara, mengamankan lebih dari 70000 pesanan hanya dalam 1 hari. Peningkatan yang luar biasa dibandingkan dengan penjualan sebelumnya.",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo adalah brand internasional yang fokus pada perawatan kecantikan pribadi.",
  "pages.home.在2022年初，他们推出了MeToo漱口水，并与iBooming合作。":
    "MeToo meluncurkan Mouthwash bekerja sama dengan iBooming pada awal 2022.",
  "pages.home.在两个月的时间里，iBooming精准地将该产品分配给与MeToo品牌价值观相符的内容创作者":
    "iBooming dengan tepat mengakolasikan produk ke influencer yang sejalan dengan nilai brand MeToo.",
  "pages.home.除了在TikTok上的MeToo Smile官方活动，品牌商也斥巨资邀请了一位当地备受瞩目的当红明星作为该产品的品牌大使，以及各大新闻媒体的报道支持，促成了MeToo在TikTok上迅速走红。":
    "MeToo juga menginvestasikan sejumlah selebritas lokal sebagai ambasador brand produk ini. Selain acara MeToo Smile di TikTok, brand mendapatkan dukungan dari berbagai media berita utama dan mendapatkan popularitas di TikTok dengan cepat.",
  "pages.home.在Metoo漱口水上市仅三天内，根据数据显示，每日有超过上千名的网红通过发布短视频或直播带货的方式销售产品，快速推动了商品销量，平均日销量超过了40,000瓶。":
    "MeToo Mouthwash mencapai lebih dari 40000 penjualan harian dengan ribuan influencer mempromosikan produk melalui video dan sesi LIVE dalam 3 hari setelah peluncuran produk.",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为 FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream。":
    'Feali adalah brand internasional yang berfokus pada pengembangan kosmetik. Mereka meluncurkan produk perawatan kulit unik bernama "FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream" pada Febuari 2023.',
  "pages.home.该产品使用了市场上罕见的天然成分，当涂抹于皮肤时会产生白色气泡，使其在视觉上也非常吸引人。":
    "Produk ini mengandung bahan alami langka yang menghasilkan efek menarik gelembung putih saat dioleskan ke kulit.",
  "pages.home.iBooming用了两周的时间，根据后台数据智能匹配了与该产品标签相符的带货网红，并快速积极地完成了产品的市场试错，为接下来的大型推广扎稳了根基。，并有效地推广了FEALI面霜。":
    "iBooming mencocokan produk dengan influencer dalam 2 bulan, melakukan uji pasar secara aktif, membentuk dasar yang kokoh, dan berhasil meningkatkan promosi krim FEALI secara efektif.",

  // "pages.home.MORE": "Buat Lebih ",
  // "pages.home.CREATE": "Banyak",
  // "pages.home.MORE2": "Dapat Lebih ",
  // "pages.home.BENEFIT": "Banyak",
  "pages.home.MORE": "CREATE ",
  "pages.home.CREATE": "MORE",
  "pages.home.MORE2": "EARN ",
  "pages.home.BENEFIT": "MORE",
  "pages.home.业务所在国家": "Negara Bisnis",
  "pages.home.所在国家": "Negara",
  "pages.home.其他账号（选填）": "Akun Lainnya (Opsional)",
  "pages.home.仅仅一个月时间，Feali就成功提升了销量并取得了令人瞩目的成果，充分诠释了有针对性的营销策略和制作创意内容所带来的成效是无可比拟的。":
    "Hanya dalam satu bulan, keberhasilan Feali dalam meningkatkan penjualan dan mencapai hasil yang luar biasa, menunjukan keefektifan yang tak tertandingi dari strategi pemasaran yang ditargetkan dan produksi konten kreatif.",
  "pages.home.企业微信": "WeCom",
  "pages.home.前往TikTok TAP报名": "Ayo Daftar TikTok TAP",

  "pages.mcn.获得": "Dapatkan ",
  "pages.mcn.更多": "Lebih Banyak",
  "pages.mcn.好处": "Keuntungan",
  "pages.mcn.加入正确MCN，你可获得":
    "Temukan MCN yang Tepat: Apa Keuntungan Eksklusif yang Menanti Anda",
  "pages.mcn.官方流量扶持": "Dukungan traffic resmi",
  "pages.mcn.官方每个月的佣金补贴": "Subsidi komisi bulanan resmi",
  "pages.mcn.享有直播特价福利": "LIVE eksklusif",
  "pages.mcn.短视频/直播投流补贴": "Subsidi promosi video/ LIVE",
  "pages.mcn.立即": "Pilih ",
  "pages.mcn.选择": " Sekarang",
  "pages.mcn.获得官方政策": "untuk mengakses kebijakan resmi",
  "pages.mcn.我们": "Kami tawarkan:",
  "pages.mcn.零抽佣，品牌佣金100%给创作者":
    "Komisi tak terbatas, brand memberi 100% komisi untuk para kreator",
  "pages.mcn.无限制，自由选择带货": "Bebas memilih produk afiliasi",
  "pages.mcn.专业导师培训TikTok最新功能":
    "Bimbingan profesional tentang fitur terbaru TikTok",
  "pages.mcn.官方限时加入": "Pendaftaran Waktu Terbatas",
  "pages.mcn.扫码打开链接填写信息":
    "Scan kode, buka tautan, dan isi informasi Anda",
  "pages.mcn.审核通过结果请查收您的TikTok站内信":
    "Periksa kotak masuk TikTok untuk hasil persetujuan",
  "pages.mcn.步骤1": "Langkah 1",
  "pages.mcn.填写信息注册": "Isi Informasi",
  "pages.mcn.填写信息申请加入": "Isi informasi untuk pengajuan",
  "pages.mcn.TikTok 账号": "Akun TikTok",
  "pages.mcn.粉丝数": "Pengikut",
  "pages.mcn.选择类目": "Pemilihan Kategori",
  "pages.mcn.拍摄一个视频的价格": "Harga per video",
  "pages.mcn.立即申请": "Ajukan Sekarang",
  "pages.mcn.步骤2": "Langkah 2",
  "pages.mcn.查看审核结果": "Periksa Hasil Tinjauan",
  "pages.mcn.打开TikTok站内信查看审核结果": "Buka Kotak masuk TikTok",
  "pages.mcn.如果通过，您会收到来自":
    "Jika disetujui, Anda akan menerima undangan dari ",
  "pages.mcn.的邀约": " di kotak masuk TikTok Anda",
  "pages.mcn.步骤3": "Langkah 3",
  "pages.mcn.点击同意": "Terima Undangan",
  "pages.mcn.点击同意，恭喜您加入成功":
    "Terima Undangan dan Selamat bergabung!",
  "pages.mcn.提交申请成功": "Pengajuan sukses",
  "pages.mcn.一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信":
    "Jika pengajuan sukses, kami akan mengirim undangan. Mohon perhatikan kotak masuk TikTok Anda.",
  "pages.mcn.好的": "OK",
  "pages.mcn.提交失败，请再尝试一次": "Pengajuan gagal. Mohon dicoba lagi.",
  "pages.mcn.已申请": "Sudah diajukan",
  "pages.mcn.填写信息加入，审核通过结果请查收您的TikTok站内信":
    "Isi informasi untuk bergabung. Silahkan cek kotak masuk TikTok untuk hasil peninjauan.",
  "pages.mcn.填写信息申请": "Isi informasi untuk pengajuan",
  "pages.home.AI Matching": "AI Matching",
  "pages.home.Matrix Affiliate": "Matrix Affiliate",
  "pages.home.Keep Engaged": "Keep Engaged",
  "pages.home.Influencer Mega Campaign": "Influencer Mega Campaign",
  "pages.home.其他": "Lainnya",
  "pages.mcn.Get More": "Dapatkan",
  "pages.mcn.Traffic": "Dorongan",
  "pages.mcn.Boost": "Trafik",
  "pages.商家入驻.BOOMING": "BOOMING",
  "pages.商家入驻.YOUR BRAND WITH": "YOUR BRAND WITH",
  "pages.商家入驻.MOUTH-TO-MOUTH": "MOUTH-TO-MOUTH",
  "pages.商家入驻.MARKETING": "MARKETING",
  "pages.商家入驻.iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara":
    "iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara",
  "pages.商家入驻.Influencer": "Influencer",
  "pages.商家入驻.Product": "Brand",
  "pages.商家入驻.Brand": "Produk",
  "pages.商家入驻.Matchmaking Brand dan Influencer dengan AI Tools":
    "Matchmaking Brand dan Influencer dengan AI Tools",
  "pages.商家入驻.Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia":
    "Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia Tenggara",
  "pages.商家入驻.Minimalisir Biaya Operasional dan Sumber Daya Manusia":
    "Minimalisir Biaya Operasional dan Sumber Daya Manusia",
  "pages.商家入驻.Maksimalkan Trafik Persentase Penjualan Produk":
    "Maksimalkan Trafik Persentase Penjualan Produk",
  "pages.商家入驻.One Stop Solution Services dalam Satu Platform":
    "One Stop Solution Services dalam Satu Platform",
  "pages.商家入驻.Mengapa MOUTH-TO-MOUTH MARKETING di iBooming":
    "Mengapa MOUTH-TO-MOUTH MARKETING di iBooming",
  "pages.商家入驻.Peran Nano-Influencer Marketing":
    "Peran Nano-Influencer Marketing",
  "pages.商家入驻.Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan":
    "Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan",
  "pages.商家入驻.More Trusted": "More Trusted",
  "pages.商家入驻.Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth":
    "Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth",
  "pages.商家入驻.More Profit": "More Profit",
  "pages.商家入驻.Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat":
    "Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat",
  "pages.商家入驻.More Massive": "More Massive",
  "pages.商家入驻.Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer":
    "Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer",
  "pages.商家入驻.Hasil MOUTH-TO-MOUTH MARKETING di iBooming":
    "Hasil MOUTH-TO-MOUTH MARKETING di iBooming",
  "pages.商家入驻.SEGERA DAFTARKAN BRAND ANDA": "SEGERA DAFTARKAN BRAND ANDA",
  "pages.商家入驻.INFLUENCER": "INFLUENCER",
  "pages.商家入驻.BRAND": "BRAND",
  "pages.商家入驻.Jordi": "Jordi",
  "pages.商家入驻.Willie Salim": "Willie Salim",
  "pages.商家入驻.Vilmei": "Vilmei",
  "pages.商家入驻.Awkarin": "Awkarin",
  "pages.商家入驻.Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus":
    "Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus",
  "pages.商家入驻.Top Product Sales in Southeast Asia":
    "Top Product Sales in Southeast Asia",
  "pages.商家入驻.Top Product Trending Skincare":
    "Top Product Trending Skincare",
  "pages.商家入驻.Top Product Body Wash": "Top Product Body Wash",
  "pages.商家入驻.Top Product Best Selling Skincare":
    "Top Product Best Selling Skincare",
  "pages.商家入驻.100jt+": "100jt+",
  "pages.商家入驻.Total Impresi": "Total Impresi",
  "pages.商家入驻.1,3M+": "1,3M+",
  "pages.商家入驻.Total Penjualan": "Total Penjualan",
  "pages.商家入驻.TikTok Southeast Asia Hot Product List":
    "TikTok Southeast Asia Hot Product List",
  "pages.商家入驻.400jt+": "400jt+",
  "pages.商家入驻.40.000+": "40,000+",
  "pages.商家入驻.Penjualan Harian Rata-Rata": "Penjualan Harian Rata-Rata",
  "pages.商家入驻.No. 1": "No. 1",
  "pages.商家入驻.in Skincare Package": "in Skincare Package",
  "pages.商家入驻.Penjualan Bulanan Rata-Rata": "Penjualan Bulanan Rata-Rata",
  "pages.商家入驻.100.000+": "100,000+",
  "pages.商家入驻.Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu":
    "Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu",
  "pages.商家入驻.Nama Perusahaan": "Nama Perusahaan",
  "pages.商家入驻.申请入驻": "Daftar ",
  "pages.商家入驻.Jenis Usaha": "Jenis Usaha",
  "pages.商家入驻.Kategori Produk": "Kategori Produk",
  "pages.商家入驻.Nomor Kontak Aktif": "Nomor Kontak Aktif ",

  "pages.influencer.RATE CARD": "RATE CARD",
  "pages.influencer.Updated": "Diperbarui",
  "pages.influencer.Category": "Kategori",
  "pages.influencer.Followers": "Pengikut",
  "pages.influencer.Accounts Reached": "Jangkauan Akun",
  "pages.influencer.Accounts Engaged": "Tingkat Interaksi Akun",
  "pages.influencer.Gender Audience": "Audience Gender",
  "pages.influencer.Average Views": "Average Views",
  "pages.influencer.GMV": "GMV",
  "pages.influencer.Subscribers": "Subscribers",
  "pages.influencer.The above are all data for the past 30 days":
    "Semua data di atas merupakan data selama 30 hari terakhir",
  "pages.influencer.Short Video TikTok": "Video Pendek TikTok",
  "pages.influencer.Live Stream TikTok": "Live Streaming TikTok",
  "pages.influencer.Reels Instagram": "Reels Instagram",
  "pages.influencer.Feed Instagram": "Feed Instagram",
  "pages.influencer.Story Instagram": "Story Instagram",
  "pages.influencer.Short Video Youtube": "Short Video YouTube",
  "pages.influencer.Video Youtube": "Video YouTube",
  "pages.influencer.Per Video or Per Live Stream Session":
    "Per Video atau Per Sesi LIVE",
  "pages.influencer.Terms & Condition": "Syarat & Ketentuan",
  "pages.influencer.简介": "Biodata",
  "pages.influencer.已认证": "Terverifikasi",
  "pages.influencer.未认证": "Belum terverifikasi",
  'pages.influencer.This Rate Card Generator feature is provided by iBooming Official (referred to as "we", "us", or "our") and by using our Rate Card Generator feature, you agree to provide accurate and up-to-date information and grant us a license to use it for generating the rate card. We make no warranties regarding the accuracy of the generated rate card and shall not be liable for any damages. If you have any questions, please contact us.':
    'Fitur pembuat Rate Card ini disediakan oleh iBooming Official (disebut sebagai "kami", atau "kita") dan dengan menggunakan fitur pembuat Rate Card kami, kamu setuju untuk memberikan informasi yang akurat dan terkini serta memberi kami lisensi untuk menggunakannya untuk membuat Rate Card. Kami tidak memberikan jaminan mengenai keakuratan kartu tarif yang dihasilkan dan tidak bertanggung jawab atas kerusakan apapun. Jika kamu memiliki pertanyaan, silakan hubungi kami.',
};
