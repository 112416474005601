import React, { useEffect, lazy, Suspense, useState } from "react";
import PcHeader from "../components/PcHeader";
import PCHome from "./Home";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import PCFooter from "../components/PcFooter";
import { WOW } from "wowjs";
import { FormattedMessage } from "react-intl";
import d1 from "../assest/d1.png";
import download from "../assest/download.png";
import d2 from "../assest/d2.png";
const PCBrand = lazy(() => import("./Brand"));
const PCNewsDetail = lazy(() => import("./NewsDetail"));
const PCTalent = lazy(() => import("./Talent"));
const PCBusiness = lazy(() => import("./Business"));
const PCNewsList = lazy(() => import("./NewsList"));
const PCCertifiedInfluencer = lazy(() => import("./CertifiedInfluencer"));
// const PCFlowScheme = lazy(() => import("./Flow"));
// const PCHome = lazy(() => import('./Home'));
let fixedHead = null;
export default function PCView(props) {
  const [show, setShow] = useState(false);
  const country = localStorage.getItem("locale") || "en-us";
  // const pathname = window?.location?.pathname;
  const [pathname, setPathname] = useState(window?.location?.pathname);
  useEffect(() => {
    const wow = new (window.WOW || WOW)({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  }, []);
  useEffect(() => {
    console.log(1111);
  }, [window?.location?.pathname]);
  // 105
  // 12

  // en 100
  return (
    <div className="pc-bg">
      <BrowserRouter>
        <PcHeader />
        <div
          style={{ minHeight: 630 }}
          className={country !== "zh-cn" ? "pc-en-class" : ""}
        >
          <Suspense>
            <Routes>
              <Route index element={<PCHome />} />
              {/* <Route path="/flow" element={<PCFlowScheme type={1} />} /> */}
              <Route
                path="/certified/:id"
                element={<PCCertifiedInfluencer />}
              />
              <Route path="/brand/detail/:id" element={<PCBrand />} />
              <Route path="/news/detail/:id" element={<PCNewsDetail />} />
              <Route path="/newslist" element={<PCNewsList />} />
              <Route path="/business" element={<PCBusiness type={1} />} />
              <Route path="/talent" element={<PCTalent type={2} />} />
              <Route path="/home" element={<PCHome />} />
              <Route path="/*" celement={<PCHome />} />
            </Routes>
          </Suspense>
        </div>
        <div className="pc-download">
          {show ? (
            <div className="big-label" onClick={() => setShow(false)}>
              <div className="bg">
                <p>
                  <FormattedMessage
                    id="pages.home.扫码下载APP"
                    s
                    defaultMessage="扫码下载APP"
                  />
                </p>
                <img src={download} alt="" />
              </div>
              <img className="d-img" src={d2} alt="" />
            </div>
          ) : (
            <div
              className="small-label"
              onClick={() => setShow(true)}
              style={
                country.includes("id")
                  ? {
                      width: 105,
                      fontSize: 12,
                    }
                  : country.includes("zh")
                  ? {}
                  : {
                      width: 102,
                    }
              }
            >
              <p>
                <FormattedMessage
                  id="pages.home.扫码下载APP"
                  defaultMessage="扫码下载APP"
                />
              </p>
              <img src={d1} alt="" />
            </div>
          )}
        </div>
        <PCFooter />
      </BrowserRouter>
    </div>
  );
}
