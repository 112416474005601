/**
 * 新闻详情
 */
import React, { useEffect } from "react";
// import './index.scss';
import "../NewsDetail/index.scss";
import newdright from "../../assest/newdright.png";
import MeToo from "./MeToo";
import Kojic from "./Kojic";
import Feail from "./Feail";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
const brandText = {
  2: "MeToo",
  1: "Kojic Plankton",
  3: "Feali",
};
export default function APPBrand() {
  const id = useParams().id;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <div className="app-new-content">
      <h3 className="title">{brandText[id]}</h3>
      <div
        className="news-detail"
        style={{
          paddingTop: "0.4rem",
        }}
      >
        {id == 1 && <Kojic />}
        {id == 2 && <MeToo />}
        {id == 3 && <Feail />}
      </div>
    </div>
  );
}
