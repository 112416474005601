export default {
  "pages.home.东南亚": "tại Đông Nam Á ",
  "pages.home.最大": "Lớn nhất",
  "pages.home.我是商家": "Tôi là seller",
  "pages.home.我是网红": "Tôi là influencer",
  "pages.home.联系我们": "Liên hệ chúng tôi",
  "pages.home.经典案例": "Thành Tựu của chúng tôi",
  "pages.home.平台新闻": "Tin tức nền tảng",
  "pages.home.中文": "中文",
  "pages.home.海外网红矩阵式分销平台":
    "Nền tảng liên kết ma trận cho influencer",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台":
    "iBooming là một nền tảng tiếp thị kỹ thuật số dựa trên công nghệ.",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长":
    "Trao quyền cho đúng người để liên kết đúng sản phẩm, đảm bảo đúng sản phẩm đến đúng người. Trao quyền cho doanh nghiệp của bạn để tăng trưởng theo cấp số nhân thông qua các chiến lược thông minh, tiết kiệm chi phí nhằm tối ưu hóa quy trình phân phối và thúc đẩy hiệu quả.",
  "pages.home.我是网红": "Tôi là influencer",
  "pages.home.我是商家": "Tôi là seller",
  "pages.home.我是机构": "Tôi là tổ chức",
  "pages.home.活跃网红": "Influencer tích cực",
  "pages.home.每日动销网红": "bán hàng ngày của Influencer",
  "pages.home.合作商家": "Hợp tác",
  "pages.home.我们的": "Của chúng tôi ",
  "pages.home.业务范围": "Phạm vi kinh doanh",
  "pages.home.iBooming是一家以技术为核心驱动的数字化营销平台。":
    "iBooming là một nền tảng tiếp thị kỹ thuật số dựa trên công nghệ.",
  "pages.home.通过智能算法，为商家精准匹配大量营销带货网红，快速在TikTok以网红矩阵销售产品以及推广品牌；为网红精准匹配智能货盘以及营销任务，网红通过iBooming可以快速在TikTok提升账号动销率，提高收益。":
    "Bằng cách sử dụng các thuật toán thông minh, chúng tôi kết nối chính xác các doanh nghiệp với rất nhiều người có ảnh hưởng am hiểu về tiếp thị, nhanh chóng tạo ra một ma trận động trên TikTok để bán sản phẩm và quảng bá thương hiệu. Đối với những người có ảnh hưởng, hệ thống kết hợp chính xác của chúng tôi sẽ điều chỉnh họ với các lựa chọn sản phẩm thông minh và nhiệm vụ tiếp thị phù hợp thông qua iBooming, đảm bảo mức độ tương tác tài khoản tăng nhanh và tăng thu nhập trên TikTok.",
  "pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长。":
    "Trao quyền cho đúng người để liên kết đúng sản phẩm, đảm bảo đúng sản phẩm đến đúng người. Trao quyền cho doanh nghiệp của bạn để tăng trưởng theo cấp số nhân thông qua các chiến lược thông minh, tiết kiệm chi phí nhằm tối ưu hóa quy trình phân phối và thúc đẩy hiệu quả.",
  "pages.home.团长招募": "Tuyển dụng Trưởng nhóm",
  "pages.home.我们一直在寻找优秀的网红领导人，在iBooming您的团队可以快速为您创造更高的带货收益，并能轻松的智能化管理您的网红团队；如果您有网红资源，想在市场营销和娱乐领域创造一个稳定的事业，与全球互联网人才一起共创美好未来，我们将非常欢迎您的加入!":
    "Chúng tôi luôn tìm kiếm những nhà lãnh đạo sáng tạo nổi trội. Với iBooming, nhóm của bạn có thể nhanh chóng tạo ra doanh thu cao hơn cho bạn và dễ dàng quản lý nhóm người ảnh hưởng của bạn một cách thông minh. Nếu bạn có nguồn lực có ảnh hưởng và muốn tạo dựng sự nghiệp ổn định trong lĩnh vực tiếp thị và giải trí, cùng với các tài năng internet toàn cầu, chúng tôi nồng nhiệt chào đón sự tham gia của bạn!",
  "pages.home.最新动态": "Cập nhật mới nhất",
  "pages.home.亿": "Triệu",
  "pages.home.总曝光量": "Tổng sản phẩm phơi bày",
  "pages.home.总销售额": "Tổng doanh thu",
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo là thương hiệu quốc tế tập trung nghiên cứu và sản xuất các sản phẩm làm đẹp và chăm sóc cá nhân.",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Kojic Plankton là thương hiệu chăm sóc sắc đẹp và cá nhân tập trung vào phát triển xà phòng làm trắng.",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为":
    "Feali là thương hiệu quốc tế tập trung vào nghiên cứu và sản xuất mỹ phẩm. Vào tháng 2 năm 2023, Feali cho ra mắt sản phẩm chăm sóc da độc đáo mang tên:",
  "pages.home.您好,": "Xin chào ",
  "pages.home.商家": "Seller",
  "pages.home.网红": "Influencer",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok达人组织建立快速响应系统":
    "iBooming xây dựng ma trận liên kết người ảnh hưởng TikTok với vô số trưởng nhóm, thiết lập hệ thống phản hồi nhanh với hơn 800.000 người có ảnh hưởng TikTok.",
  "pages.home.立即报名": "Đăng ký ngay!",
  "pages.home.立即加入": "Tham gia ngay !",
  "pages.home.80万+ 带货网红": "Hơn 800k người ảnh hưởng",
  "pages.home.我是TikTok": "Tôi là TikTok",
  "pages.home.头部卖家": "Seller hàng đầu",
  "pages.home.新卖家": "Seller mới",
  "pages.home.我是其他": "Tôi là Seller Của ",
  "pages.home.类型商家": "những danh mục khác",
  "pages.home.为什么选择iBooming": "Tại sao chọn iBooming",
  "pages.home.无前置费用": "Không chi phí trả trước",
  "pages.home.矩阵分销": "Ma trận liên kết",
  "pages.home.快速测品": "Test sản phẩm nhanh chóng",
  "pages.home.本土服务": "Dịch vụ địa phương",
  "pages.home.iBooming不收取任何前置服务费，品牌商可以将所有推广费用花在刀刃上，为您的投产效益做到最大化。":
    "iBooming không tính bất kỳ khoản phí dịch vụ trả trước nào, cho phép đối tác người bán phân bổ tất cả chi phí khuyến mãi một cách hiệu quả, tối đa hóa lợi tức đầu tư của bạn.",
  "pages.home.iBooming服务类型": "Các dịch vụ của iBooming",
  "pages.home.抢占海外网红分销的红利":
    "Tận dụng lợi ích của việc liên kết với người có ảnh hưởng ở nước ngoài",
  "pages.home.立即匹配": "Ghép ngay",
  "pages.home.#服务1": "#Dịch vụ 1",
  "pages.home.#服务2": "#Dịch vụ 2",
  "pages.home.#服务3": "#Dịch vụ 3",
  "pages.home.#服务4": "#Dịch vụ 4",
  "pages.home.服务1": "Dịch vụ 1",
  "pages.home.服务2": "Dịch vụ 2",
  "pages.home.服务3": "Dịch vụ 3",
  "pages.home.服务4": "Dịch vụ 4",
  "pages.home.AI matching TikTok Affiliate Partner":
    "Đối tác liên kết TikTok phù hợp với AI",
  "pages.home.Product Analysis": "Phân tích sản phẩm",
  "pages.home.iBooming analyse your product label and potential influencer demographics information through big data":
    "iBooming phân tích nhãn sản phẩm của bạn và thông tin nhân khẩu học có ảnh hưởng tiềm năng thông qua dữ liệu lớn",
  "pages.home.Celebrity Marketing": "Tiếp thị người nổi tiếng",
  "pages.home.Top Influencer Effect": "Hiệu ứng từ các influencer hàng đầu",
  "pages.home.iBooming specializes in professionally matching your products with the most suitable celebrities or top influencer for cooperation, rapidly boosting your brand's visibility":
    "iBooming chuyên kết nối sản phẩm của bạn một cách chuyên nghiệp với những người nổi tiếng phù hợp nhất hoặc người có ảnh hưởng hàng đầu để hợp tác, nhanh chóng nâng cao khả năng hiển thị thương hiệu của bạn.",
  "pages.home.Influencer Matrix Promotion": "Ma trận quảng cáo cho influencer",
  "pages.home.Matrix Affiliate": "Ma trận liên kết",
  "pages.home.iBooming's Influencer Distribution System leverages a sophisticated network of internet celebrities and an efficient execution system, effectively amplifying product visibility and enhancing user engagement":
    "Hệ thống phân phối người ảnh hưởng của iBooming tận dụng mạng lưới phức tạp gồm những người nổi tiếng trên Internet và hệ thống thực thi hiệu quả, khuếch đại hiệu quả khả năng hiển thị sản phẩm và nâng cao mức độ tương tác của người dùng.",
  "pages.home.TikTok Advertising": "Quảng cáo của TikTok",
  "pages.home.Improve results": "Cải thiện kết quả",
  "pages.home.iBooming's advertising delivery service ensures a steady supply of materials through a vast pool of high-quality content resources, thereby increasing efficiency and maximizing the return on advertising investment":
    "Dịch vụ phân phối quảng cáo của iBooming đảm bảo nguồn cung cấp nguyên liệu ổn định thông qua nguồn tài nguyên nội dung chất lượng cao khổng lồ, từ đó tăng hiệu quả và tối đa hóa lợi tức đầu tư quảng cáo.",
  "pages.home.为您精准匹配智能货盘以及营销任务，通过iBooming您可以快速在TikTok提升账号动销率，提高收益":
    "iBooming kết nối chính xác những người có ảnh hưởng với các pallet Trí tuệ nhân tạo (AI) và các nhiệm vụ tiếp thị cho bạn, để người ảnh hưởng đó có thể nhanh chóng tăng tỷ lệ bán tài khoản của họ và nâng cao thu nhập trên TikTok thông qua iBooming!",
  "pages.home.加入网红社群": "Tham gia cộng đồng Influencer",
  "pages.home.已有": "Đã có",
  "pages.home.万+": "Ngàn +",
  "pages.home.动销网红加入我们": "nhà sáng tạo đang tham gia với chúng tôi",
  "pages.home.已入驻": "Thành lập",
  "pages.home.国家（印尼，泰国，越南，马来西亚，菲律宾）":
    "Quốc gia (Indonesia, Thailand, Vietnam, Malaysia, Philippines)",
  "pages.home.当前已开通5个国家，突破80万带货网红，成为东南亚最大的TikTok网红分销平台":
    "Hiện đang hoạt động tại 5 quốc gia, với hơn 800.000 doanh số sản phẩm liên kết, chúng tôi đã trở thành nền tảng liên kết người ảnh hưởng TikTok lớn nhất ở Đông Nam Á.",
  "pages.home.TOP": "Hàng đầu ",
  "pages.home.网红2": "Influencer",
  "pages.home.快加入我们": "tham gia ngay!",
  "pages.home.加入网红社群": "Tham gia cộng đồng Influencer",
  "pages.home.八十万网红池": "Tập hợp 800,000 Influencer",
  "pages.home.各类目网红满足您的商业需求":
    "Người có ảnh hưởng khác nhau trong mọi danh mục để đáp ứng nhu cầu kinh doanh của bạn",
  "pages.home.立即咨询我们": "Liên hệ ngay.",
  "pages.home.提交": "Nộp",
  "pages.home.#步骤1": "#Bước 1",
  "pages.home.#步骤2": "#Bước 2",
  "pages.home.#步骤3": "#Bước 3",
  "pages.home.#步骤4": "#Bước 4",
  "pages.home.注册填写您的品牌":
    "Đăng ký và điền thông tin thương hiệu của bạn",
  "pages.home.AI Booms 工具扫描您的店铺信息":
    "AI Booms quét thông tin cửa hàng của bạn",
  "pages.home.AI Booms 为您提供符合您企业的最佳推广方案":
    "AI Booms cung cấp cho bạn giải pháp quảng cáo tốt nhất cho doanh nghiệp của bạn",
  "pages.home.享受您的品牌流量": "Tận hưởng lưu lượng truy cập brand của bạn",
  "pages.home.平台新闻": "Thông tin về nền tảng",
  "pages.home.不要错过与iBooming的每一刻":
    "Đừng bỏ lỡ bất cứ khoảnh khắc nào với iBooming",
  "pages.home.继续阅读": "Đọc tiếp",
  "pages.home.您的企业名称": "Tên công ty của bạn",
  "pages.home.企业所在国家": "Vị trí công ty",
  "pages.home.选择类目": "Chọn danh mục",
  "pages.home.印度尼西亚": "Indonesia",
  "pages.home.泰国": "Thailand",
  "pages.home.越南": "Vietnam",
  "pages.home.马来西亚": "Malaysia",
  "pages.home.菲律宾": "Philippines",
  "pages.home.美妆": "Sắc đẹp và mỹ phẩm",
  "pages.home.电子": "Đồ điện tử",
  "pages.home.服饰": "May mặc",
  "pages.home.食品": "Đồ ăn",
  "pages.home.家居生活": "Thiết bị gia dụng",
  "pages.home.母婴": "mẹ và bé",
  "pages.home.个护和健康": "Sức khoẻ",
  "pages.home.恭喜您提交成功": "Chúc mừng bạn đã nộp thành công!",
  "pages.home.我们工作人员会尽快跟您取得联系.您还可以直接TAP处报名合作":
    "Nhân viên của chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất. Bạn cũng có thể trực tiếp đăng ký cộng tác TAP",
  "pages.home.前往 TikTok TAP报名": "Đi tới Đăng ký TikTok TAP",
  "pages.home.iBooming平台超大的网红活跃人数，可以快速让网红生产带货短视频以及直播带货，形成网红推品矩阵，让产品可以更大范围的覆盖到TikTok的消费用户，提升动销率，快速打爆产品。":
    "Nền tảng iBooming có nhiều người có ảnh hưởng tích cực, cho phép tạo nhanh các video ngắn và bán hàng trực tiếp. Nó tạo thành một ma trận sản phẩm được thúc đẩy bởi những người có ảnh hưởng, mở rộng phạm vi tiếp cận sản phẩm đến cơ sở người tiêu dùng của TikTok, thúc đẩy doanh số bán hàng và nhanh chóng phổ biến sản phẩm.",
  "pages.home.新品可以通过iBooming匹配小规模网红，以小微矩阵的形式快速获得市场多维度数据反馈，让品牌商可以更快的布局市场以及迭代产品。":
    "Các sản phẩm mới có thể được kết hợp với những người có ảnh hưởng quy mô nhỏ thông qua iBooming, nhanh chóng nhận được phản hồi đa chiều của thị trường, cho phép các đối tác thương hiệu tiếp thị và lặp lại sản phẩm nhanh hơn.",
  "pages.home.iBooming在东南亚各国都有本土的服务团队，提供免费咨询以及市场调研，更加本土化的基因可以规避品牌出海的水土不服。":
    "iBooming có các nhóm dịch vụ địa phương ở nhiều nước Đông Nam Á, cung cấp tư vấn và nghiên cứu thị trường miễn phí. Cách tiếp cận địa phương hóa hơn giúp giảm thiểu thách thức khi mở rộng thương hiệu ra quốc tế.",
  "pages.home.新": "Mới ",
  "pages.home.卖家": "Seller",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过80万TikTok网红组织建立快速响应系统":
    "iBooming xây dựng ma trận liên kết người ảnh hưởng TikTok với vô số trưởng nhóm, thiết lập hệ thống phản hồi nhanh với hơn 80.000 người có ảnh hưởng TikTok.",
  "pages.home.其他类型": "Loại khác ",
  "pages.home.首页": "Home",
  "pages.home.About iBooming": "Về iBooming",
  "pages.home.iBooming is a technology company specializing in developing artificial intelligence solutions for the digital content industry. Through AI, iBooming enables influencers and brands to optimize content strategies, expand reach, and increase monetization. With a commitment to innovation, iBooming aims to be a leader in AI-powered industry change, enabling influencers and brands to increase product visibility and achieve success in the global marketplace. For more information about iBooming, visit www.iboomingglobal.com":
    'iBooming là công ty công nghệ chuyên phát triển các giải pháp trí tuệ nhân tạo cho ngành nội dung số. Thông qua AI, iBooming cho phép những người có ảnh hưởng và thương hiệu tối ưu hóa chiến lược nội dung, mở rộng phạm vi tiếp cận và tăng khả năng kiếm tiền. Với cam kết đổi mới, iBooming đặt mục tiêu trở thành người dẫn đầu trong việc thay đổi ngành công nghiệp được hỗ trợ bởi AI, cho phép những người có ảnh hưởng và thương hiệu tăng khả năng hiển thị sản phẩm và đạt được thành công trên thị trường toàn cầu. Để biết thêm thông tin về iBooming, hãy truy cập www.iboomingglobal.com"',
  "pages.home.TikTok username": "Tên đăng nhập TikTok",
  "pages.home.更多高佣商品与带货补贴":
    "Nhiều sản phẩm có hoa hồng cao hơn và trợ cấp sản phẩm liên kết",
  "pages.home.粉丝数": "Số người theo dõi",
  "pages.home.拍摄一个视频的价格": "Giá cho một video",
  "pages.home.其他账号": "Tài khoản khác",
  "pages.home.提交成功": "Gửi thành công",
  "pages.home.印尼": "Indonesia",
  "pages.home.中国": "China",
  "pages.home.1,000,000 以上": "Trên 1,000,000",
  "pages.home.500,000 至 1,000,000": "500,000 đến 1,000,000",
  "pages.home.100,000 至 500,000": "100,000 đến 500,000",
  "pages.home.50,000 至 100,000": "50,000 đến 100,000",
  "pages.home.10,000 至 50,000": "10,000 đến 50,000",
  "pages.home.10,000 以下": "Dưới 10,000",
  "pages.home.在线品牌商": "Thương hiệu online",
  "pages.home.我是达人": "Tôi là influencer",
  "pages.home.我们的业务范围": "Phạm vi kinh doanh của chúng tôi",
  "pages.home.电子邮箱": "Email",
  "pages.home.关于我们": "About Us",
  "pages.home.iBooming与无数团长构建的TikTok网红分销矩阵 与超过100万TikTok达人组织建立快速响应系统":
    "iBooming xây dựng ma trận liên kết người ảnh hưởng TikTok với vô số trưởng nhóm, thiết lập hệ thống phản hồi nhanh với hơn 1 triệu người có ảnh hưởng TikTok.",
  "pages.home.抢占海外达人分销的红利":
    "Kiếm tiền thưởng từ việc bán hàng liên kết với Influencer ở nước ngoài",
  "pages.home.联系号码": "Số liên hệ",
  "pages.home.微信": "WeChat",
  "pages.home.合作商家": "Người bán cộng tác",
  "pages.home.立即加入网红社群": "Tham gia cộng đồng Influencer",
  "pages.home.合作媒体": "Đối tác truyền thông",
  "pages.home.网红加入": "Influencer tham gia",
  "pages.home.合作团长": "Trưởng nhóm hợp tác",
  "pages.home.扫码下载APP": "Quét để tải app",
  "pages.home.复制成功": "Copy thành công",
  "pages.home.护肤": "Chăm sóc da",
  "pages.home.彩妆": "Trang điểm",
  "pages.home.立即下载": "Tải ngay",
  "pages.home.下载iBooming APP, 参与活动领福利":
    "Tải ứng dụng iBooming, tham gia hoạt động và nhận lợi ích",
  "pages.home.平均日销量": "Doanh số trung bình mỗi ngày",
  "pages.home.1亿": "Triệu",
  "pages.home.200亿": "Triệu",
  "pages.home.Kojic Plankton 是一家专注于研发美白香皂的美容个人护理品牌。":
    "Đây là một thương hiệu chăm sóc sắc đẹp và cá nhân tập trung vào phát triển xà bông làm trắng.",
  "pages.home.该品牌过去依赖当地的经销商来销售产品，像是传统电商平台Tokopedia，Shopee或Lazada等其他渠道进行销售。":
    "Thương hiệu này từng dựa vào các nhà phân phối địa phương để bán sản phẩm của mình thông qua các kênh khác như: Tokopedia, Shopee hay Lazada.",
  "pages.home.然而，由于推广的数量以及力度不足，销售一直停滞不前。":
    "Tuy nhiên, doanh số bán hàng bị trì trệ do không đủ số lượng và mức độ khuyến mãi.",
  "pages.home.2022年10月，Kojic Plankton与iBooming合作，首次利用KOL（关键意见领袖）和KOC（关键意见消费者）在TikTok上推广其产品。":
    "Vào tháng 10 năm 2022, Kojic Plankton hợp tác với iBooming để lần đầu tiên sử dụng KOL (Người dẫn đầu về ý kiến) và KOC (Người tiêu dùng có ý kiến) để quảng cáo sản phẩm của mình trên TikTok.",
  "pages.home.iBooming的团队花费了两个月的时间寻找适合该产品的创作者，并测试了各种内容、定价策略和推广方式等等，以提高产品销售。":
    "iBooming đã dành hai tháng để tìm kiếm những người sáng tạo phù hợp với sản phẩm và thử nghiệm nhiều nội dung, chiến lược giá, phương pháp quảng cáo, v.v. để tăng doanh số bán sản phẩm",
  "pages.home.到12月底，iBooming的团队成功将该产品推动到东南亚TikTok销售排行榜的前列，在仅仅1天内就获得了超过70,000个订单，对比以往的销量提高了百分比的成绩。":
    'Đến cuối tháng 12, đội ngũ của iBooming đã thành công đẩy sản phẩm lên đầu bảng xếp hạng doanh số TikTok tại Đông Nam Á, nhận được hơn 70.000 đơn đặt hàng chỉ trong một ngày, tăng phần trăm so với doanh số trước đó.": " ",',
  "pages.home.MeToo 是一家专注于研发并生产美容个人护理产品的国际品牌。":
    "MeToo là thương hiệu quốc tế tập trung vào lĩnh vực chăm sóc sắc đẹp cá nhân.",
  "pages.home.在2022年初，他们推出了MeToo漱口水，并与iBooming合作。":
    "MeToo ra mắt nước súc miệng hợp tác với iBooming vào đầu năm 2022.",
  "pages.home.在两个月的时间里，iBooming精准地将该产品分配给与MeToo品牌价值观相符的内容创作者":
    "iBooming phân bổ chính xác sản phẩm cho người sáng tạo nội dung phù hợp với giá trị thương hiệu của MeToo trong vòng 2 tháng.",
  "pages.home.除了在TikTok上的MeToo Smile官方活动，品牌商也斥巨资邀请了一位当地备受瞩目的当红明星作为该产品的品牌大使，以及各大新闻媒体的报道支持，促成了MeToo在TikTok上迅速走红。":
    "MeToo đã đầu tư đáng kể vào việc mời những người nổi tiếng địa phương làm đại sứ của họ, bên cạnh sự kiện MeToo Smile trên TikTok, thu hút được sự ủng hộ từ các phương tiện truyền thông lớn và khiến nó nhanh chóng trở nên phổ biến trên TikTok.",
  "pages.home.在Metoo漱口水上市仅三天内，根据数据显示，每日有超过上千名的网红通过发布短视频或直播带货的方式销售产品，快速推动了商品销量，平均日销量超过了40,000瓶。":
    "Sản phẩm nhanh chóng đạt được hơn 40000 doanh số hàng ngày với hàng nghìn người có ảnh hưởng quảng bá sản phẩm thông qua liên kết video và phiên trực tiếp trong vòng 3 ngày kể từ khi ra mắt Nước súc miệng MeToo.",
  "pages.home.Feali 是一家专注于研发与生产化妆品的国际品牌。在2023年2月，Feali推出了一款独特的护肤产品，名为 FEALI 5x Pro-Xylane Goat Milk Anti-Aging and Moisturizer Cream。":
    'Feali là thương hiệu quốc tế tập trung phát triển mỹ phẩm. Họ đã cho ra mắt sản phẩm chăm sóc da độc đáo mang tên "Kem dưỡng ẩm và chống lão hóa sữa dê FEALI 5x Pro-Xylane" vào tháng 2 năm 2023.',
  "pages.home.该产品使用了市场上罕见的天然成分，当涂抹于皮肤时会产生白色气泡，使其在视觉上也非常吸引人。":
    "Sản phẩm chứa các thành phần tự nhiên quý hiếm, tạo bọt trắng khi thoa lên da mang lại hiệu quả hấp dẫn.",
  "pages.home.iBooming用了两周的时间，根据后台数据智能匹配了与该产品标签相符的带货网红，并快速积极地完成了产品的市场试错，为接下来的大型推广扎稳了根基。，并有效地推广了FEALI面霜。":
    "iBooming kết nối thông minh những người có ảnh hưởng với nhãn sản phẩm trong vòng 2 tháng, tích cực tiến hành thử nghiệm thị trường và thiết lập nền tảng vững chắc giúp thúc đẩy hiệu quả việc quảng bá kem FEALI.",
  "pages.home.MORE": "Tạo ",
  "pages.home.CREATE": "thêm",
  "pages.home.MORE2": "nhận nhiều ",
  "pages.home.BENEFIT": "hơn",
  "pages.home.业务所在国家": "Quốc gia kinh doanh",
  "pages.home.所在国家": "Quốc Gia",
  "pages.home.其他账号（选填）": "Tài khoản khác.",
  "pages.home.总销售额(美元)": "Tổng doanh thu(USD)",
  "pages.home.仅仅一个月时间，Feali就成功提升了销量并取得了令人瞩目的成果，充分诠释了有针对性的营销策略和制作创意内容所带来的成效是无可比拟的。":
    "Chỉ trong vòng 1 tháng, Feali đã thành trong việc thúc đẩy doanh số và đạt được những kết quả đáng kể, cho thấy được sự hiệu quả vượt bậc của chiến lượt tiếp thị đối tượng và hoạt động sáng tạo nội dung.",
  "pages.home.企业微信": "WeCom",
  "pages.home.前往TikTok TAP报名": "Vào Tiktok TAP để đăng ký",

  "pages.mcn.获得": "nhận",
  "pages.mcn.更多": "nhiều hơn",
  "pages.mcn.好处": "lợi ích ",
  "pages.mcn.加入正确MCN，你可获得":
    "chọn đúng mcn để nhận quyền lợi riêng biệt",
  "pages.mcn.官方流量扶持": "hỗ trợ lượt truy cập chính thức",
  "pages.mcn.官方每个月的佣金补贴":
    "hỗ trợ hoa hồng và các  trợ cấp hằng tháng",
  "pages.mcn.享有直播特价福利": "đặc quyền livestream",
  "pages.mcn.短视频/直播投流补贴": "video/live hỗ trợ khuyến mãi",
  // TODO: 翻译给了要改
  "pages.mcn.立即": "chọn ",
  "pages.mcn.选择": " ngay ",
  "pages.mcn.获得官方政策": "để truy cập điều khoản chính thức",
  "pages.mcn.我们": "chúng tôi đề xuất",
  "pages.mcn.零抽佣，品牌佣金100%给创作者":
    "hoa hồng không giới hạn, 100% hoa hồng từ brand sẽ được gửi cho creator",
  "pages.mcn.无限制，自由选择带货":
    "Không giới hạn các sản phẩm bạn có thể đăng kí affiliate",
  "pages.mcn.专业导师培训TikTok最新功能":
    "Đào tạo chuyên nghiệp về các tính năng mới nhất của tiktok",
  "pages.mcn.官方限时加入": "Đăng kí có giới hạn thời gian",
  "pages.mcn.扫码打开链接填写信息":
    "quét mã, mở link và điền thông tin của bạn",
  "pages.mcn.审核通过结果请查收您的TikTok站内信":
    "kiểm tra tiktok chat để thấy kết quả ",
  "pages.mcn.步骤1": "bước 1",
  "pages.mcn.填写信息注册": "điền thông tin đăng kí",
  "pages.mcn.填写信息申请加入": "điền thông tin để tham gia",
  "pages.mcn.TikTok 账号": "Tài khoản Tiktok",
  "pages.mcn.粉丝数": "Người theo dõi",
  "pages.mcn.选择类目": "Sự lựa chọn ngành hàng",
  "pages.mcn.拍摄一个视频的价格": "Giá để tạo một video",
  "pages.mcn.立即申请": "Áp dụng ngay",
  "pages.mcn.步骤2": "Bước 2",
  "pages.mcn.查看审核结果": "kiểm tra kết quả đánh giá",
  "pages.mcn.打开TikTok站内信查看审核结果": "Mở tiktok chat",
  "pages.mcn.如果通过，您会收到来自": "Nếu thông qua, hãy đợi lời mời từ ",
  "pages.mcn.的邀约": " trong box chat",
  "pages.mcn.步骤3": "Bước 3",
  "pages.mcn.点击同意": "Nhấp Đồng ý",
  "pages.mcn.点击同意，恭喜您加入成功": "Nhấn đồng ý và sự chúc mừng tham gia",
  "pages.mcn.提交申请成功": "Đăng ký thành công",
  "pages.mcn.一旦审核通过，我们将给您发送邀约，请您时刻留意TikTok站内信":
    "Khi đã được duyệt, chúng tôi sẽ gửi lời mời, kiểm tra tiktok chat của bạn nhé",
  "pages.mcn.好的": "OK",
  "pages.mcn.提交失败，请再尝试一次": "Đăng ký thất bại, thử lại lần nữa",
  "pages.mcn.已申请": "Đăng kí thành công",
  "pages.mcn.填写信息加入，审核通过结果请查收您的TikTok站内信":
    "Hãy kiểm tra hộp thư Tiktok của bạn để xem kết quả. Điền thông tin để đăng ký.",
  "pages.mcn.填写信息申请": "Điền thông tin để tham gia.",
  "pages.home.AI Matching": "Kết nối AI",
  "pages.home.Matrix Affiliate": "Ma trận liên kết",
  "pages.home.Keep Engaged": "Giữ tương tác",
  "pages.home.Influencer Mega Campaign": "Giữ tương tác",
  "pages.home.其他": "Khác",
  "pages.mcn.Get More": "",
  "pages.mcn.Traffic": "",
  "pages.mcn.Boost": "",
  "pages.商家入驻.iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara":
    "iBooming sử dụng trí tuệ thông minh (AI) để kết nối nhãn hàng với mạng lưới nhà sáng tạo quy mô lớn tại Indonesia thậm chí là Đông Nam Á",
  "pages.商家入驻.Influencer": "Influencer",
  "pages.商家入驻.Product": "Nhãn hàng",
  "pages.商家入驻.Brand": "Sản phẩm",
  "pages.商家入驻.Matchmaking Brand dan Influencer dengan AI Tools":
    "Sử dụng công cụ AI để kết hợp Influencer và Nhãn hàng với nhau",
  "pages.商家入驻.Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia":
    "Mạng xã hội của cộng đồng Influencer tại Indonesia và Đông Nam Á",
  "pages.商家入驻.Minimalisir Biaya Operasional dan Sumber Daya Manusia":
    "Làm giảm chi phí vận hành và nhân lực",
  "pages.商家入驻.Maksimalkan Trafik Persentase Penjualan Produk":
    "Tối đa hóa tỷ lệ lưu lượng truy cập sản phẩm",
  "pages.商家入驻.One Stop Solution Services dalam Satu Platform":
    "Cung cấp Dịch vụ một điểm trên một nền tảng",
  "pages.商家入驻.Mengapa MOUTH-TO-MOUTH MARKETING di iBooming": "Vì sao ",
  "pages.商家入驻.Peran Nano-Influencer Marketing":
    "Vai trò của nhóm Nano-Influencer",
  "pages.商家入驻.Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan":
    "Nhóm Nano-Influencer giúp gia tăng doanh số bán hàng và mở rộng quảng bá sản phẩm trong tương lai",
  "pages.商家入驻.More Trusted": "Đáng tin cậy hơn",
  "pages.商家入驻.Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth":
    "Nhóm Nano-Influencer xây dựng mối quan hệ khắng khít với người theo dõi thông qua sự tin tưởng bằng hình thức tiếp thị truyền miệng",
  "pages.商家入驻.More Profit": "Nhiều lợi ích hơn",
  "pages.商家入驻.Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat":
    "Các nhãn hàng được giới thiệu bởi nhóm Nano-Influencer có sự tăng trưởng về doanh số",
  "pages.商家入驻.More Massive": "Quy mô lớn hơn",
  "pages.商家入驻.Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer":
    "Nguồn Nano-Influencer không giới hạn do sự tiếp cận rộng rãi thông qua Cộng đồng Influencer",
  "pages.商家入驻.Hasil MOUTH-TO-MOUTH MARKETING di iBooming":
    "Kết quả của tiếp thị truyền miệng tại iBooming",
  "pages.商家入驻.SEGERA DAFTARKAN BRAND ANDA":
    "Đăng ký doanh nghiệp của bạn ngay",
  "pages.商家入驻.INFLUENCER": "Nhà sáng tạo",
  "pages.商家入驻.BRAND": "Nhãn hàng",
  "pages.商家入驻.Jordi": "Jordi",
  "pages.商家入驻.Willie Salim": "Willie Salim",
  "pages.商家入驻.Vilmei": "Vilmei",
  "pages.商家入驻.Awkarin": "Awkarin",
  "pages.商家入驻.Mouth to Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus":
    "Tiếp thị truyền miệng ở iBooming có thể thu hút hàng trăm nghìn khách hàng thông qua hàng nghìn nhà sáng tạo, tạo ra doanh số lên đến hàng tỷ đồng",
  "pages.商家入驻.Top Product Sales in Southeast Asia":
    "Sản phẩm bán chạy hàng đầu tại Đông Nam Á",
  "pages.商家入驻.Top Product Trending Skincare": "Sản phẩm Skincare xu hướng",
  "pages.商家入驻.Top Product Body Wash": "Sản phẩm Body Wash hàng đầu",
  "pages.商家入驻.Top Product Best Selling Skincare":
    "Sản phẩm Skincare bán chạy hàng đầu",
  "pages.商家入驻.100jt+": "100M+",
  "pages.商家入驻.Total Impresi": "Tổng số lần hiển thị",
  "pages.商家入驻.1,3M+": "1.3M+",
  "pages.商家入驻.Total Penjualan": "Tổng doanh số",
  "pages.商家入驻.TikTok Southeast Asia Hot Product List":
    "Danh sách sản phẩm bán chạy Tiktok Đông Nam Á",
  "pages.商家入驻.400jt+": "400M+",
  "pages.商家入驻.40.000+": "40K+",
  "pages.商家入驻.Penjualan Harian Rata-Rata": "Doanh số trung bình mỗi ngày",
  "pages.商家入驻.No. 1": "No.1",
  "pages.商家入驻.in Skincare Package": "trong Skincare Package",
  "pages.商家入驻.Penjualan Bulanan Rata-Rata": "Doanh số trung bình mỗi tháng",
  "pages.商家入驻.100.000+": "100K+",
  "pages.商家入驻.Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu":
    "Điền thông tin để gia nhập, iBooming sẽ tiến hành xét duyệt và liên hệ bạn ngay",
  "pages.商家入驻.Nama Perusahaan": "Tên công ty",
  "pages.商家入驻.申请入驻": "Gia nhập",
  "pages.商家入驻.Jenis Usaha": "Loại hình doanh nghiệp",
  "pages.商家入驻.Kategori Produk": "Category sản phẩm",
  "pages.商家入驻.Nomor Kontak Aktif": "Số điện thoại liên hệ",

  "pages.influencer.RATE CARD": "Bảng báo giá ",
  "pages.influencer.Updated": "Cập nhật",
  "pages.influencer.Category": "Phân loại",
  "pages.influencer.Followers": "Người theo dõi",
  "pages.influencer.Accounts Reached": "Số tài khoản đã đạt được ",
  "pages.influencer.Accounts Engaged": "Tài khoản đã tương tác",
  "pages.influencer.Gender Audience": "Đối tượng theo giới tính",
  "pages.influencer.Average Views": "Số lượt xem trung bình ",
  "pages.influencer.GMV": "Giá trị hàng hóa đã bán",
  "pages.influencer.Subscribers": "Người đăng ký",
  "pages.influencer.The above are all data for the past 30 days":
    "Các dữ liệu trên đều là dữ liệu trong vòng 30 ngày qua.",
  "pages.influencer.Short Video TikTok": "Video ngắn trên TikTok",
  "pages.influencer.Live Stream TikTok": "Phát trực tiếp trên TikTok",
  "pages.influencer.Reels Instagram": "Reels trên Instagram",
  "pages.influencer.Feed Instagram": "Bảng tin Instagram",
  "pages.influencer.Story Instagram": "Story trên Instagram",
  "pages.influencer.Short Video Youtube": "Video ngắn trên Youtube ",
  "pages.influencer.Video Youtube": "Video trên Tiktok ",
  "pages.influencer.Per Video or Per Live Stream Session":
    "Cho mỗi video hoặc mỗi phiên trực tiếp",
  "pages.influencer.Terms & Condition": "Điều khoản và Điều kiện",
  "pages.influencer.简介": "Thông tin cá nhân",
  "pages.influencer.已认证": "Đã xác minh",
  "pages.influencer.未认证": "Chưa xác minh",

  'pages.influencer.This Rate Card Generator feature is provided by iBooming Official (referred to as "we", "us", or "our") and by using our Rate Card Generator feature, you agree to provide accurate and up-to-date information and grant us a license to use it for generating the rate card. We make no warranties regarding the accuracy of the generated rate card and shall not be liable for any damages. If you have any questions, please contact us.':
    '"Rate Card Generator" là một tính năng được cung cấp bởi iBooming (gọi tắt là "chúng tôi"). Bằng cách sử dụng tính năng này, bạn đồng ý cung cấp thông tin chính xác và mới nhất, và cấp phép cho chúng tôi sử dụng thông tin đó để tạo ra Bảng báo giá. Chúng tôi không đảm bảo tính chính xác của Bàng báo giá được tạo ra và không chịu trách nhiệm đối với bất kỳ thiệt hại nào phát sinh. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với chúng tôi.',
};
