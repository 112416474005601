import React, { useEffect, useState } from "react";
let oldCountO = {
  oldAmount1: 0,
  oldAmount2: 0,
  oldAmount3: 0,
  timer1: null,
  timer2: null,
  timer3: null,
  oldAmount4: 0,
  oldAmount5: 0,
  oldAmount6: 0,
  timer4: null,
  timer5: null,
  timer6: null,
  oldAmount7: 0,
  oldAmount8: 0,
  oldAmount9: 0,
  timer7: null,
  timer8: null,
  timer9: null,
};
export default function CountDown(props) {
  const targetN = props.count;
  const type = props.type;
  const dvalue = props.dvalue || 356;
  const second = (props.second || 5) * 1000;
  const oldD = new Date().getTime();
  const [count, setCount] = useState(targetN < 1000 ? 5 : 186);
  useEffect(() => {
    const { scale } = props;
    console.log(scale);

    oldCountO[`oldAmount${type}`] = 0;
    if (oldCountO[`timer${type}`]) {
      clearInterval(oldCountO[`timer${type}`]);
    }
    if (oldCountO[`timer${type}`]) {
      clearInterval(oldCountO[`timer${type}`]);
    }
    oldCountO[`timer${type}`] = setInterval(() => {
      const oldAmount = oldCountO[`oldAmount${type}`];
      const nowD = new Date().getTime();
      // console.log(nowD - oldD, second, oldAmount, type);
      if (nowD - oldD > second) {
        const newAmount = Number(oldAmount) + dvalue;
        if (newAmount > targetN) {
          oldCountO[`oldAmount${type}`] = targetN;
          setCount(targetN);
          clearInterval(oldCountO[`timer${type}`]);
        } else {
          oldCountO[`oldAmount${type}`] = newAmount;
          setCount(newAmount);
        }
      }
    }, 30);
    return () => {
      if (oldCountO[`timer${type}`]) {
        clearInterval(oldCountO[`timer${type}`]);
      }
    };
  }, []);
  return <span>{count}</span>;
}
