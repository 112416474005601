import React from "react";
import Marquee from "react-fast-marquee";
const avatarFirstLine = [
  {
    name: "ilvi_sanh",
    fans: "4.8M",
  },
  {
    name: "rtsfya",
    fans: "1.2M",
  },
  {
    name: "ryanmanggala",
    fans: "1.8M",
  },
  {
    name: "Vilmeijuga",
    fans: "42.3M",
  },
  {
    name: "Williesalim",
    fans: "46.7M",
  },
  {
    name: "Ismethalatas",
    fans: "1.3M",
  },
  {
    name: "kamilaya93",
    fans: "635.8K",
  },
  {
    name: "kamilaya93",
    fans: "219.1K",
  },
  {
    name: "leevastoreofficial",
    fans: "311.9K",
  },
  {
    name: "nenty_garut_karawang",
    fans: "2.3M",
  },
];
const avatarSecondLine = [
  {
    name: "a_khamprathom",
    fans: "131.1K",
  },
  {
    name: "puchong1949",
    fans: "45.9K",
  },
  {
    name: "ikikkikkk",
    fans: "26.8K",
  },
  {
    name: "kidkat8558",
    fans: "154.8K",
  },
  {
    name: "james_pongsapak",
    fans: "259.3K",
  },
  {
    name: "doitfit",
    fans: "172.2K",
  },
  {
    name: "prapatsara.23",
    fans: "154.8K",
  },
  {
    name: "pure_taichifit",
    fans: "396.7K",
  },
  {
    name: "cookkai9936",
    fans: "189.6K",
  },
  {
    name: "gappo6",
    fans: "152.2K",
  },
];
const avatarThirdLine = [
  {
    name: "hiennguyen666666",
    fans: "10.9K",
  },
  {
    name: "thuychieuedit",
    fans: "35.4K",
  },
  {
    name: "kim.luyen_laplalaplanh",
    fans: "233.2K",
  },
  {
    name: "setdep.storereview",
    fans: "14.3K",
  },
  {
    name: "xxxibruak.202",
    fans: "175.7K",
  },
  {
    name: "tuanngocday",
    fans: "2.8M",
  },
  {
    name: "dqamii",
    fans: "728K",
  },
  {
    name: "dtbao.203",
    fans: "170.6K",
  },
  {
    name: "mehuonghuong",
    fans: "2.8M",
  },
  {
    name: "lnntah_",
    fans: "361.2K",
  },
];
export default function TopTalent() {
  return (
    <div className="top-talent">
      <Marquee>
        {avatarFirstLine.map((item, index) => (
          <div className="avatar" key={item.name}>
            <img
              src={`https://www.iboomingglobal.com/talent_avatar/fi${
                index + 1
              }.jpg?x-oss-process=image/resize,w_100/quality,q_90`}
              alt=""
            />
            <div>
              <p>{item.name}</p>
              <p>{item.fans}</p>
            </div>
          </div>
        ))}
      </Marquee>
      <Marquee direction="right">
        {avatarSecondLine.map((item, index) => (
          <div className="avatar" key={item.name}>
            <img
              src={`https://www.iboomingglobal.com/talent_avatar/se${
                index + 1
              }.png?x-oss-process=image/resize,w_100/quality,q_90`}
              alt=""
            />
            <div>
              <p>{item.name}</p>
              <p>{item.fans}</p>
            </div>
          </div>
        ))}
      </Marquee>
      <Marquee>
        {avatarThirdLine.map((item, index) => (
          <div className="avatar" key={item.name}>
            <img
              src={`https://www.iboomingglobal.com/talent_avatar/th${
                index + 1
              }.jpg?x-oss-process=image/resize,w_100/quality,q_90`}
              alt=""
            />
            <div>
              <p>{item.name}</p>
              <p>{item.fans}</p>
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
}
