import React, { useEffect } from "react";
import APPHeader from "../components/AppHeader";
import APPHome from "./Home";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { GoToAPP } from "../utils";
import APPFooter from "../components/AppFooter";
import { FormattedMessage } from "react-intl";
import APPBusiness from "./Business";
import APPTalent from "./Talent";
import APPNewsDetail from "./NewsDetail";
import APPNewsList from "./NewsList";
import APPBrand from "./Brand";
import APPCertifiedInfluencer from "./CertifiedInfluencer";
// import APPFlowScheme from "./Flow";
import logo from "../assest/logod.png";

export default function APPView() {
  const search = window.location.search || "";
  const country = localStorage.getItem("locale") || "en-us";
  const pathname = window?.location?.pathname;
  return (
    <div
      className={`app-bg${country !== "zh-cn" ? " app-en-class" : ""}`}
      // style={
      //   pathname.includes("business")
      //     ? {
      //         inset: 0,
      //         width: "100%",
      //         height: "100%",
      //         position: "fixed",
      //         overflow: "hidden",
      //       }
      //     : {}
      // }
    >
      <BrowserRouter>
        <APPHeader />
        <Routes>
          <Route index element={<APPHome />} />
          {/* <Route path="/flow" element={<APPFlowScheme />} /> */}
          <Route path="/certified/:id" element={<APPCertifiedInfluencer />} />
          <Route path="/newslist" element={<APPNewsList />} />
          <Route path="/news/detail/:id" element={<APPNewsDetail />} />
          <Route path="/brand/detail/:id" element={<APPBrand />} />
          <Route path="/business" element={<APPBusiness />} />
          <Route path="/talent" element={<APPTalent />} />
          <Route path="/home" element={<APPHome />} />
          <Route path="/*" celement={<APPHome />} />
        </Routes>
        {search.includes("locale") ? (
          <></>
        ) : (
          <div className="app-download">
            <img src={logo} alt="" />
            <p>
              <FormattedMessage
                id="pages.home.下载iBooming APP, 参与活动领福利"
                defaultMessage="下载iBooming APP, 参与活动领福利"
              />
            </p>
            <div className="btn" onClick={() => GoToAPP()}>
              <FormattedMessage
                id="pages.home.立即下载"
                defaultMessage="Download Now"
              />
            </div>
          </div>
        )}
        {/* {!pathname.includes("business") && <APPFooter />} */}
        {!pathname.includes("/certified") && <APPFooter />}
      </BrowserRouter>
    </div>
  );
}
