import React, { useEffect, useState } from "react";
import "../../utils/setRem";
import "./index.scss";
import { WOW } from "wowjs";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
import CountDown from "../../components/CountDown";
import country1 from "../../assest/country1.png";
import country2 from "../../assest/country2.png";
import country3 from "../../assest/country3.png";
import country4 from "../../assest/country4.png";
import country5 from "../../assest/country5.png";
import drop from "../../assest/drop.png";
import logo from "../../assest/title.png";
import us1 from "../../assest/us2.png";
import us2 from "../../assest/us1.png";
import us3 from "../../assest/us5.png";
import us4 from "../../assest/us3.png";
import us5 from "../../assest/us4.png";
import tm1 from "../../assest/tm1.png";
import tm2 from "../../assest/tm2.png";
import tm3 from "../../assest/tm3.png";
import tm4 from "../../assest/tm4.png";
import tm5 from "../../assest/tm5.png";
import tm6 from "../../assest/tm6.png";
import arrow from "../../assest/arrow.png";
import AppClassicCases from "./ClassicCases";
import new1 from "../../assest/new1.6.png";
import new3 from "../../assest/new1.4.png";
import new5 from "../../assest/new1.5.png";
import { getOSSImgUrl } from "../../utils";
let timer;
export default function APPHome() {
  const locale = localStorage.getItem("locale") || "en-us";
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getList();
    if (timer) {
      clearTimeout(timer);
    }
    const hash = window.location.hash;
    const text = hash.substr(1);
    // const aboutTop = document
    //   .getElementById("about")
    //   .getBoundingClientRect().top;
    // const brandOtherTop = document
    //   .getElementById("brands")
    //   .getBoundingClientRect().top;
    timer = setTimeout(() => {
      const aboutTop = document.getElementById("about").offsetTop;
      const brandOtherTop = document.getElementById("brands").offsetTop;
      const topElem = document.getElementById("about");
      console.log(topElem.getBoundingClientRect(), aboutTop, 999);
      const scrollValue = {
        about: aboutTop - 100,
        brands: brandOtherTop - 20,
      };
      console.log(scrollValue);

      window.scrollTo(0, hash ? scrollValue[text] : 0);
      clearTimeout(timer);
    }, 1000);
    const wow = new (window.WOW || WOW)({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  }, []);
  const getList = () => {
    const url = `${config.newsUrl}/bg/api/resource/list`;
    const param = {
      page: 1,
      limit: 3,
      status: 1,
      types: 3,
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        const data = res?.data?.data || {};
        setList(data.list || []);
      })
      .catch((err) => {
        console.log("onError", err);
      });
  };
  return (
    <div className="app-home">
      <div className="banner">
        <h3 className="title">
          <FormattedMessage id="pages.home.东南亚" defaultMessage="东南亚" />
          {["zh-cn", "en-us"].includes(locale) ? "" : <br />}
          <span>
            <FormattedMessage id="pages.home.最大" defaultMessage="最大" />
          </span>
        </h3>
        <h4 className="min-title ">
          <FormattedMessage
            id="pages.home.海外网红矩阵式分销平台"
            defaultMessage="海外网红矩阵式分销平台"
          />
        </h4>
        <p className="text">
          <FormattedMessage
            id="pages.home.iBooming是一家以技术为核心驱动的数字化营销平台"
            defaultMessage="iBooming是一家以技术为核心驱动的数字化营销平台；"
          />
          <FormattedMessage
            id="pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长"
            defaultMessage="让对的人带对的货，让对的货找对的人；降本增效的智能化驱动分销业务指数级增长"
          />
        </p>
        <div
          className={`business ${locale.includes("id") ? "id-business" : ""}`}
        >
          <a className="store-btn store-active" href="/business">
            <FormattedMessage
              id="pages.home.我是商家"
              defaultMessage="我是商家"
            />
          </a>
          <a className="store-btn" href="/talent">
            <FormattedMessage
              id="pages.home.我是网红"
              defaultMessage="我是网红"
            />
          </a>
        </div>
        {/* 动销数据 */}
        <div
          className="information wow animated fadeInUp"
          data-wow-delay="0.6s"
        >
          <p>
            <FormattedMessage
              id="pages.home.网红加入"
              defaultMessage="网红加入"
            />
          </p>
          <h3>
            <CountDown count={907357} dvalue={16688} second={1} type={1} />
          </h3>
          <p>
            <FormattedMessage
              id="pages.home.合作团长"
              defaultMessage="合作团长"
            />
          </p>
          <h3>
            <CountDown count={11527} dvalue={318} second={1.5} type={2} />
          </h3>
          <p>
            <FormattedMessage
              id="pages.home.合作商家"
              defaultMessage="合作商家"
            />
          </p>
          <h3>
            <CountDown count={2521} dvalue={58} second={1.5} type={3} />
          </h3>
        </div>
        {/* 我的业务范围 */}
        <div className="range">
          <h3>
            <FormattedMessage id="pages.home.我们的" defaultMessage="我们的" />
            <span>
              <FormattedMessage
                id="pages.home.业务范围"
                defaultMessage="业务范围"
              />
            </span>
          </h3>
          <div className="range-bg">
            <ul>
              <li>
                <img src={country1} alt="Indonesia" />
                <span>
                  <FormattedMessage
                    id="pages.home.印度尼西亚"
                    defaultMessage="印度尼西亚"
                  />
                </span>
              </li>
              <li
                style={
                  locale === "zh-cn"
                    ? {
                        padding: "0 0.4rem 0 0.1rem",
                      }
                    : locale === "th-th"
                    ? {
                        padding: "0 0.28rem",
                      }
                    : {}
                }
              >
                <img src={country2} alt="Thailand" />
                <span>
                  <FormattedMessage
                    id="pages.home.泰国"
                    defaultMessage="泰国"
                  />
                </span>
              </li>
              <li style={{ marginRight: 0 }}>
                <img src={country3} alt="Vietnam" />
                <span>
                  <FormattedMessage
                    id="pages.home.越南"
                    defaultMessage="越南"
                  />
                </span>
              </li>
            </ul>
            <ul
              style={{
                paddingLeft: "1rem",
              }}
            >
              <li
                style={
                  locale === "zh-cn" ? { padding: "0 0.2rem 0 0.15rem" } : {}
                }
              >
                <img src={country4} alt="Malaysia" />
                <span>
                  <FormattedMessage
                    id="pages.home.马来西亚"
                    defaultMessage="马来西亚"
                  />
                </span>
              </li>
              <li style={{ border: "none" }}>
                <img src={country5} alt="Philippines" />
                <span>
                  <FormattedMessage
                    id="pages.home.菲律宾"
                    defaultMessage="菲律宾"
                  />
                </span>
              </li>
            </ul>
            <div className="map wow fadeInUp" data-wow-duration="2s">
              <img
                className="drop1 wow animated mapAnimated"
                data-wow-duration="3s"
                src={drop}
                alt=""
              />
              <img
                className="drop2 wow animated mapAnimated"
                data-wow-delay="1.3s"
                data-wow-duration="2s"
                src={drop}
                alt=""
              />
              <img
                className="drop3 wow animated mapAnimated"
                data-wow-delay="1.2s"
                data-wow-duration="2s"
                src={drop}
                alt=""
              />
              <img
                className="drop4 wow animated mapAnimated"
                data-wow-delay="1s"
                data-wow-duration="2s"
                src={drop}
                alt=""
              />
              <img
                className="drop5 wow animated mapAnimated"
                data-wow-delay="0.8s"
                data-wow-duration="2s"
                src={drop}
                alt=""
              />
            </div>
            <div id="aboutMap"></div>
          </div>
        </div>
      </div>

      <div id="about"></div>
      <div className="app-warp-content">
        {/* 关于我们 */}
        <div className="app-introduce">
          <div className="desc wow fadeInUp" data-wow-duration="1s">
            <img src={logo} alt="iBooming" />
            <div
              className="wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.5s"
            >
              <p>
                <FormattedMessage
                  id="pages.home.iBooming是一家以技术为核心驱动的数字化营销平台。"
                  defaultMessage="iBooming是一家以技术为核心驱动的数字化营销平台。"
                />
              </p>
              <p>
                <FormattedMessage
                  id="pages.home.通过智能算法，为商家精准匹配大量营销带货网红，快速在TikTok以网红矩阵销售产品以及推广品牌；为网红精准匹配智能货盘以及营销任务，网红通过iBooming可以快速在TikTok提升账号动销率，提高收益。"
                  defaultMessage="通过智能算法，为商家精准匹配大量营销带货网红，快速在TikTok以网红矩阵销售产品以及推广品牌；为网红精准匹配智能货盘以及营销任务，网红通过iBooming可以快速在TikTok提升账号动销率，提高收益。"
                />
              </p>
              <p>
                <FormattedMessage
                  id="pages.home.让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长。"
                  defaultMessage="让对的人带对的货，让对的货找对的人，降本增效的智能化驱动分销业务指数级增长。"
                />
              </p>
            </div>
          </div>
          <div className="tik-warp">
            <img
              className="img1 wow animate__fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
              src={us1}
              alt=""
            />
            <img
              className="img2 wow animate__fadeInUp"
              data-wow-duration="0.5s"
              src={us2}
              alt=""
            />
            <img
              className="img3 wow animate__fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="0.6s"
              src={us3}
              alt=""
            />
            <img
              className="img4 wow animate__fadeInUp"
              data-wow-duration="0.4s"
              data-wow-delay="0.8s"
              src={us4}
              alt=""
            />
            <img
              className="img5 wow animate__fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="1s"
              src={us5}
              alt=""
            />
          </div>
        </div>
        {/* 团长招募 */}
        <div className="app-introduce">
          <div className="desc1 wow fadeInUp" data-wow-duration="1s">
            <h3>
              <FormattedMessage
                id="pages.home.团长招募"
                defaultMessage="团长招募"
              />
            </h3>
            <div
              className="wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.5s"
            >
              {/* {locale === "th-th" ? (
                <p>
                  เรามองหาผู้นำครีเอเตอร์ที่มีความสามารถโดดเด่นอยู่เสมอ
                  <br />
                  การทำงานร่วมกับiBooming จะช่วยให้ทีมของคุณ
                  <br />
                  สร้างรายได้ที่สูงขึ้นจากการขายสินค้าให้กับคุณได้อย่างรวดเร็ว
                  <br />
                  และช่วยจัดการทีมของคุณผ่านระบบ AI ที่ชาญฉลาดได้อย่างง่ายดาย
                  <br />
                  หากคุณมีเหล่าครีเอเตอร์ที่ต้องการสร้างอาชีพที่มั่นคง
                  <br />
                  ในด้านการตลาดและความบันเทิงอยู่ในมือ มาร่วมสร้างอนาคตที่ดีกว่า
                  <br />
                  กับผู้ที่มีความเชี่ยวชาญในโลกตลอดออนไลน์
                  พวกเรารอต้อนรับคุณอยู่นะ!
                  <br />
                </p>
              ) : ( */}
              <p>
                <FormattedMessage
                  id="pages.home.我们一直在寻找优秀的网红领导人，在iBooming您的团队可以快速为您创造更高的带货收益，并能轻松的智能化管理您的网红团队；如果您有网红资源，想在市场营销和娱乐领域创造一个稳定的事业，与全球互联网人才一起共创美好未来，我们将非常欢迎您的加入!"
                  defaultMessage="我们一直在寻找优秀的网红领导人，在iBooming您的团队可以快速为您创造更高的带货收益，并能轻松的智能化管理您的网红团队；如果您有网红资源，想在市场营销和娱乐领域创造一个稳定的事业，与全球互联网人才一起共创美好未来，我们将非常欢迎您的加入!"
                />
              </p>
              {/* )} */}
            </div>
          </div>
          <div className="intr-imgs">
            <img
              className="tm1 wow fadeInLeft"
              data-wow-duration="0.5s"
              src={tm1}
              alt=""
            />
            <img
              className="tm2 wow fadeInLeft"
              data-wow-delay="0.2s"
              data-wow-duration="0.5s"
              src={tm2}
              alt=""
            />
            <img
              className="tm3 wow fadeInLeft"
              data-wow-delay="0.4s"
              data-wow-duration="0.5s"
              src={tm3}
              alt=""
            />
            <img
              className="tm4 wow fadeInLeft"
              data-wow-delay="0.6s"
              data-wow-duration="0.5s"
              src={tm4}
              alt=""
            />
            <img
              className="tm5 wow fadeInLeft"
              data-wow-delay="0.6s"
              data-wow-duration="0.5s"
              src={tm5}
              alt=""
            />
            <img
              className="tm6 wow fadeInLeft"
              data-wow-delay="0.7s"
              data-wow-duration="0.5s"
              src={tm6}
              alt=""
            />
          </div>
        </div>
        {/* 经典案例 */}
        <div id="brands"></div>
        <AppClassicCases />
        {/* 最新动态 */}
        <div className="app-brand">
          <h3 className="app-main-title">
            <FormattedMessage
              id="pages.home.最新动态"
              defaultMessage="最新动态"
            />
          </h3>
          <div className="brand-content">
            <div className="scroll-warp">
              {list.map((item) => (
                <div
                  key={item.id}
                  className="card"
                  onClick={() => navigate(`/news/detail/${item.id}`)}
                >
                  <div className="img-warp">
                    <img src={getOSSImgUrl(item.showImg, 372, 100)} alt="" />
                  </div>
                  <h3>{item.showTitle}</h3>
                  <p>{item.showContent}</p>
                  <img className="img2" src={arrow} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
