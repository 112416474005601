/**
 * 商家入驻
 */
import React from "react";
import "../../utils/setRem";
import "./index.scss";
import img1 from "../images/settle1.png";
import mcn4 from "../images/mcn4.png";
import country1 from "../../assest/country1.png";
import country2 from "../../assest/country2.png";
import country3 from "../../assest/country3.png";
import country4 from "../../assest/country4.png";
import country5 from "../../assest/country5.png";
import tt1 from "../images/settimg1.png";
import tt2 from "../images/settimg2.png";
import tt3 from "../images/settimg3.png";
import tt4 from "../images/settimg4.png";
import settimg14 from "../images/settimg14.png";
import br1 from "../images/settimg5.png";
import br2 from "../images/settimg6.png";
import br3 from "../images/settimg7.png";
import br4 from "../images/settimg8.png";
import brand1 from "../images/settimg9.png";
import brand2 from "../images/settimg10.png";
import brand3 from "../images/settimg11.png";
import img12 from "../images/settimg12.png";
import img13 from "../images/settimg13.png";
import mright from "../images/mright.png";
import mleft from "../images/mleft.png";
import setback from "../images/setback.png";
import arrow from "../images/mcnarrow.png";
import sr2 from "../../assest/sr2.png";
import sr3 from "../../assest/sr3.png";
import sr4 from "../../assest/sr4.png";
import axios from "axios";
import fire from "../images/mcn5.png";
import { FormattedMessage } from "react-intl";
import JoinUS from "../Business/JoinUS";
import CountDown from "../../components/CountDown";
export default function BusinessSettle() {
  const locale = localStorage.getItem("locale") || "en-us";
  const onScroll = () => {
    const scrollTop = document.getElementById("forminfo").offsetTop;
    window.scrollTo(0, scrollTop);
  };
  const onBack = () => {
    const newData = {
      actionType: 3,
    };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(newData));
    } else {
      window.history.go(-1);
    }
  };
  return (
    <div className="app-settle">
      <div className="app-header-bar">
        <div>
          <img onClick={onBack} src={setback} alt="" />
        </div>
      </div>
      <div style={{ height: "1.4rem" }}></div>
      <a
        // onClick={onScroll}
        href="#forminfo"
        className="anchor-point"
      >
        <img src={img13} alt="" />
        <p>
          <FormattedMessage
            id="pages.商家入驻.申请入驻"
            defaultMessage="申请入驻"
          />
        </p>
      </a>
      <div className="banner">
        <img
          className="img1"
          src={img1}
          alt="iBooming TikTok Affiliate Partner"
        />
        <h3 className="max-title">
          <FormattedMessage
            id="pages.商家入驻.BOOMING"
            defaultMessage="BOOMING"
          />
        </h3>
        {locale.includes("vn") ? (
          <>
            <h3 className="title2" style={{ marginBottom: "0.36rem" }}>
              BÙNG NỔ DOANH SỐ NHÃN HÀNG HÀNG TIẾP THỊ TRUYỀN MIỆNG
            </h3>
          </>
        ) : (
          <>
            <h3 className="title1">
              <FormattedMessage
                id="pages.商家入驻.YOUR BRAND WITH"
                defaultMessage="YOUR BRAND WITH"
              />
            </h3>
            <h3 className="title2">
              <FormattedMessage
                id="pages.商家入驻.MOUTH-TO-MOUTH"
                defaultMessage="MOUTH-TO-MOUTH"
              />
            </h3>
          </>
        )}
      </div>
      {!locale.includes("vn") && (
        <h3 className="title3">
          <FormattedMessage
            id="pages.商家入驻.MARKETING"
            defaultMessage="MARKETING"
          />
        </h3>
      )}
      <div className="introduce1">
        {/* TODO:翻译了要分开下 */}
        <p className="text1">
          <FormattedMessage
            id="pages.商家入驻.iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara"
            defaultMessage="iBooming dengan Kecerdasan Buatan (AI) akan Bantu Hubungkan Brand dengan Komunitas Influencer secara Masif dan Tepat di Seluruh Indonesia Bahkan Asia Tenggara"
          />
          <img className="horn" src={mcn4} alt="" />
        </p>
      </div>
      <div className="range-bg">
        <ul>
          <li>
            <img src={country1} alt="Indonesia" />
            <span>
              <FormattedMessage
                id="pages.home.印度尼西亚"
                defaultMessage="印度尼西亚"
              />
            </span>
          </li>
          <li
            style={
              locale === "zh-cn"
                ? {
                    padding: "0 0.4rem 0 0.1rem",
                  }
                : locale === "th-th"
                ? {
                    padding: "0 0.28rem",
                  }
                : {}
            }
          >
            <img src={country2} alt="Thailand" />
            <span>
              <FormattedMessage id="pages.home.泰国" defaultMessage="泰国" />
            </span>
          </li>
          <li style={{ marginRight: 0 }}>
            <img src={country3} alt="Vietnam" />
            <span>
              <FormattedMessage id="pages.home.越南" defaultMessage="越南" />
            </span>
          </li>
        </ul>
        <ul
          style={{
            paddingLeft: "1rem",
          }}
        >
          <li
            style={locale === "zh-cn" ? { padding: "0 0.2rem 0 0.15rem" } : {}}
          >
            <img src={country4} alt="Malaysia" />
            <span>
              <FormattedMessage
                id="pages.home.马来西亚"
                defaultMessage="马来西亚"
              />
            </span>
          </li>
          <li style={{ border: "none" }}>
            <img src={country5} alt="Philippines" />
            <span>
              <FormattedMessage
                id="pages.home.菲律宾"
                defaultMessage="菲律宾"
              />
            </span>
          </li>
        </ul>
      </div>
      <div className="information">
        <div style={{ width: "37.5%" }}>
          <div className="border"></div>
          <p>
            <CountDown count={907357} dvalue={16688} second={1} type={7} />
          </p>
          <h3>
            <FormattedMessage
              id="pages.商家入驻.Influencer"
              defaultMessage="Influencer"
            />
          </h3>
        </div>
        <div style={{ width: "32.5%" }}>
          <div className="border"></div>
          <p>
            <CountDown count={11527} dvalue={318} second={1.5} type={8} />
          </p>
          <h3>
            <FormattedMessage
              id="pages.商家入驻.Product"
              defaultMessage="Product"
            />
          </h3>
        </div>
        <div style={{ width: "30%" }}>
          <p>
            <CountDown count={2521} dvalue={58} second={1.5} type={9} />
          </p>
          <h3>
            <FormattedMessage
              id="pages.商家入驻.Brand"
              defaultMessage="Brand"
            />
          </h3>
        </div>
      </div>
      <h3 className="settle-title">
        <img
          style={{
            marginLeft: 0,
          }}
          src={mleft}
          alt=""
        />
        <p>
          <FormattedMessage
            id="pages.商家入驻.Mengapa MOUTH-TO-MOUTH MARKETING di iBooming"
            defaultMessage="Mengapa MOUTH-TO-MOUTH MARKETING di iBooming"
          />
        </p>
        <img
          style={{
            marginRight: 0,
          }}
          src={mright}
          alt=""
        />
      </h3>
      <div className="introduce2">
        <img className="horn" src={mcn4} alt="" />
        <div
          className="text"
          style={{
            width: "4.82rem",
            marginTop: 0,
          }}
        >
          <img className="img1" src={fire} alt="" />
          <p>
            <FormattedMessage
              id="pages.商家入驻.Matchmaking Brand dan Influencer dengan AI Tools"
              defaultMessage="Matchmaking Brand dan Influencer dengan AI Tools"
            />
          </p>
        </div>
        <div className="text">
          <img className="img1" src={fire} alt="" />
          <p>
            <FormattedMessage
              id="pages.商家入驻.Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia"
              defaultMessage="Jejaring Sosial Komunitas Influencer Seluruh Indonesia Bahkan Asia"
            />
          </p>
        </div>
        <div className="text">
          <img className="img1" src={fire} alt="" />
          <p>
            <FormattedMessage
              id="pages.商家入驻.Minimalisir Biaya Operasional dan Sumber Daya Manusia"
              defaultMessage="Minimalisir Biaya Operasional dan Sumber Daya Manusia"
            />
          </p>
        </div>
        <div className="text">
          <img className="img1" src={fire} alt="" />
          <p>
            <FormattedMessage
              id="pages.商家入驻.Maksimalkan Trafik Persentase Penjualan Produk"
              defaultMessage="Maksimalkan Trafik Persentase Penjualan Produk"
            />
          </p>
        </div>
        <div className="text">
          <img className="img1" src={fire} alt="" />
          <p>
            <FormattedMessage
              id="pages.商家入驻.One Stop Solution Services dalam Satu Platform"
              defaultMessage="One Stop Solution Services dalam Satu Platform"
            />
          </p>
        </div>
      </div>
      <img className="arrow-down" src={arrow} alt="" />
      <div className="introduce1">
        <h3 style={{ width: "5.02rem" }}>
          <FormattedMessage
            id="pages.商家入驻.Peran Nano-Influencer Marketing"
            defaultMessage="Peran Nano-Influencer Marketing"
          />
        </h3>
        <p className="text1">
          <FormattedMessage
            id="pages.商家入驻.Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan"
            defaultMessage="Masa depan sangat membutuhkan Nano-Influencer untuk promosi produk dan peningkatan penjualan"
          />
          <img className="horn2" src={settimg14} alt="" />
        </p>
      </div>
      <div className="advantage">
        <p className="number">92%</p>
        <div>
          <h3>
            <FormattedMessage
              id="pages.商家入驻.More Trusted"
              defaultMessage="More Trusted"
            />
          </h3>
          <p className="text">
            <FormattedMessage
              id="pages.商家入驻.Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth"
              defaultMessage="Nano-Influencer lebih membangun hubungan personal dengan pengikut sehingga menciptakan kepercayaan Mouth-to-Mouth"
            />
          </p>
        </div>
        <img src={sr3} alt="" />
      </div>
      <div className="advantage1">
        <p className="number">7X</p>
        <div>
          <h3>
            <FormattedMessage
              id="pages.商家入驻.More Profit"
              defaultMessage="More Profit"
            />
          </h3>
          <p className="text">
            <FormattedMessage
              id="pages.商家入驻.Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat"
              defaultMessage="Brand melalui rekomendasi Nano-Influencer akan mengalami kenaikan penjualan sampai berkali-kali lipat"
            />
          </p>
        </div>
        <img src={sr2} alt="" />
      </div>
      <div className="advantage2">
        <p className="number">89%</p>
        <div>
          <h3>
            <FormattedMessage
              id="pages.商家入驻.More Massive"
              defaultMessage="More Massive"
            />
          </h3>
          <p className="text">
            <FormattedMessage
              id="pages.商家入驻.Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer"
              defaultMessage="Jumlah Nano-Influencer tanpa batas dengan jangkauan yang luas melalui Komunitas Influencer"
            />
          </p>
        </div>
        <img src={sr4} alt="" />
      </div>
      <h3 className="settle-title">
        <img
          style={{
            marginLeft: 0,
          }}
          src={mleft}
          alt=""
        />
        <p>
          <FormattedMessage
            id="pages.商家入驻.Hasil MOUTH-TO-MOUTH MARKETING di iBooming"
            defaultMessage="Hasil MOUTH-TO-MOUTH MARKETING di iBooming"
          />
        </p>
        <img
          style={{
            marginRight: 0,
          }}
          src={mright}
          alt=""
        />
      </h3>
      <div className="influencer">
        <h3>
          <FormattedMessage
            id="pages.商家入驻.INFLUENCER"
            defaultMessage="INFLUENCER"
          />
        </h3>
        <div className="talent-warp">
          <div className="warp">
            <div className="list">
              <img src={tt1} alt="" />
              <p>
                <FormattedMessage
                  id="pages.商家入驻.Jordi"
                  defaultMessage="Jordi"
                />
              </p>
            </div>

            <div className="list">
              <img src={tt2} alt="" />
              <p>
                <FormattedMessage
                  id="pages.商家入驻.Willie Salim"
                  defaultMessage="Willie Salim"
                />
              </p>
            </div>

            <div className="list">
              <img src={tt3} alt="" />
              <p>
                <FormattedMessage
                  id="pages.商家入驻.Vilmei"
                  defaultMessage="Vilmei"
                />
              </p>
            </div>

            <div className="list">
              <img src={tt4} alt="" />
              <p>
                <FormattedMessage
                  id="pages.商家入驻.Awkarin"
                  defaultMessage="Awkarin"
                />
              </p>
            </div>
          </div>
        </div>
        <p className="desc">
          <FormattedMessage
            id="pages.商家入驻.Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus"
            defaultMessage="Mouth-to-Mouth Marketing di iBooming dapat menghasilkan total miliaran pendapatan melalui jutaan penonton dari ribuan creator sekaligus"
          />
        </p>
      </div>
      <div className="influencer">
        <h3>
          <FormattedMessage id="pages.商家入驻.BRAND" defaultMessage="BRAND" />
        </h3>
        <img className="img1" src={br1} alt="" />
        <h4 className="brand-title">
          <FormattedMessage
            id="pages.商家入驻.Top Product Sales in Southeast Asia"
            defaultMessage="Top Product Sales in Southeast Asia"
          />
        </h4>
        <div className="product-list">
          <div className="list">
            <img src={br2} alt="" />
            <p>
              <FormattedMessage
                id="pages.商家入驻.Top Product Trending Skincare"
                defaultMessage="Top Product Trending Skincare"
              />
            </p>
          </div>
          <div className="list">
            <img src={br3} alt="" />
            <p>
              <FormattedMessage
                id="pages.商家入驻.Top Product Body Wash"
                defaultMessage="Top Product Body Wash"
              />
            </p>
          </div>
          <div className="list">
            <img src={br4} alt="" />
            <p>
              <FormattedMessage
                id="pages.商家入驻.Top Product Best Selling Skincare"
                defaultMessage="Top Product Best Selling Skincare"
              />
            </p>
          </div>
        </div>
      </div>

      {/* 经典案例 */}
      <div className="brand-warp">
        <img src={brand1} alt="" />
        <div className="desc">
          <h3>KOJIC</h3>

          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.100jt+"
                defaultMessage="100jt+"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.Total Impresi"
              defaultMessage="Total Impresi"
            />
          </p>
          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.1,3M+"
                defaultMessage="1,3M+"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.Total Penjualan"
              defaultMessage="Total Penjualan"
            />
          </p>
          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.No. 1"
                defaultMessage="No. 1"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.TikTok Southeast Asia Hot Product List"
              defaultMessage="TikTok Southeast Asia Hot Product List"
            />
          </p>
        </div>
      </div>

      <div className="brand-warp">
        <img src={brand2} alt="" />
        <div className="desc">
          <h3>METOO</h3>
          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.400jt+"
                defaultMessage="400jt+"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.Total Impresi"
              defaultMessage="Total Impresi"
            />
          </p>
          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.40.000+"
                defaultMessage="40.000+"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.Penjualan Harian Rata-Rata"
              defaultMessage="Penjualan Harian Rata-Rata"
            />
          </p>
        </div>
      </div>

      <div className="brand-warp">
        <img src={brand3} alt="" />
        <div className="desc">
          <h3>JESTHAM</h3>
          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.No. 1"
                defaultMessage="No. 1"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.in Skincare Package"
              defaultMessage="in Skincare Package"
            />
          </p>
          <p>
            <span>
              <FormattedMessage
                id="pages.商家入驻.100.000+"
                defaultMessage="100.000+"
              />
            </span>
            <FormattedMessage
              id="pages.商家入驻.Penjualan Bulanan Rata-Rata"
              defaultMessage="Penjualan Bulanan Rata-Rata"
            />
          </p>
        </div>
      </div>

      <h3 className="settle-title" style={{ marginTop: "0.32rem" }}>
        <img
          style={{
            marginLeft: 0,
          }}
          src={mleft}
          alt=""
        />
        <p>
          <FormattedMessage
            id="pages.商家入驻.SEGERA DAFTARKAN BRAND ANDA"
            defaultMessage="SEGERA DAFTARKAN BRAND ANDA"
          />
        </p>
        <img
          style={{
            marginRight: 0,
          }}
          src={mright}
          alt=""
        />
      </h3>

      <div className="form-desc">
        <p>
          <FormattedMessage
            id="pages.商家入驻.Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu"
            defaultMessage="Isi informasi untuk bergabung, tim iBooming akan melakukan peninjauan dan langsung menghubungimu"
          />
        </p>
        <img src={img12} alt="" />
      </div>
      <div
        id="forminfo"
        style={{
          width: "6.86rem",
          margin: "0 auto",
          paddingBottom: "0.90rem",
        }}
      >
        <JoinUS type={7} />
      </div>
    </div>
  );
}
