/**
 * 加入我们
 */
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  Carousel,
  Button,
  Modal,
  message,
} from "antd";
import axios from "axios";
import "./join.scss";
import pl1 from "../../assest/pl1.png";
import pl2 from "../../assest/pl2.png";
import pl3 from "../../assest/pl3.png";
import pl4 from "../../assest/pl4.png";
import icon from "../images/mcn_icon.png";
import PCModal from "../../components/PCModal";
const { Option } = Select;

const countryNum = {
  ZH: "86",
  EN: "62",
  ID: "62",
  TH: "66",
  VN: "84",
  MY: "60",
  PH: "63",
};
const codeCurrency = {
  ZH: "$",
  EN: "$",
  ID: "RP",
  TH: "฿",
  VN: "₫",
  MY: "RM",
  PH: "₱",
};
const tapUrlArr = {
  ID: [
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7215055489527269126",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7215071692724258565",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7312366895940257542&shop_region=ID",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7215062244529669893",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7215071738458818309",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7215081795673917190",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7215062244529866501",
  ],
  TH: ["", "", "", "", "", "", ""],
  VN: [
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7299766482073618181&shop_region=VN",
    "",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7299754165803910917&shop_region=VN",
    "",
    "https://affiliate.tiktok.com/product/partner-campaign/detail?campaign_id=7299758747579467525&shop_region=VN",
    "",
    "",
  ],
  MY: [
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312355106439120641&tab=info&market=6&page_size=20&cur_page=1&product_status=1",
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312362540532139777&tab=info&market=6&page_size=20&cur_page=1&product_status=1",
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312362540532238081&tab=info&page_size=20&cur_page=1&product_status=1",
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312362540532303617&tab=info&page_size=20&cur_page=1&product_status=1",
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312355074235467521&tab=info&page_size=20&cur_page=1&product_status=1",
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312362540532369153&tab=info&page_size=20&cur_page=1&product_status=1",
    "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312355074234976001&tab=info&market=6&page_size=20&cur_page=1&product_status=1",
  ],
  PH: ["", "", "", "", "", "", ""],
};

const fansArr = [
  "1,000,000 以上",
  "500,000 至 1,000,000",
  "100,000 至 500,000",
  "50,000 至 100,000",
  "10,000 至 50,000",
  "10,000 以下",
];
let appThrottleDate = 0;
export default function APPJoinUS(props) {
  // const [search] = useSearchParams();
  const locale = localStorage.getItem("locale") || "en-us";
  const appCountry = localStorage.getItem("country") || "ID";
  const [form] = Form.useForm();
  // const [visible, setVisible] = useState(
  //   "https://partner.tiktokshop.com/affiliate-campaign/campaign/detail?campaign_id=7312355106439120641&tab=info&market=6&page_size=20&cur_page=1&product_status=1"
  // );
  // TODO: 明天看下样式
  const [visible, setVisible] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState([]);
  const [showError, setShowError] = useState(false);
  // type 1是头号卖家，2是新卖家，3是其他商家、4是达人、5是MCN
  const { type, userInfo, tikCategory, typeIds, showModal, setShowModal } =
    props;
  const [mobileCode, setMobileCode] = useState(
    type == 5 || type == 7
      ? countryNum[appCountry]
      : countryNum[(locale.split("-")[0] || "en").toUpperCase()]
  );
  const [country, setCountry] = useState("ID");
  const [platCode, setPlatCode] = useState("Instagram");
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const onCountryChange = (value) => {
    if (value) {
      console.log(value);
      const str = typeof value === "object" ? value[0] : value;
      if (str) {
        setMobileCode(countryNum[str]);
      }
      setCountry(value);
      form.resetFields(["category"]);
    }
  };
  useEffect(() => {
    if (type != 5) {
      // const [search] = useSearchParams();
      const search = window.location.search;
      if (search.includes("tapurl=")) {
        const tapurl = (search.split("tapurl=")[1] || "").split("&")[0];
        setVisible(tapurl);
      }
    }
  }, []);
  // MCN数据回显
  useEffect(() => {
    if (userInfo && userInfo.userId) {
      form.setFieldsValue({
        tiktok_username: userInfo.tiktok,
      });
    }
  }, [userInfo]);
  // MCN类目回显
  useEffect(() => {
    if (typeIds) {
      form.setFieldsValue({
        category: typeIds,
      });
    }
  }, [typeIds]);
  const selectBefore = (
    <Select
      defaultValue={mobileCode}
      value={mobileCode}
      onChange={(value) => setMobileCode(value)}
      style={{ width: 90 }}
      popupClassName="app-platform-select"
    >
      {/* 中国 */}
      <Option value="86">CN +86</Option>
      {/* 印尼 */}
      <Option value="62">ID +62</Option>
      {/* 泰国 */}
      <Option value="66">TH +66</Option>
      {/* 越南 */}
      <Option value="84">VN +84</Option>
      {/* 马来西亚 */}
      <Option value="60">MY +60</Option>
      {/* 菲律宾 */}
      <Option value="63">PH +63</Option>
    </Select>
  );
  const onCategoryChange = (value) => {
    const ids = value.length > 3 ? value.slice(0, 3) : value;
    setCategoryName(ids);
    form.setFieldsValue({ category: ids });
  };
  const categoryArr =
    country === "ID"
      ? ["护肤", "彩妆", "电子", "服饰", "食品", "家居生活", "母婴", "其他"]
      : [
          "美妆",
          "电子",
          "服饰",
          "食品",
          "家居生活",
          "母婴",
          "个护和健康",
          "其他",
        ];
  const onFinish = (values) => {
    const newDate = new Date().getTime();
    if (newDate - appThrottleDate < 5000) {
      return;
    }
    appThrottleDate = newDate;
    const url = "https://api.ibooming.top/api/refer/user";
    if (type == 5) {
      const param = {
        ...values,
        access_type: type.toString(),
        category: values.category.join(","),
        contact_phone: `+${mobileCode} ${values.contact_phone}`,
        THB: codeCurrency[appCountry] + (values.THB || 0).toString(),
        country: appCountry,
      };
      setLoading(true);
      axios
        .post(url, param)
        .then(() => {
          setLoading(false);
          form.resetFields();
          setShowModal(true);
          // message.success({
          //   content: intl.formatMessage({
          //     id: "pages.home.提交成功",
          //     defaultMessage: "提交成功",
          //   }),
          //   className: "app-toast-warp",
          //   style: {
          //     marginTop: "47vh",
          //   },
          // });
        })
        .catch((err) => {
          setShowError(true);
          setLoading(false);
          // message.success({
          //   content: intl.formatMessage({
          //     id: "pages.mcn.提交失败，请再尝试一次",
          //     defaultMessage: "提交失败，请再尝试一次",
          //   }),
          //   className: "app-toast-warp",
          //   style: {
          //     marginTop: "47vh",
          //   },
          // });
        });
    } else {
      // TODO:线上接口要改
      const index = values.category;

      const param = {
        ...values,
        access_type: type.toString(),
        contact_phone: `+${mobileCode} ${values.contact_phone}`,
        category: intl.formatMessage({
          id: `pages.home.${categoryArr[index]}`,
          defaultMessage: categoryArr[index],
        }),
        THB: (values.THB || 0).toString(),
        country:
          type == 4 || type == 5 ? values.country : values.country.join(","),
      };
      let urlCountry = values.country;
      if (type == 4) {
        param.other_platform = platCode;
      } else {
        urlCountry = values.country[0] || "ID";
      }
      setLoading(true);
      axios
        .post(url, param)
        .then(() => {
          setLoading(false);
          // 重新跳转链接
          let linkUrl =
            window.location.origin +
            window.location.pathname +
            window.location.search;
          const tapUrl = tapUrlArr[urlCountry]
            ? tapUrlArr[urlCountry][index]
            : "";
          // if (type == 1 && tapUrl) {
          //   setVisible(tapUrl);
          // } else {
          //   message.success({
          //     content: intl.formatMessage({
          //       id: "pages.home.提交成功",
          //       defaultMessage: "提交成功",
          //     }),
          //     className: "app-toast-warp",
          //     style: {
          //       marginTop: "47vh",
          //     },
          //   });
          // }
          form.resetFields();
          message.success({
            content: intl.formatMessage({
              id: "pages.home.提交成功",
              defaultMessage: "提交成功",
            }),
            className: "app-toast-warp",
            style: {
              marginTop: "47vh",
            },
            onClose: () => {
              if (type == 7) {
                return;
              }
              if (!linkUrl.includes("success=")) {
                if (type == 1 && tapUrl) {
                  // setVisible(tapUrl);
                  const str = linkUrl.includes("?") ? "&" : "?";
                  linkUrl = linkUrl + str + "tapurl=" + tapUrl;
                }
                linkUrl = linkUrl.includes("?")
                  ? linkUrl + "&success=true"
                  : linkUrl + "?success=true";
                window.location.href = linkUrl;
              } else if (type == 1 && tapUrl) {
                setVisible(tapUrl);
              }
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          message.success({
            content: intl.formatMessage({
              id: "pages.mcn.提交失败，请再尝试一次",
              defaultMessage: "提交失败，请再尝试一次",
            }),
            className: "app-toast-warp",
            style: {
              marginTop: "47vh",
            },
          });
        });
    }
  };
  return (
    <div className="app-join-now">
      {type == 5 || type == 7 ? null : (
        <>
          <h3 className="app-main-title" style={{ marginBottom: 12 }}>
            {type == 1 ? (
              <FormattedMessage
                id="pages.home.立即匹配"
                defaultMessage="立即匹配"
              />
            ) : type == 4 ? (
              <FormattedMessage
                id="pages.home.立即加入网红社群"
                defaultMessage="立即加入网红社群"
              />
            ) : (
              <FormattedMessage
                id="pages.home.立即咨询我们"
                defaultMessage="立即咨询我们"
              />
            )}
          </h3>
          <p>
            {type == 4 ? (
              <FormattedMessage
                id="pages.home.更多高佣商品与带货补贴"
                defaultMessage="更多高佣商品与带货补贴"
              />
            ) : (
              <FormattedMessage
                id="pages.home.抢占海外达人分销的红利"
                defaultMessage="抢占海外网红分销的红利"
              />
            )}
          </p>
        </>
      )}
      <div
        style={
          type == 5 || type == 7 ? {} : { width: "6.7rem", margin: "0 auto" }
        }
      >
        <Form
          form={form}
          onFinish={onFinish}
          size="large"
          initialValues={
            type == 4 && locale !== "en-us" && locale !== "zh-cn"
              ? {
                  country: (locale.split("-")[0] || "en").toUpperCase(),
                }
              : {}
          }
        >
          {type == 4 ? (
            <>
              <Form.Item name="country">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.home.所在国家",
                    defaultMessage: "所在国家",
                  })}
                  onChange={onCountryChange}
                  popupClassName="app-platform-select"
                >
                  <Option value="ID">
                    <FormattedMessage
                      id="pages.home.印度尼西亚"
                      defaultMessage="印度尼西亚"
                    />
                  </Option>
                  <Option value="TH">
                    <FormattedMessage
                      id="pages.home.泰国"
                      defaultMessage="泰国"
                    />
                  </Option>
                  <Option value="VN">
                    <FormattedMessage
                      id="pages.home.越南"
                      defaultMessage="越南"
                    />
                  </Option>
                  <Option value="MY">
                    <FormattedMessage
                      id="pages.home.马来西亚"
                      defaultMessage="马来西亚"
                    />
                  </Option>
                  <Option value="PH">
                    <FormattedMessage
                      id="pages.home.菲律宾"
                      defaultMessage="菲律宾"
                    />
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item name="tiktok_username">
                <Input
                  placeholder={intl.formatMessage({
                    id: "pages.home.TikTok username",
                    defaultMessage: "TikTok username",
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item name="fans_num">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.home.粉丝数",
                    defaultMessage: "粉丝数",
                  })}
                  popupClassName="app-platform-select"
                >
                  {fansArr.map((item, index) => (
                    <Option
                      value={intl.formatMessage({
                        id: `pages.home.${item}`,
                        defaultMessage: item,
                      })}
                      key={item}
                    >
                      <FormattedMessage
                        id={`pages.home.${item}`}
                        defaultMessage={item}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="category">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.home.选择类目",
                    defaultMessage: "选择类目",
                  })}
                  popupClassName="app-platform-select"
                >
                  {categoryArr.map((item, index) => (
                    <Option value={index} key={item}>
                      <FormattedMessage
                        id={`pages.home.${item}`}
                        defaultMessage={item}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="THB">
                <InputNumber
                  addonBefore={
                    codeCurrency[(locale.split("-")[0] || "en").toUpperCase()]
                  }
                  placeholder={intl.formatMessage({
                    id: "pages.home.拍摄一个视频的价格",
                    defaultMessage: "拍摄一个视频的价格",
                  })}
                />
              </Form.Item>
              <Form.Item name="contact_phone">
                <InputNumber
                  addonBefore={selectBefore}
                  placeholder={intl.formatMessage({
                    id: "pages.home.联系号码",
                    defaultMessage: "联系号码",
                  })}
                  // min={100000000}
                  // max={99999999999}
                />
              </Form.Item>
              <Form.Item name="platform_account">
                <Input
                  addonBefore={
                    <div className="platform">
                      <Select
                        defaultValue={platCode}
                        value={platCode}
                        onChange={(value) => setPlatCode(value)}
                        style={{ width: 70 }}
                        popupClassName="app-platform-select app-platform_account"
                      >
                        <Option value="Facebook">
                          <div className="demo-option-label-item">
                            <img src={pl1} alt="" />
                            <span>Facebook</span>
                          </div>
                        </Option>
                        <Option value="X (Twitter)">
                          <div className="demo-option-label-item">
                            <img src={pl2} alt="" />
                            <span>X (Twitter)</span>
                          </div>
                        </Option>
                        <Option value="Youtube">
                          <div className="demo-option-label-item">
                            <img src={pl3} alt="" />
                            <span>Youtube</span>
                          </div>
                        </Option>
                        <Option value="Instagram">
                          <div className="demo-option-label-item">
                            <img src={pl4} alt="" />
                            <span>Instagram</span>
                          </div>
                        </Option>
                      </Select>
                    </div>
                  }
                  placeholder={intl.formatMessage({
                    id: "pages.home.其他账号（选填）",
                    defaultMessage: "其他账号（选填）",
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {(form) => {
                  const {
                    country,
                    contact_phone,
                    category,
                    tiktok_username,
                    fans_num,
                    THB,
                    // platform_account,
                  } = form.getFieldsValue(
                    "country",
                    "contact_phone",
                    "category",
                    "tiktok_username",
                    "fans_num",
                    "THB"
                    // "platform_account"
                  );
                  return (
                    <Button
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={
                        // !form.isFieldsTouched(true) ||
                        !country ||
                        !contact_phone ||
                        category == undefined ||
                        !tiktok_username ||
                        !fans_num ||
                        THB == undefined
                      }
                    >
                      <FormattedMessage
                        id="pages.home.提交"
                        defaultMessage="提交"
                      />
                    </Button>
                  );
                }}
              </Form.Item>
            </>
          ) : type == 5 ? (
            <>
              <Form.Item name="tiktok_username">
                <Input
                  placeholder={intl.formatMessage({
                    id: "pages.home.TikTok username",
                    defaultMessage: "TikTok username",
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item name="fans_num">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.home.粉丝数",
                    defaultMessage: "粉丝数",
                  })}
                  popupClassName="app-platform-select"
                  onDropdownVisibleChange={(open) => {
                    setOpen(open);
                  }}
                >
                  {fansArr.map((item, index) => (
                    <Option
                      value={intl.formatMessage({
                        id: `pages.home.${item}`,
                        defaultMessage: item,
                      })}
                      key={item}
                    >
                      <FormattedMessage
                        id={`pages.home.${item}`}
                        defaultMessage={item}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="category" className="hide-keyboard">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.home.选择类目",
                    defaultMessage: "选择类目",
                  })}
                  mode="multiple"
                  popupClassName="app-platform-select"
                  showSearch={false}
                  value={categoryName}
                  onChange={onCategoryChange}
                  // onFocus={() => {
                  //   // setTimeout(() => {
                  //   //   const a = document.querySelector(
                  //   //     ".ant-select-selection-search-input"
                  //   //   );
                  //   //   console.log(a, 999);
                  //   //   a.setAttribute("readOnly", true);
                  //   // }, 1000);
                  //   // document.activeElement.blur();
                  //   document.documentElement.style.overflow = "hidden";
                  // }}
                  onDropdownVisibleChange={(open) => {
                    // console.log(open);
                    setOpen(open);
                    // if (open) {
                    //   // document.documentElement.style.overflow = "hidden";
                    //   // setTimeout(() => {
                    //   //   // document.documentElement.style.overflow = "hidden";
                    //   //   // document.body.style.overflowY = "hidden";
                    //   // }, 100);
                    //   console.log(document.documentElement.style);
                    //   // alert(JSON.stringify(document.documentElement.style));
                    //   // document.body.style.overflowY = "hidden";
                    //   // document.body.style.transform = "";
                    // } else {
                    //   // document.documentElement.style.overflow = "inherit";
                    //   // document.body.style.overflowY = "inherit";
                    // }
                  }}
                  // onBlur={() => {
                  //   document.documentElement.style.overflow = "scroll";
                  // }}
                >
                  {(tikCategory || []).map((item, index) => (
                    <Option value={item.name} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item name="category">
                <Input
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: "pages.home.选择类目",
                    defaultMessage: "选择类目",
                  })}
                  readOnly
                  onFocus={() => {
                    console.log(111);
                  }}
                />
              </Form.Item> */}
              <Form.Item name="contact_phone">
                <InputNumber
                  addonBefore={selectBefore}
                  placeholder={intl.formatMessage({
                    id: "pages.home.联系号码",
                    defaultMessage: "联系号码",
                  })}
                />
              </Form.Item>
              <Form.Item name="THB">
                <InputNumber
                  addonBefore={codeCurrency[appCountry]}
                  placeholder={intl.formatMessage({
                    id: "pages.home.拍摄一个视频的价格",
                    defaultMessage: "拍摄一个视频的价格",
                  })}
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {(form) => {
                  const {
                    category,
                    tiktok_username,
                    fans_num,
                    THB,
                    contact_phone,
                    // platform_account,
                  } = form.getFieldsValue(
                    "category",
                    "tiktok_username",
                    "fans_num",
                    "THB",
                    "contact_phone"
                  );
                  console.log(
                    category,
                    tiktok_username,
                    fans_num,
                    THB,
                    contact_phone
                  );
                  return (
                    <Button
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={
                        (category || []).length == 0 ||
                        !tiktok_username ||
                        !fans_num ||
                        THB == undefined ||
                        !contact_phone
                      }
                    >
                      <FormattedMessage
                        id="pages.mcn.立即申请"
                        defaultMessage="立即申请"
                      />
                    </Button>
                  );
                }}
              </Form.Item>
              {showError && (
                <p className="show-error">
                  <img src={icon} alt="" />
                  <FormattedMessage
                    id="pages.mcn.提交失败，请再尝试一次"
                    defaultMessage="提交失败，请再尝试一次"
                  />
                </p>
              )}
            </>
          ) : type == 7 ? (
            <>
              <Form.Item name="company_name">
                <Input
                  placeholder={intl.formatMessage({
                    id: "pages.商家入驻.Nama Perusahaan",
                    defaultMessage: "Nama Perusahaan",
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item name="country" className="hide-keyboard">
                <Select
                  mode="multiple"
                  placeholder={intl.formatMessage({
                    id: "pages.商家入驻.Jenis Usaha",
                    defaultMessage: "Jenis Usaha",
                  })}
                  onChange={onCountryChange}
                  popupClassName="app-platform-select"
                  showSearch={false}
                >
                  <Option value="ID">
                    <FormattedMessage
                      id="pages.home.印度尼西亚"
                      defaultMessage="印度尼西亚"
                    />
                  </Option>
                  <Option value="TH">
                    <FormattedMessage
                      id="pages.home.泰国"
                      defaultMessage="泰国"
                    />
                  </Option>
                  <Option value="VN">
                    <FormattedMessage
                      id="pages.home.越南"
                      defaultMessage="越南"
                    />
                  </Option>
                  <Option value="MY">
                    <FormattedMessage
                      id="pages.home.马来西亚"
                      defaultMessage="马来西亚"
                    />
                  </Option>
                  <Option value="PH">
                    <FormattedMessage
                      id="pages.home.菲律宾"
                      defaultMessage="菲律宾"
                    />
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item name="category">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.商家入驻.Kategori Produk",
                    defaultMessage: "Kategori Produk",
                  })}
                  popupClassName="app-platform-select"
                >
                  {categoryArr.map((item, index) => (
                    <Option value={index} key={item}>
                      <FormattedMessage
                        id={`pages.home.${item}`}
                        defaultMessage={item}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="contact_phone">
                <InputNumber
                  addonBefore={selectBefore}
                  placeholder={intl.formatMessage({
                    id: "pages.商家入驻.Nomor Kontak Aktif",
                    defaultMessage: "Nomor Kontak Aktif",
                  })}
                />
              </Form.Item>

              <Form.Item shouldUpdate>
                {(form) => {
                  const { company_name, country, contact_phone, category } =
                    form.getFieldsValue(
                      "company_name",
                      "country",
                      "contact_phone",
                      "category"
                    );
                  // const {}
                  return (
                    <Button
                      className="settle-btn"
                      type="primary"
                      htmlType="submit"
                      // loading={true}
                      loading={loading}
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !company_name ||
                        !country ||
                        !contact_phone ||
                        category == undefined
                      }
                    >
                      <FormattedMessage
                        id="pages.home.提交"
                        defaultMessage="提交"
                      />
                    </Button>
                  );
                }}
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item name="company_name">
                <Input
                  placeholder={intl.formatMessage({
                    id: "pages.home.您的企业名称",
                    defaultMessage: "您的企业名称",
                  })}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item name="contact_phone">
                <InputNumber
                  addonBefore={selectBefore}
                  placeholder={intl.formatMessage({
                    id: "pages.home.联系号码",
                    defaultMessage: "联系号码",
                  })}
                />
              </Form.Item>
              <Form.Item name="country" className="hide-keyboard">
                <Select
                  mode="multiple"
                  placeholder={intl.formatMessage({
                    id: "pages.home.业务所在国家",
                    defaultMessage: "业务所在国家",
                  })}
                  onChange={onCountryChange}
                  popupClassName="app-platform-select"
                  showSearch={false}
                >
                  <Option value="ID">
                    <FormattedMessage
                      id="pages.home.印度尼西亚"
                      defaultMessage="印度尼西亚"
                    />
                  </Option>
                  <Option value="TH">
                    <FormattedMessage
                      id="pages.home.泰国"
                      defaultMessage="泰国"
                    />
                  </Option>
                  <Option value="VN">
                    <FormattedMessage
                      id="pages.home.越南"
                      defaultMessage="越南"
                    />
                  </Option>
                  <Option value="MY">
                    <FormattedMessage
                      id="pages.home.马来西亚"
                      defaultMessage="马来西亚"
                    />
                  </Option>
                  <Option value="PH">
                    <FormattedMessage
                      id="pages.home.菲律宾"
                      defaultMessage="菲律宾"
                    />
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item name="category">
                <Select
                  placeholder={intl.formatMessage({
                    id: "pages.home.选择类目",
                    defaultMessage: "选择类目",
                  })}
                  popupClassName="app-platform-select"
                >
                  {categoryArr.map((item, index) => (
                    <Option value={index} key={item}>
                      <FormattedMessage
                        id={`pages.home.${item}`}
                        defaultMessage={item}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item shouldUpdate>
                {(form) => {
                  const { company_name, country, contact_phone, category } =
                    form.getFieldsValue(
                      "company_name",
                      "country",
                      "contact_phone",
                      "category"
                    );
                  // const {}
                  return (
                    <Button
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !company_name ||
                        !country ||
                        !contact_phone ||
                        category == undefined
                      }
                    >
                      <FormattedMessage
                        id="pages.home.提交"
                        defaultMessage="提交"
                      />
                    </Button>
                  );
                }}
              </Form.Item>
            </>
          )}
        </Form>
      </div>
      <PCModal
        type={visible ? (type == 5 ? "3" : "2") : ""}
        setType={setVisible}
        tapUrl={visible}
        isApp={true}
      />
      {isOpen && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            // background: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            // bottom: 0,
            zIndex: 2,
            overflow: "hidden",
          }}
          // onTouchMove={() => {
          //   setOpen(false);
          // }}
          // onClick={() => {
          //   setOpen(false);
          // }}
        ></div>
      )}
    </div>
  );
}
