// import { parse } from "querystring-es3"
window.os = {};
export function uuid(len = 8) {
  const S = "qwertyuioopasdfghjklzxcvbnmQWERTYUIOOPASDFGHJKLZXCVBNM0123456789";
  const LEN = S.length - 1;
  return " "
    .repeat(len)
    .split("")
    .map(() => S[Math.round(Math.random() * LEN)])
    .join("");
}

// export const getPageQuery = () => parse(window.location.href.split("?")[1])

export function obj2url(obj) {
  let url = "?";
  // eslint-disable-next-line no-restricted-syntax
  for (const k in obj) {
    if (obj[k] !== "" && obj[k] !== null && obj[k] !== undefined) {
      url += `${k}=${obj[k]}&`;
    } else {
      url += `${k}=${""}&`;
    }
  }
  return url.slice(0, url.length - 1);
}

export function getParams(param) {
  const url = window.location.href;
  const res = {};
  if (url.includes("?")) {
    const str = url.split("?")[1];
    const arr = str.split("&");
    arr.forEach((item) => {
      const key = item.split("=")[0];
      const val = item.split("=")[1];
      res[key] = decodeURIComponent(val); // 解码
    });
  }
  return res[param];
}

export function palyGoogle() {
  window.location.href =
    "https://play.google.com/store/apps/details?id=com.booming.booming";
}

export function appStore() {
  window.location.href = "https://apps.apple.com/us/app/ibooming/id1615570620";
}

export function downloadApp() {
  const userAgent = window.navigator.userAgent;
  if (/(iPhone|iPad|iPod|iOS|macintosh|mac os x)/i.test(userAgent)) {
    appStore();
  } else if (/(Android)/i.test(userAgent)) {
    palyGoogle();
  }
}
export function GoToAPP(page, param, callback) {
  let url = "ibooming://";
  if (page) {
    url += "?pageName=" + encodeURIComponent(page);
    if (param) {
      let p = "";
      for (let item in param) {
        if (param.hasOwnProperty(item)) {
          p +=
            encodeURIComponent(item) +
            "=" +
            encodeURIComponent(param[item]) +
            "&";
        }
      }
      if (p.endsWith("&") > 0) {
        p = p.slice(0, -1);
      }
      url += "&" + p;
    }
  }
  const userAgent = window.navigator.userAgent;
  if (/(iPhone|iPad|iPod|iOS|macintosh|mac os x)/i.test(userAgent)) {
    var loadDateTime = new Date();
    window.location = url; //schema链接或者universal link

    window.setTimeout(function () {
      //如果没有安装app,便会执行setTimeout跳转下载页
      let hidden =
        window.document.hidden ||
        window.document.mozHidden ||
        window.document.msHidden ||
        window.document.webkitHidden;
      if (typeof hidden == "undefined" || hidden == false) {
        if (callback) {
          callback();
        } else {
          appStore(); //ios下载地址
        }
      }
      window.close();
    }, 2000);
  } else {
    var state = null;
    try {
      window.location = url; //schema链接或者universal link
      window.setTimeout(function () {
        if (callback) {
          callback();
        } else {
          palyGoogle(); //android下载地址
        }
      }, 600);
    } catch (e) {}
  }
}
export function throttle(fun, delay, time) {
  var timeout,
    startTime = new Date();
  return function () {
    var context = this,
      args = arguments,
      curTime = new Date();
    clearTimeout(timeout);
    // 如果达到了规定的触发时间间隔，触发 handler
    if (curTime - startTime >= time) {
      fun.apply(context, args);
      startTime = curTime;
      // 没达到触发间隔，重新设定定时器
    } else {
      timeout = setTimeout(function () {
        fun.apply(context, args);
      }, delay);
    }
  };
}

export function getMoneyNum(num) {
  const locale = localStorage.getItem("locale") || "en-us";
  if (locale == "zh-cn") {
    return num;
  } else {
    if (num < 100) {
      return num * 100;
    } else {
      return num / 10;
    }
  }
}
/**
 * 根据size宽度，返回特定大小的图片
 *  https://<ZONE>/cdn-cgi/image/<OPTIONS>/<SOURCE-IMAGE>
 * @param {*} url
 * @param {*} size 指定图片的大小
 * @param quality 指定图片的质量，0～100
 * @returns
 */
export function getOSSImgUrl(url, width = 750, quality = 95) {
  if (!url || url.length === 0) {
    return "";
  } else {
    if (url.startsWith("https://oss.ibooming.top")) {
      return (
        url + "?x-oss-process=image/resize,w_" + width + "/quality,q_" + quality
      );
    }
    //参考文档：https://help.aliyun.com/document_detail/44688.html
    //https://oss.ibooming.top/booming/sys/6284dcfd6caa4b129e.jpeg?x-oss-process=image/resize,w_300,h_300/quality,q_90
  }
  return url;
}

export function formatFansNum(oldNum, isID, isGMV) {
  if (!oldNum) {
    return 0;
  }
  const country = localStorage.getItem("country") || "ID";
  let num = oldNum;
  let fanNumber = num;
  console.log(isGMV, country);
  if (isGMV && ["TH", "MY"].includes(country)) {
    num = oldNum / 100;
  }
  let unit = "";
  const bigNumb = isID ? 1000000000 : 1000000;
  if (num >= bigNumb) {
    unit = "M";
    fanNumber = num / bigNumb;
  } else if (num >= 1000) {
    unit = "K";
    fanNumber = num / 1000;
  }
  fanNumber = Math.floor(fanNumber * 10) / 10;
  const result = parseFloat(fanNumber.toFixed(1))
    .toString()
    .replace(
      /\B(?=(?:\d{3})+(?!\d))/g,
      country == "ID" || country == "VN" ? "." : ","
    );
  return `${result}${unit}`;
}

//金额转换
export function formatNumber(number = 0) {
  const num = Math.abs(parseFloat(number || 0));
  let ret = String(num);
  const country = localStorage.getItem("country") || "ID";
  const sign = number && number < 0 ? "-" : "";
  // 印尼
  if (country === "ID") {
    const result = ret.replace(/\B(?=(?:\d{3})+(?!\d))/g, ".");
    if (number && number < 0) {
      return `-Rp${result}`;
    } else {
      return `Rp${result}`;
    }
  } else if (country === "TH") {
    const money = String((num / 100).toFixed(2));
    const int = money.slice(0, -3);
    const decimal = money.slice(-3);
    // 泰语
    return `฿${int.replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")}${decimal}`;
  } else if (country === "VN") {
    // 越南
    const result = ret.replace(/\B(?=(?:\d{3})+(?!\d))/g, ".");
    return `${sign}${result}₫`;
  } else if (country === "PH") {
    const money = String((num / 100).toFixed(2));
    const int = money.slice(0, -3);
    const decimal = money.slice(-3);
    // 菲律宾
    return `${sign}₱${int.replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")}${decimal}`;
  } else if (country === "MY") {
    const money = String((num / 100).toFixed(2));
    const int = money.slice(0, -3);
    const decimal = money.slice(-3);
    // 马来西亚
    return `RM${int.replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")}${decimal}`;
  }
}
