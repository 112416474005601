/**
 * 经典案例
 */
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import { getMoneyNum } from "../../utils";
import new1 from "../../assest/new1.png";
import new2 from "../../assest/new2.png";
import new3 from "../../assest/new3.png";
const arrr = ["KOJIC", "MeToo", "Feali"];
export default function ClassicCases() {
  const navigate = useNavigate();
  const ref = useRef();
  const locale = localStorage.getItem("locale") || "en-us";
  const [count, setCount] = useState(0);
  return (
    <div className="cases wow fadeInUp" data-wow-duration="1s">
      <h3 className="main-title">
        <FormattedMessage id="pages.home.经典案例" defaultMessage="经典案例" />
      </h3>
      <div>
        {arrr.map((item, index) => (
          <div
            key={item}
            className={`btn ${index === count ? "active" : ""}`}
            onClick={() => {
              ref.current.goTo(index);
            }}
          >
            {item}
          </div>
        ))}
        <div
          className="wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <Carousel
            // autoplay
            effect="fade"
            ref={ref}
            dots={false}
            beforeChange={(from, to) => {
              setCount(to);
            }}
          >
            <div
              className="cases-content"
              onClick={() => navigate("/brand/detail/1")}
            >
              <img src={new1} alt="" />
              <div className="text">
                <h3>
                  {getMoneyNum(1)}
                  <span>
                    <FormattedMessage id="pages.home.1亿" defaultMessage="亿" />
                    +
                  </span>
                </h3>
                <p>
                  <FormattedMessage
                    id="pages.home.总曝光量"
                    defaultMessage="总曝光量"
                  />
                </p>
                <h3 style={{ marginTop: 60 }}>
                  {/* {getMoneyNum(200)} */}
                  {locale === "zh-cn" ? "128" : "1.28"}
                  <span>
                    <FormattedMessage
                      id="pages.home.200亿"
                      defaultMessage="万"
                    />
                    +
                  </span>
                </h3>
                <p>
                  <FormattedMessage
                    id="pages.home.总销售额(美元)"
                    defaultMessage="总销售额(美元)"
                  />
                </p>
              </div>
            </div>
            <div
              className="cases-content"
              onClick={() => navigate("/brand/detail/2")}
            >
              <img src={new2} alt="" />
              <div className="text">
                <h3>
                  {getMoneyNum(4)}
                  <span>
                    <FormattedMessage id="pages.home.1亿" defaultMessage="亿" />
                    +
                  </span>
                </h3>
                <p>
                  <FormattedMessage
                    id="pages.home.总曝光量"
                    defaultMessage="总曝光量"
                  />
                </p>
                <h3 style={{ marginTop: 60 }}>
                  40,000
                  <span>
                    {/* <FormattedMessage
                      id="pages.home.万+"
                      defaultMessage="万+"
                    /> */}
                    +
                  </span>
                </h3>
                <p>
                  <FormattedMessage
                    id="pages.home.平均日销量"
                    defaultMessage="平均日销量"
                  />
                </p>
              </div>
            </div>
            <div
              className="cases-content"
              onClick={() => navigate("/brand/detail/3")}
            >
              <img src={new3} alt="" />
              <div className="text">
                <h3>
                  {getMoneyNum(3)}
                  <span>
                    <FormattedMessage id="pages.home.1亿" defaultMessage="亿" />
                    +
                  </span>
                </h3>
                <p>
                  <FormattedMessage
                    id="pages.home.总曝光量"
                    defaultMessage="总曝光量"
                  />
                </p>
                <h3 style={{ marginTop: 60 }}>
                  10,000
                  <span>
                    {/* <FormattedMessage
                      id="pages.home.万+"
                      defaultMessage="万+"
                    /> */}
                    +
                  </span>
                </h3>
                <p>
                  <FormattedMessage
                    id="pages.home.平均日销量"
                    defaultMessage="平均日销量"
                  />
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <div></div>
    </div>
  );
}
